{
  "chat": {
    "title": "Parlez avec nous",
    "email": "Adresse électronique",
    "email_placeholder": "par ex. exemple{'@'}gmail.com",
    "tracking": "Numéro de suivi",
    "tracking_placeholder": "par ex. #01921883712873",
    "subject": "Sélectionnez le motif de votre demande",
    "placeholder": "Écrivez un message",
    "inform": "Salut! Merci de nous contacter via ce chat. Nous ne pouvons pas traiter votre demande immédiatement via ce canal, mais nous vous contacterons par e-mail dès que possible. Merci!",
    "send": "Envoyer",
    "options": {
      "select": "Sélectionnez une option",
      "op1": "Faire une consultation sur l'expédition",
      "op2": "Signaler un incident",
      "op3": "Consultation sur la politique de retour",
      "op4": "Consultation sur le processus de remboursement",
      "op5": "Demander l'annulation de ma commande",
      "op6": "Modifier ma commande"
    }
  },
  "chAndRe": {
    "tellUs": "Dites-nous pourquoi vous souhaitez retourner la commande",
    "writeHere": "Écris ici",
    "fulfillmentDays": "La période de retour (DAYS_TO jours) de cette commande est terminée.",
    "expired": "La période de retour a expiré",
    "change": "Changer",
    "errorEmail": "E-mail manquant",
    "invalidEmail": "Email invalide",
    "unit": "U.",
    "to_tracking": "Retour au suivi"
  },
  "app": {
    "power": "Powered by"
  },
  "add": {
    "submit": "Envoyer",
    "id_card": "Ajoutez votre carte d'identité",
    "success": "Succès!",
    "message": "Votre commande est prête à être traitée.",
    "invalidDNI": "Carte d'identité invalide",
    "required": "Champ requis",
    "info": "Le numéro de la carte d'identité est généralement imprimé sur le document lui-même et se compose de huit chiffres suivis d'une lettre qui agit comme un chiffre de contrôle.",
    "country": {
      "label": "Pays de livraison",
      "placeholder": "Sélectionner"
    },
    "zipCode": {
      "label": "Code Postal",
      "placeholder": "Entrer le code postal"
    },
    "city": {
      "label": "Ville",
      "placeholder": "Entrer la ville"
    },
    "address": {
      "label": "Adresse",
      "placeholder": "Entrer l'adresse"
    },
    "address2": {
      "label": "Informations supplémentaires sur l'adresse",
      "placeholder": "Entrer l'adresse"
    },
    "name": {
      "label": "Nom",
      "placeholder": "Entrez le nom"
    },
    "phone": {
      "label": "Téléphone",
      "placeholder": "Entrez le numéro de téléphone"
    },
    "searchCountry": "Rechercher un pays"
  },
  "dialog": {
    "title": "Veuillez indiquer votre adresse électronique afin que nous puissions traiter votre demande"
  },
  "info": {
    "form": {
      "tracking": "Numéro de commande ou de suivi",
      "tracking_ej": "par ex. #02692F0405",
      "required_tracking": "Le numéro de commande est requis.",
      "postal": "Code Postal",
      "postal_ej": "par ex. 08006",
      "required_postal": "Le code postal est requis.",
      "search": "Chercher",
      "tooltip_order": "Trouvez votre numéro de suivi dans l'e-mail de confirmation de commande que vous avez reçu. Vous pouvez également le trouver sur le bon de livraison à l'intérieur du colis.",
      "tooltip_code": "C'est un code numérique qui détermine un emplacement. Vous pouvez facilement le trouver en appelant la poste ou en effectuant une recherche sur Internet.",
      "error": "Ordre non trouvé"
    },
    "title": {
      "need": "Trouvez votre commande",
      "tracking": "Numéro de suivi ",
      "your_order": "Votre commande",
      "your_products": "Vos produits"
    }
  },
  "options": {
    "tracking": "Suivie: ",
    "order_number": "Numéro de commande",
    "order_name": "Nom de la commande",
    "id": "Numéro d'ordre",
    "return": "Souhaitez-vous effectuer un retour?",
    "change": "Souhaitez-vous effectuer un échange?",
    "my_return": "Voir mon retour",
    "my_change": "Voir mon changement",
    "return_progress": "Vous avez un retour en cours",
    "change_progress": "Vous avez un changement en cours",
    "return_or_change": "Souhaitez-vous effectuer un échange ou un retour?",
    "make_return": "Effectuer un retour",
    "make_change": "Effectuer un échange",
    "courier": {
      "name": "Votre commande est traitée par",
      "contact": "service de transporteur de confiance",
      "details": "Détails du transporteur"
    },
    "noData": "Aucune option disponible"
  },
  "steps": {
    "return": {
      "step1": {
        "step": "Étape 1 :",
        "desc": "Sélectionnez le produit que vous souhaitez retourner"
      },
      "step2": {
        "step": "Étape 2 :",
        "desc": "Confirmez le retour",
        "desc2": "Confirmez le retour ou sélectionnez d'autres produits"
      },
      "step3": {
        "desc": "Nous avons envoyé la demande de retour. Vous recevrez bientôt une réponse par e-mail."
      }
    },
    "change": {
      "step1": {
        "step": "Étape 1 :",
        "desc": "Sélectionnez le produit que vous souhaitez échanger"
      },
      "step2": {
        "step": "Étape 2 :",
        "desc": "Confirmez l'échange",
        "desc2": "Confirmez l'échange ou sélectionnez d'autres produits"
      },
      "step3": {
        "desc": "Nous avons envoyé la demande d'échange. Vous recevrez bientôt une réponse par e-mail."
      }
    }
  },
  "instructions": {
    "title": "Instructions pour le Retour",
    "title_steps": "Comment retourner un produit?",
    "subtitle_return": "Retour",
    "subtitle_change": "Changer",
    "item_return": "Vos articles à retourner",
    "item_change": "Vos articles à changement",
    "prepare": "Préparez",
    "send": "Envoyer la commande de retour",
    "refund": "Remboursement",
    "information": {
      "desc_url_1": "Pour effectuer le retour, suivez les instructions figurant sur l'étiquette. Veuillez noter que si vous effectuez un retour depuis un pays qui ne fait pas partie de l'Union européenne, il est essentiel d'imprimer la facture et de l'inclure dans le colis.",
      "desc_url_2": "Pour retourner un article, il vous suffit de coller cette étiquette sur le colis, et nous programmerons son ramassage.",
      "desc_url_3": "Pour finaliser le retour, cliquez sur le bouton 'Plus d'informations' et suivez les instructions.",
      "desc_cex": "Pour terminer le retour, déposez le colis dans un bureau de poste en mentionnant qu'il s'agit d'un envoi en 'Logística Inversa de Correos express' (Logistique de Retour Correos express) afin qu'ils puissent générer une étiquette de retour. Assurez-vous que les informations sur l'étiquette correspondent exactement à la référence de votre commande '{ref}', y compris les chiffres et les lettres."
    },
    "qr": {
      "step1": "Téléchargez et imprimez 2 exemplaires du code QR. Collez une copie à l'extérieur du colis et incluez l'autre à l'intérieur. Ainsi, nous pouvons identifier votre retour et procéder au remboursement.",
      "important": "IMPORTANT : Le QR n'est pas une étiquette d'expédition ; c'est un code d'identification interne pour notre entrepôt afin d'identifier le produit. Vous devez également attacher l'étiquette d'expédition fournie par la société de transport de votre choix à votre colis.",
      "step2": "Apportez le colis à la société de transport qui vous convient le mieux et envoyez-le à l'adresse indiquée dans l'e-mail.",
      "step3": "Recevez votre remboursement une fois que nous avons reçu le colis de retour."
    },
    "defaultInstructions": {
      "step1": "Emballez soigneusement les articles à retourner.",
      "step2": "Suivez les instructions fournies pour procéder au retour.",
      "step3": "Recevez votre remboursement une fois que nous avons reçu le colis de retour."
    },
    "return": {
      "title": "Vous pouvez maintenant renvoyer votre produit",
      "desc": "Pour effectuer le retour, veuillez suivre les instructions que nous avons envoyées à votre adresse e-mail.",
      "print_label": "Imprimer l'étiquette de retour",
      "more_info": "Plus d'informations",
      "qr_return": "Télécharger le code QR",
      "identifier": "Identifiant de retour:"
    },
    "received": {
      "title": "Nous avons reçu votre retour",
      "desc": "Votre retour a été reçu avec succès. Pour plus d'informations, veuillez nous contacter."
    }
  },
  "product": {
    "return": {
      "title": "Voulez-vous retourner cet article?",
      "label": "Pourquoi voulez-vous le retourner?",
      "qty": "Combien souhaitez-vous retourner?",
      "reason": "Écrivez ici la raison du retour",
      "required": "Veuillez écrire une raison pour le retour.",
      "date": "Retour disponible jusqu'au",
      "return": "Retourner",
      "item": "article",
      "items": "articles",
      "uploadImageDamage": "Joignez l'image de l'article endommagé",
      "uploadImageWrong": "Joignez l'image de l'article incorrect",
      "uploadImageDamagePlus": "Joignez les images des articles endommagés",
      "uploadImageWrongPlus": "Joignez les images des articles incorrects",
      "maxUpload": "Vous ne pouvez joindre qu'un maximum de 1 image par produit",
      "addImage": "Ajouter",
      "notReturnable": "Ce produit n'est pas éligible aux retours ou aux échanges",
      "allProductsNoReturnable": "Les retours ou échanges ne sont pas possibles pour aucun des produits"
    },
    "change": {
      "title": "Voulez-vous échanger cet article?",
      "label": "Pourquoi voulez-vous l'échanger?",
      "product": "Options d'échange",
      "reason": "Écrivez ici la raison de l'échange",
      "required": "Veuillez écrire une raison pour l'échange.",
      "date": "Échange disponible jusqu'au",
      "noStock": "Pas de stock disponible"
    },
    "confirm": "Confirmer",
    "cancel": "Annuler",
    "options": {
      "select": "Sélectionnez une option",
      "DAMAGED": "Arrivé endommagé",
      "TOO_BIG": "Trop grand",
      "TOO_SMALL": "Trop petit",
      "NOT_EXPECTED": "Pas ce à quoi je m'attendais",
      "NOT_ORDERED": "Pas ce que j'ai commandé",
      "OTHER": "Autre",
      "eg": "par ex: ça ne répond pas à mes attentes"
    }
  },
  "tracking": {
    "need": "Ajouter des informations",
    "resume": {
      "order": {
        "title": "Adresse de livraison"
      }
    },
    "messages": {
      "processing": "Cette commande est en cours de traitement.",
      "shipped": "Cette commande est expédiée.",
      "out": "Cette commande est en cours de livraison.",
      "ful": "Cette commande est exécutée.",
      "refused": "Statut : refusé",
      "info": "Pour exécuter cette commande, nous avons besoin d'informations supplémentaires.",
      "wrongAddress": "Oups, il semble qu'il y ait une erreur dans certains champs.",
      "verifyField": "Veuillez vérifier que toutes les informations sont correctes."
    },
    "progress": {
      "processing": "Nous traitons votre commande aussi rapidement que possible",
      "shipped": "Votre commande a déjà quitté notre entrepôt",
      "delivery": "Presque arrivé, votre commande est en cours de livraison",
      "delivered": "Nous avons livré votre commande",
      "refused": "Nous avons tenté de livrer votre colis, mais il a été refusé. Veuillez nous contacter pour plus d'informations."
    }
  },
  "footer": {
    "contact": "Contact",
    "help": "Aide",
    "policy": "Politique de confidentialité"
  },
  "checkout": {
    "checkout": "Checkout",
    "paymentDetails": "Détails du paiement",
    "paymentDetailsText": "Vous n'êtes plus qu'à un pas de finaliser votre achat ! Veuillez vérifier les détails de votre commande et procéder au paiement sécurisé.",
    "customerName": "Prénom",
    "customerSurnames": "Nom de famille",
    "customerAdress": "Adresse du domicile",
    "zipCode": "Code postal",
    "city": "Ville",
    "orderHeader": "Votre commande",
    "orderText": "Les nouveaux produits ajoutés à votre panier seront expédiés avec votre commande précédente. C'est-à-dire que vous recevrez tout dans un seul paquet.",
    "quantity": "unités",
    "exclusivePrice": "Prix exclusif :",
    "totalPrice": "Sous-total",
    "shippment": "Expédition incluse dans la commande",
    "thanks": "Merci beaucoup!",
    "successText": "Vous avez profité des remises exclusives pour vous. Ensuite, nous procéderons à la préparation de votre nouvelle commande. N'oubliez pas que vous recevrez tout à la date initialement prévue. Profitez de vos nouveaux produits à prix réduit!",
    "payNow": "Payer maintenant",
    "paymentSuccess": "Votre paiement a été effectué",
    "paymentProcessing": "Votre paiement est en cours de traitement",
    "paymentLabel": "Vos données de paiement ne sont pas stockées sur nos serveurs. Elles sont uniquement utilisées pour traiter votre paiement en toute sécurité."
  },
  "upselling": {
    "discount": {
      "text": "ACHETEZ AVEC DES REMISES ET RECEVEZ-LE EN UNE SEULE COMMANDE!"
    },
    "days": [
      "Dimanche",
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi"
    ],
    "months": [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre"
    ],
    "finished": {
      "condolences": "Désolé, cette offre est déjà terminée.",
      "condolencesBody1": "Nous sommes désolés que vous n'ayez pas pu profiter de cette offre à temps. Ne vous inquiétez pas! Chez",
      "condolencesBody2": "nous travaillons toujours pour vous proposer de nouvelles promotions passionnantes.",
      "condolencesBody3": "En attendant, nous vous invitons à découvrir nos autres produits et les nouvelles découvertes que nous sommes sûrs que vous allez adorer.",
      "titleTracking": "Vérifiez le statut de votre commande",
      "trackingButton": "Suivez-le ici"
    },
    "recommendedProducts": {
      "title": "Recommandé pour vous",
      "subtitleUpselling": "Nous pensons que ces produits correspondent à vos achats. Ajoutez au panier maintenant et recevez-le dans la même commande.",
      "subtitleUpsellingEnd": "Nous pensons que ces produits pourraient vous intéresser. Ne manquez pas l'occasion de découvrir ces perles rares qui pourraient devenir vos nouveaux favoris.",
      "goToShop": "Aller à la boutique",
      "addToCart": "Ajouter au panier",
      "modifyCart": "Modifier le panier"
    },
    "upsellingProducts": {
      "title": ["Achetez avec", "remise", "et recevez dans la même commande"],
      "orderNumber": "Numéro de commande",
      "timeText": "OFFRES À COMPTE À REBOURS!",
      "continue": "Continuez à lire pour voir le statut de votre commande"
    },
    "paySummary": {
      "title1": "Votre panier (",
      "title2": "nouveaux articles )",
      "deleteCart": "Supprimer du panier",
      "addMoreProducts": "Ajouter plus de produits",
      "endPurchase": "Terminer l'achat",
      "price": "Prix:"
    }
  },
  "iframe": {
    "addBanner": "Ajouter une bannière (optionnel)"
  },
  "exceptionsCarrier": {
    "CARRIER_WRONG_POSTAL_CODE": {
      "title": "Code postal invalide",
      "subtitle": "Le code postal saisi est incorrect ou ne correspond pas à la ville spécifiée."
    },
    "CARRIER_ADDRESS_TOO_LONG": {
      "title": "Adresse trop longue",
      "subtitle": "L'adresse saisie est trop longue pour générer l'étiquette d'expédition."
    },
    "CARRIER_PHONE_REQUIRED": {
      "title": "Numéro de téléphone requis",
      "subtitle": "L'étiquette d'expédition ne peut pas être générée car le numéro de téléphone est manquant."
    },
    "CARRIER_PHONE_TOO_LONG": {
      "title": "Numéro de téléphone trop long",
      "subtitle": "Le numéro de téléphone saisi est trop long pour générer l'étiquette d'expédition."
    },
    "CARRIER_PHONE_TOO_SHORT": {
      "title": "Numéro de téléphone trop court",
      "subtitle": "Le numéro de téléphone saisi est incorrect; il est trop court pour générer l'étiquette d'expédition."
    },
    "CARRIER_ADDRESS_TOO_SHORT": {
      "title": "Adresse trop courte",
      "subtitle": "L'adresse saisie est trop courte pour générer l'étiquette d'expédition."
    },
    "carrierMessage": "Le transporteur indique le message suivant :"
  }
}
