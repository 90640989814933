<template>
  <v-label class="pl-10 pb-4">
    <h6 class="d-inline"><slot></slot></h6>
    <slot name="tooltip"></slot>
  </v-label>
  <v-select
    class="option-select mb-10"
    append-inner-icon="mdi-chevron-down"
    v-model="selectedValue"
    :items="items"
    :placeholder="placeholder"
    :no-data-text="noChange ?  $t('options.noData') : $t('product.change.noStock')"
    variant="plain"
    single-line
    hide-details
  />
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      required: true,
      default: "",
    },
    items: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [String, Number],
      default: null,
    },
    noChange: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectedValue: this.value,
    };
  },
  watch: {
    selectedValue(newValue) {
      // Emit this information to the parents component
      this.$emit("input", newValue);
    },
  },
  emits: ["input"], // declare the custom event
};
</script>
<style lang="scss" scoped>
.v-select {
  border-radius: 8px;
  font-size: 10px;
  border: 1px solid rgb(var(--v-theme-neutral20));
  box-shadow: 0px 34px 100px rgba(143, 168, 191, 0.3);
  &:hover {
    background-color: rgb(var(--v-theme-neutral05));
    // background-color: var(--amph-light);
  }
  &:focus-within {
    border: 1px solid var(--amph-primary);
  }
}
:deep .v-field__input {
  padding-left: 40px;
  padding-top: 15px !important;
}
:deep .v-field__append-inner {
  padding-right: 15px !important;
}
:deep .v-overlay__scrim {
  opacity: 0.4 !important;
}
:deep.v-field__append-inner {
  padding-right: 20px;
  padding-bottom: 12px;
}
.v-input__control {
  max-width: 200px;
}

:deep .v-field__field {
  padding-bottom: 4px;
  padding-top: 4px;
}
</style>
