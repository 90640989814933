<template>
  <svg
    width="130"
    height="100"
    viewBox="0 0 130 112"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 55.87L32.3393 0L64.9394 55.87L32.3391 112L0 55.87Z"
      fill="#9A9A9A"
    />
    <path
      d="M74.3281 0L85.021 16.8909H66.2433L55.5505 0H32.3392L64.9393 55.87H87.8898L78.5009 39.7587H97.2786L106.407 55.87H129.618L97.2786 0H74.3281Z"
      fill="#282828"
    />
    <path
      d="M74.3281 112L85.021 95.109H66.2433L55.5505 112H32.3392L64.9393 55.87H87.8898L78.5009 72.2412H97.2786L106.407 55.87H129.618L97.2786 112H74.3281Z"
      fill="#686868"
    />
  </svg>
</template>

<script>
export default {
  name: "AmphoraIcon",
};
</script>
