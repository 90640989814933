import {
  SET_ORDER,
  SET_COMPANY,
  SET_PUBLIC_KEY,
  SET_CUSTOMIZATION,
} from "@/store/mutationTypes";
import { logoUrl } from "@/assets/utils/s3Urls.js"
import { createStore } from "vuex";
import { getOrderByTracking } from "@/services/orders.service.js";
import { getCompanyCustomization } from "../services/company.service";
import { ERROR_CODES } from "../errors/errors";

const today = new Date().toISOString();
const AMPHORA_COLORS = ["#E3B307"];
const AMPHORA_RULES = {
  showTracking: true,
  canChange: false,
  canReturn: false,
};
const DEFAULT_CONFIG = {
  colors: AMPHORA_COLORS,
  rules: AMPHORA_RULES,
  company: {
    logo_url: logoUrl,
  },
};

const checkParameters = (order, params) => {
  if (!Object.keys(params)) {
    return false;
  } else if (params["products"] && !order["products"]) {
    //order without products
    return false;
  } else if (
    (!!params["trackingNumber"] &&
      params["trackingNumber"] !== order["shipping_number"]) ||
    (!!params["postalCode"] &&
      params["postalCode"] !== order["shipping_address_zip"]) ||
    (!!params["companyId"] && params["companyId"] !== order["company_id"]) ||
    (!!params["orderId"] && params["orderId"] !== order["id"])
  ) {
    return false;
  }
  return true;
};

export default createStore({
  state: {
    order: null,
    companyId: undefined,
    publicKey: undefined,
    updateTime: null,
    companyCustomization: DEFAULT_CONFIG,
  },
  actions: {
    async findOrder({ commit }, order_params) {
      order_params.companyId = this.state.companyId;
      if (
        this.state.order &&
        this.state.updateTime >
          new Date(
            new Date().setHours(new Date().getHours() - 5)
          ).toISOString() &&
        checkParameters(this.state.order, order_params)
      ) {
        return Promise.resolve(this.state.order);
      } else {
        return new Promise((resolve, reject) => {
          getOrderByTracking(order_params).then(
            (r) => {
              commit(SET_ORDER, r.order);
              if (this.state.companyId != r.order.company_id) {
                commit(SET_COMPANY, r.order.company_id);
                getCompanyCustomization({ publicKey: r.order.company_id }).then(
                  (r) => {
                    if (r) {
                      this.state.companyCustomization = r;
                      commit(SET_CUSTOMIZATION, r);
                    }
                  },
                  (error) => {
                    if (
                      error.code == ERROR_CODES["AFLORA_DEFAULT_CONFIG"].code
                    ) {
                      this.state.companyCustomization = DEFAULT_CONFIG;
                      commit(SET_CUSTOMIZATION, DEFAULT_CONFIG);
                    }
                  }
                );
              }
              resolve(r.order);
            },
            (error) => {
              reject(error);
            }
          );
        });
      }
    },

    async findCompanyCustomization({ commit }, publicKey) {
      if (
        this.state.companyCustomization &&
        (this.state.publicKey === publicKey || this.state.companyId === publicKey)
      ) {
        return Promise.resolve(this.state.companyCustomization);
      } else {
        if (!this.state.publicKey) {
          commit(SET_PUBLIC_KEY, publicKey);
        }
        return new Promise((resolve, reject) => {
          if (publicKey) {
            getCompanyCustomization({ publicKey: publicKey }).then(
              (r) => {
                if (r) {
                  let search_key = r.company_id.split(" ");
                  r.company_id = search_key[0];
                  r.shop_id = search_key[1];
                  this.state.companyCustomization = r;
                  commit(SET_COMPANY, r.company_id);
                  commit(SET_CUSTOMIZATION, r);
                  return resolve(r);
                }
              },
              (error) => {
                if (
                  error.code == ERROR_CODES["AFLORA_DEFAULT_CONFIG"].code ||
                  !publicKey
                )
                  resolve(this.state.companyCustomization);
                reject(error);
              }
            );
          } else {
            return resolve(this.state.companyCustomization); //Maintain the general aflora for the moment with no url path
            // return reject("Not company specified");
          }
        });
      }
    },
  },
  mutations: {
    [SET_ORDER](state, order) {
      state.order = order;
      state.updateTime = today;
    },
    [SET_COMPANY](state, companyId) {
      state.companyId = companyId;
    },
    [SET_PUBLIC_KEY](state, publicKey) {
      state.publicKey = publicKey;
    },
    [SET_CUSTOMIZATION](state, companyCustomization) {
      state.companyCustomization = companyCustomization;
    },
  },
  getters: {
    companyId: (_state) => _state.companyId,
    order: (_state) => _state.order,
    companyCustomization: (_state) => _state.companyCustomization,
  },
});
