import Home from "@/views/Home.vue";

import path from "@/router/const/path";
import name from "@/router/const/name";

export default [
  {
    path: path.BASE,
    name: name.BASE,
    component: Home,
  },
  {
    path: path.SHOP_BASE,
    name: name.SHOP_BASE,
    component: Home,
  },
];
