<template>
  <a @click="openDialog('logo_url')">
    <div
      @mouseover="handleHover"
      :class="upselling ? 'my-0' : {'my-10':true, container: isInIframe }"

    >
      <a
        v-if="webUrl"
        :href="getUrlWithProtocol(webUrl)"
        target="_blank"
      >
        <v-img
          :height="height"
          :max-height="maxHeight"
          :src="logoImage"
          :alt="company.company_id"
          contain
        />
      </a>
      <v-img
        v-else-if="logoImage"
        :height="height"
        :max-height="maxHeight"
        :src="logoImage"
        contain
      />
      <v-img
        v-else
        :height="height"
        :max-height="maxHeight"
        :src="logoUrl"
        alt="Amphora"
        contain
      />
      <div v-if="isInIframe" class="overlay"></div>
    </div>
  </a>
</template>

<script>
import { logoUrl } from "@/assets/utils/s3Urls.js"
import { iframeMixin } from "@/mixins/iframeMixin";
import { useDisplay } from "vuetify";

export default {
  name: "LogoComponent",
  mixins: [iframeMixin], // imports handleHover, openDialog & data isInIframe
  props: {
    company: {
      type: Object,
      default: () => undefined,
    },
    webUrl: {
      type: String,
      default: undefined,
    },
    upselling: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      height: "150px",
      maxHeight: "250px",
      maxWidth: "350px",
      logoUrl,
    };
  },
  setup() {
    const { mobile } = useDisplay();
    return { mobile };
  },
  methods: {
    getUrlWithProtocol(url) {
      if (!url.startsWith("http")) {
        return "https://" + url;
      }
      return url;
    },
  },
  computed: {
    logoImage() {
      return this.company ? this.company.logo_url : logoUrl;
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 960px) {
  .v-img {
    margin: auto;
    max-width: 200px;
  }
}
@media (min-width: 960px) {
  .v-img {
    margin-left: auto;
    max-width: v-bind(maxWidth);
  }
  :deep .v-img__img--contain {
    object-position: right;
  }
}
:deep .v-responsive__content {
  flex: 0 0 0px !important;
}

.overlay {
  height: 100%;
  margin-left: auto;
  max-height: v-bind(maxHeight);
  max-width: v-bind(maxWidth);
}
</style>
