<template>
  <template v-if="!exceptionOrder">
    <div class="w-100 pt-5 pb-10 hidden-sm-and-down">
      <div class="container">
        <div>
          <div :class="position == '25%' ? 'selected accepted' : ''"></div>
        </div>
        <div>
          <div :class="position == '50%' ? 'selected processing' : ''"></div>
        </div>
        <div>
          <div :class="position == '75%' ? 'selected delivery' : ''"></div>
        </div>
        <div>
          <div :class="position == '100%' ? 'selected fulfilled' : ''"></div>
        </div>
      </div>
      <div class="progress-bar">
        <div class="fill-bar"></div>
      </div>
    </div>
    <div class="hidden-md-and-down">
      <div :class="position == '25%' ? 'description' : 'd-none'">
        <p>{{ $t("tracking.progress.processing") }}</p>
      </div>
      <div :class="position == '50%' ? 'description' : 'd-none'">
        <p>{{ $t("tracking.progress.shipped") }}</p>
      </div>
      <div :class="position == '75%' ? 'description' : 'd-none'">
        <p>{{ $t("tracking.progress.delivery") }}</p>
      </div>
      <div :class="position == '100%' ? 'description' : 'd-none'">
        <p v-if="!isRefused">{{ $t("tracking.progress.delivered") }}</p>
        <p v-else class="text-red">{{ $t("tracking.progress.refused") }}</p>
      </div>
    </div>
  </template>
</template>

<script>
import { INTERNAL_STATUS } from "@/services/_const.service.js";

export default {
  name: "ProgressBar",
  props: {
    order: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    position() {
      if (this.order.internal_status) {
        switch (this.order.internal_status) {
          case INTERNAL_STATUS.STATUS_PROCESSING:
          case INTERNAL_STATUS.STATUS_PROCESSING_A:
          case INTERNAL_STATUS.STATUS_PROCESSING_RESERVED_STOCK:
          case INTERNAL_STATUS.STATUS_PROCESSING_WAIT:
          case INTERNAL_STATUS.STATUS_NEW:
          case INTERNAL_STATUS.STATUS_NEW_HOLD:
          case INTERNAL_STATUS.STATUS_NEW_COD:
          case INTERNAL_STATUS.STATUS_NEW_UPSELLING:
            return "25%";
          case INTERNAL_STATUS.STATUS_SHIPPED:
          case INTERNAL_STATUS.STATUS_SHIPPED_PUDO:
            return "50%";
          case INTERNAL_STATUS.STATUS_FULFILLED:
          case INTERNAL_STATUS.STATUS_FULFILLED_LOST:
            return "100%";
        }
      }
      return "50%";
    },
    exceptionOrder() {
      if (this.order.internal_status) {
        return [INTERNAL_STATUS.STATUS_EXCEPTION].includes(
          this.order.internal_status
        );
      }
      return false;
    },
    isRefused() {
      return (
        this.order.internal_status === "FULFILLED" && this.order.refused_order
      );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.description {
  p {
    padding-bottom: 40px;
    color: rgb(var(--v-theme-success));
    text-align: left;
    font-weight: 300;
  }
}
.progress-bar {
  width: 100%;
  height: 10px;
  background: rgb(var(--v-theme-successLight));
  margin-top: -15px;
  border-radius: 9px;
  .fill-bar {
    height: 100%;
    animation: goForward 1.5s ease-in-out;
    width: v-bind(position);
    background: rgb(var(--v-theme-success));
    border-radius: 10px;
  }
}
.container {
  display: flex;
  flex-direction: row;
  div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .selected {
      position: relative;
      left: 2px;
      width: 25px;
      height: 25px;
      background: rgb(var(--v-theme-success));
      border-radius: 100%;
      border: 1px solid rgb(var(--v-theme-success));
      animation: 1.4s fadeIn;
      animation-fill-mode: forwards;

      visibility: hidden;
      .tracking-fill {
        fill: rgb(var(--v-theme-success));
        opacity: 0.3;
      }
    }
    .accepted {
      background-image: url("@/assets/svg/package.svg");
    }
    .processing,
    .delivery {
      background-image: url("@/assets/svg/truck.svg");
    }
    .fulfilled {
      background-image: url("@/assets/svg/home.svg");
    }
  }
}
@keyframes goForward {
  0% {
    width: 0;
  }
  40% {
    width: 0;
  }
  100% {
    width: v-bind(position);
  }
}
@keyframes fadeIn {
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}
</style>
