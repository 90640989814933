{
  "error": {
    "ORDER_NOT_FOUND": "Ordine $order_id non trovato",
    "INVALID_VALIDATION_PARAM": "Parametro di validazione non valido per l'ordine $order_name",
    "INCOMPLETE_BODY_PARAMS": "Parametri del corpo incompleti",
    "INCOMPLETE_COMPANY_PARAMS": "Parametri aziendali incompleti",
    "CUSTOMIZATION_NOT_FOUND": "Personalizzazione non trovata",
    "MISSING_CONTACT_EMAIL": "L'azienda $company_id non ha un'email di contatto",
    "MISSING_CUSTOMER_EMAIL": "L'ordine $order_id non ha un'email del cliente",
    "ORDER_COMPANY_NOT_FOUND": "Ordine con ID: $order_id e azienda: $company_id non trovato",
    "MISSING_BODY_PARAMS": "Parametri del corpo mancanti",
    "DUPLICATE_RETURN_ID": "Esiste già un reso con questa ID: $order_id",
    "RETURN_ORDER_NOT_FOUND": "Ordine $order_id non trovato",
    "EMPTY_RETURN_PRODUCTS": "Questo reso non ha prodotti"
  },
  "validationError": {
    "required": "Richiesto",
    "email": "Per favore, inserisci un indirizzo email valido.",
    "phone": "Per favore, inserisci un numero di telefono valido.",
    "image": "Errore di formato (Accettati: .png, .jpg, .jpeg) e dimensione del file < 5MB.",
    "number": "L'input deve essere un numero",
    "cjk": "Caratteri CJK non consentiti",
    "arabic": "Caratteri arabi non consentiti",
    "comma": "Il carattere virgola non è consentito",
    "hyphen": "Il carattere trattino non è consentito"
  }
}
