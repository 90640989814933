<template>
  <div class="mb-10">
    <h6 class="mb-10">{{ $t(`tracking.resume.order.title`) }}</h6>

    <div class="text-neutral60 font-weight-light">
      <p>{{ order.shipping_address_name }}</p>
      <p>{{ order.shipping_address }}</p>
      <p>{{ order.shipping_address_zip }}, {{ order.shipping_address_city }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderAddress",
  props: {
    order: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  line-height: 20px;
  margin-bottom: 0.6rem;
}
</style>
