<template>
  <div class="container">
    <four-zero-four />
  </div>
</template>

<script>
import FourZeroFour from "@/components/shared/FourZeroFour";

export default {
  name: "404",
  components: {
    FourZeroFour,
  },
  props: {
    company: {
      type: Object,
      default: () => {},
    },
    setLoading: {
      type: Function,
    },
  },
  mounted() {
    this.setLoading(false);
  },
};
</script>
