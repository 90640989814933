<template>
  <v-text-field
    class="input-inner text-wrap"
    v-model="customValue"
    :placeholder="placeholder"
    :rules="rules"
    :readonly="$attrs.readonly"
    :hide-details="hideDetails ? true : undefined"
    required
    :disabled="disabled"
    ><template v-slot:append-inner>
      <v-icon :style="{ color: iconColor }"> {{ innerIcon }} </v-icon>
    </template>
  </v-text-field>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    innerIcon: {
      type: String,
      default: "",
    },
    rules: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    iconColor: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number],
      default: "",
    },
  },
  inheritAttrs: true, // enable the inheritAttrs behavior
  data() {
    return {
      customValue: this.value,
    };
  },
  watch: {
    customValue: function () {
      // Emit this information to the parents component
      this.$emit("input", this.customValue);
    },
  },
  emits: ["input"], // declare the custom event
};
</script>
<style lang="scss" scoped></style>
