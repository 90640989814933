<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="btn-icon"
  >
    <g clip-path="url(#clip0_2663_48931)">
      <path
        d="M11.3333 11.8333H12.6667C13.0203 11.8333 13.3594 11.6929 13.6095 11.4428C13.8595 11.1928 14 10.8536 14 10.5V7.83333C14 7.47971 13.8595 7.14057 13.6095 6.89052C13.3594 6.64048 13.0203 6.5 12.6667 6.5H3.33333C2.97971 6.5 2.64057 6.64048 2.39052 6.89052C2.14048 7.14057 2 7.47971 2 7.83333V10.5C2 10.8536 2.14048 11.1928 2.39052 11.4428C2.64057 11.6929 2.97971 11.8333 3.33333 11.8333H4.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.3346 6.5V3.83333C11.3346 3.47971 11.1942 3.14057 10.9441 2.89052C10.6941 2.64048 10.3549 2.5 10.0013 2.5H6.0013C5.64768 2.5 5.30854 2.64048 5.05849 2.89052C4.80844 3.14057 4.66797 3.47971 4.66797 3.83333V6.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.0013 9.16699H6.0013C5.26492 9.16699 4.66797 9.76395 4.66797 10.5003V13.167C4.66797 13.9034 5.26492 14.5003 6.0013 14.5003H10.0013C10.7377 14.5003 11.3346 13.9034 11.3346 13.167V10.5003C11.3346 9.76395 10.7377 9.16699 10.0013 9.16699Z"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2663_48931">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "Print",
};
</script>
