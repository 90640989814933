<template>
  <MainWrapper :company="company" :customTextsObject="customTextsObject">
    <p class="subtitle-3 text-neutral40 mb-10" v-if="order.shipping_number">
      {{ `${$t(`options.tracking`)} ${order.shipping_number}` }}
    </p>
    <p class="subtitle-3 text-neutral40 mb-10" v-if="order.id">
      {{ `${$t(`options.id`)}: ${order.id}` }}
    </p>
    <div v-if="!loading">
      <AddIdCard :order="order" v-if="idCardException" />
      <AddAddressInfo :order="order" v-else-if="hasCarrierError" />
    </div>
    <template v-for="item in order.products" :key="item.id">
      <ProductDetails v-if="item.product" :item="item" unicItem />
    </template>
    <OrderAddress :order="order" v-if="!hasCarrierError" />
    <ChatComponent :company="company" :order="order" />
  </MainWrapper>
</template>

<script>
import { toBase, toTracking } from "@/router/navigate";
import { mapActions } from "vuex";

import MainWrapper from "@/components/containers/MainWrapper.vue";
import ChatComponent from "@/components/shared/ChatComponent.vue";

import AddIdCard from "@/components/forms/AddIdCard.vue";
import AddAddressInfo from "@/components/forms/AddAddressInfo.vue";
import OrderAddress from "@/components/blocks/OrderAddress.vue";
import ProductDetails from "@/components/blocks/ProductDetails.vue";
import {
  checkCarrierError,
  exceptionIdCard,
} from "@/helpers/const.carrierErrors.js";

export default {
  name: "AddInfo",
  components: {
    MainWrapper,
    AddIdCard,
    AddAddressInfo,
    OrderAddress,
    ProductDetails,
    ChatComponent,
  },
  props: {
    customTextsObject: {
      type: Object,
      default: () => {},
    },
    company: {
      type: Object,
      default: () => {},
    },
    setLoading: {
      type: Function,
    },
  },
  data: function () {
    return {
      loading: true,
      order: {},
      error: null,
      companyId: undefined,
    };
  },
  computed: {
    hasCarrierError() {
      return checkCarrierError(this.order);
    },
    idCardException() {
      return (
        exceptionIdCard(this.order?.exception_message) ||
        this.order.customer_id_card === "required"
      );
    },
  },
  methods: {
    ...mapActions(["findOrder"]),
    async setTracking(params) {
      this.loading = true;
      const order_params = {
        trackingNumber: params.t,
        postalCode: params.zc,
        publicKey: this.$route.params.publicKey,
        orderId: params.oi,
      };
      this.findOrder(order_params)
        .then((r) => {
          this.order = r;

          if (
            this.order.customer_id_card !== "required" &&
            !this.hasCarrierError
          ) {
            this.toTrackingRoute();
          }
        })
        .catch((e) => {
          if (e.error === 404 || e.error === 403 || e.error === 427) {
            toBase({
              publicKey: this.$route.params.publicKey,
            });
          }
        })
        .finally(() => {
          this.loading = false;
          this.setLoading(false);
        });
    },
    toTrackingRoute() {
      toTracking({
        publicKey: this.$route.params.publicKey,
        order_id: this.order.id,
        zip_code: this.order.shipping_address_zip,
        tracking: this.order.shipping_number,
      });
    },
  },
  mounted() {
    const parsedParams = {};
    this.setLoading(true);
    this.$route.hash
      .split("&")
      .map((part) => part.replace(/#/, ""))
      .forEach((param) => {
        const parts = [
          param.substr(0, param.indexOf("=")),
          param.substr(param.indexOf("=") + 1),
        ];
        parsedParams[parts[0]] = parts[1];
      });
    this.setTracking(parsedParams);
  },
};
</script>

<style scoped></style>
