import Upselling from "@/views/order/Upselling.vue";

import path from "@/router/const/path";
import name from "@/router/const/name";

export default [
  {
    path: path.UPSELLING,
    name: name.UPSELLING,
    component: Upselling,
  },
  {
    path: path.SHOP_UPSELLING,
    name: name.SHOP_UPSELLING,
    component: Upselling,
  },
];
