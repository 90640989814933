<template>
  <v-card
    class="rounded-lg mx-1 overflow-visible"
    :color="borderColor"
    width="178"
    max-height="auto"
    :style="borderStyle"
  >
    <NumberBadge
      v-if="selectedItem"
      class="mt-3 mr-2 mr-sm-n2 position-absolute"
    >
      1
    </NumberBadge>
    <v-row class="d-flex mt-4" no-gutter align="center">
      <v-col align="center">
        <v-avatar
          size="123"
          rounded="0"
          class="elevation-soft rounded-lg"
          color="neutral80"
          variant="outlined"
        >
          <v-img :src="logoImage" cover />
        </v-avatar>
        <p class="px-5 pt-4 footnote text-neutral80" style="height: 65px">
          {{ title }}
        </p>
        <p class="px-3 pb-2 caption-2 text-neutral80" style="height: 30px">
          {{ variant }}
        </p>
        <p class="px-3 pb-2 footnote">{{ price }}</p>
        <slot name="addButton"></slot>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import NumberBadge from "@/components/blocks/NumberBadge.vue";

export default {
  name: "RecommendedProductDetails",
  components: {
    NumberBadge,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    selectedItem: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    logoImage() {
      return this.item.img;
    },
    title() {
      return this.item.title;
    },
    variant() {
      return this.item.variant_title;
    },
    price() {
      return this.item.price;
    },
    borderColor() {
      return this.selectedItem ? "neutral05" : "neutral00";
    },
    borderStyle() {
      const colorVar = this.selectedItem
        ? "--v-theme-info"
        : "--v-theme-neutral40";
      return `border: 1px solid rgb(var(${colorVar}))`;
    },
  },
};
</script>

<style lang="scss" scoped></style>
