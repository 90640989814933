<template>
  <MainWrapper :company="company" :customTextsObject="customTextsObject">
    <info
      :company="company"
      :customTextsObject="customTextsObject"
      :prefixName="prefixName"
    />
  </MainWrapper>
</template>

<script>
import Info from "@/components/forms/Info.vue";
import MainWrapper from "@/components/containers/MainWrapper.vue";

export default {
  name: "Home",
  components: {
    MainWrapper,
    Info,
  },
  props: {
    company: {
      type: Object,
      default: () => {},
    },
    customTextsObject: {
      type: Object,
      default: () => {},
    },
    prefixName: {
      type: String,
      default: () => "",
    },
    setLoading: {
      type: Function,
    },
  },
  mounted() {
    this.setLoading(false);
  },
};
</script>
<style lang="scss" scoped></style>
