<template>
  <h3 class="mb-10 text-center text-md-start">
    <slot />
    <slot name="subtitle" />
  </h3>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
