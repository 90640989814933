import {
  $fetchJson,
  GET_RETURN,
  CREATE_RETURN,
  SAVE_RETURN_IMAGES,
} from "@/services/_index.service";

let cacheReturns = {};

export const getReturnByOrder = async ({ companyId, orderId }) => {
  const key = companyId + orderId;
  if (!cacheReturns[key]) {
    cacheReturns[key] = $fetchJson({
      method: "POST",
      body: {
        company_id: companyId,
        order_id: orderId,
      },
      url: GET_RETURN,
    });
  }
  return cacheReturns[key];
};

function _base64ToBlob(base64, mimeType) {
  const bytes = atob(base64.split(",")[1]);
  const array = new Uint8Array(bytes.length);

  for (let i = 0; i < bytes.length; i++) {
    array[i] = bytes.charCodeAt(i);
  }

  return new Blob([array], { type: mimeType });
}

export const saveReturnImages = async ({
  companyId,
  orderId,
  returnProducts,
}) => {
  const formData = new FormData();
  formData.append("company_id", companyId);
  formData.append("order_id", orderId);
  let img_quantity = 0;

  for (const product of returnProducts) {
    const images = product.images;
    img_quantity += images.length;
    for (const image of images) {
      const blob = _base64ToBlob(
        image.src,
        "image/" + image.name.substring(image.name.indexOf(".") + 1)
      );

      const cleanFile = new File([blob], image.name, {
        type: image.name.substring(image.name.indexOf(".") + 1),
      });
      formData.append("img_product", cleanFile);
    }
  }
  formData.append("img_quantity", img_quantity);
  return fetch(SAVE_RETURN_IMAGES, {
    method: "POST",
    body: formData,
  });
};

export const createReturn = async ({
  returnProducts,
  costumerEmail,
  changeProducts,
  companyId,
  orderId,
  afloraImages = [],
}) => {
  const requestBody = {
    change_products: changeProducts,
    company_id: companyId,
    order_id: orderId,
    costumer_email: costumerEmail,
    return_products: returnProducts,
  };

  if (afloraImages.length > 0) {
    requestBody.aflora_images = afloraImages;
  }

  await $fetchJson({
    method: "POST",
    body: requestBody,
    url: CREATE_RETURN,
  });
};
