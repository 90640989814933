import Checkout from "@/views/order/Checkout.vue";

import path from "@/router/const/path";
import name from "@/router/const/name";

export default [
  {
    path: path.CHECKOUT,
    name: name.CHECKOUT,
    component: Checkout,
  },
  {
    path: path.SHOP_CHECKOUT,
    name: name.SHOP_CHECKOUT,
    component: Checkout,
  },
];
