<template>
  <v-label class="pl-10 pb-4">
    <h6 class="d-inline"><slot></slot></h6>
    <slot name="tooltip"></slot>
  </v-label>
  <v-text-field
    class="input-custom mb-8 text-wrap"
    v-model="customValue"
    :placeholder="placeholder"
    :rules="rules"
    :readonly="$attrs.readonly"
    variant="plain"
    :hide-details="hideDetails ? true : undefined"
    required
    :append-inner-icon="innerIcon"
    :disabled="disabled"
  />
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    innerIcon: {
      type: String,
      default: "",
    },
    rules: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      default: "",
    },
  },
  inheritAttrs: true, // enable the inheritAttrs behavior
  data() {
    return {
      customValue: this.value,
    };
  },
  watch: {
    customValue: function () {
      // Emit this information to the parents component
      this.$emit("input", this.customValue);
    },
  },
  emits: ["input"], // declare the custom event
};
</script>
<style lang="scss" scoped></style>
