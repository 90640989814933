<template>
  <LoadingComponent v-if="isEmptyObject(customCompany)" :company="company" />
  <v-fade-transition v-else>
    <MainWrapper :company="customCompany" :customTextsObject="customText">
      <OrderName :order="fakeOrder" :needAddInfo="false" />
      <ProgressBarMobile :order="fakeOrder" />
      <ProgressBar :order="fakeOrder" />
      <a @click="openDialog('color')">
        <div @mouseover="handleHover" :class="{ container: isInIframe }">
          <ShippingDetails :order="fakeOrder" />
          <div class="overlay"></div>
        </div>
      </a>
      <h6 class="">{{ $t(`info.title.your_order`) }}</h6>
      <ShippingNumber :order="fakeOrder" />
      <template v-for="item in fakeOrder.products" :key="item.id">
        <ProductDetails v-if="item.product" :item="item" unicItem />
      </template>
      <OrderAddress :order="fakeOrder" />
      <ChatComponent :company="customCompany" :order="fakeOrder" />
    </MainWrapper>
  </v-fade-transition>
</template>

<script>
import LoadingComponent from "@/components/shared/LoadingComponent";
import MainWrapper from "@/components/containers/MainWrapper.vue";
import ChatComponent from "@/components/shared/ChatComponent.vue";
import ProgressBar from "@/components/blocks/ProgressBar.vue";
import ProgressBarMobile from "@/components/blocks/ProgressBarMobile.vue";
import OrderName from "@/components/blocks/OrderName.vue";
import ShippingNumber from "@/components/blocks/ShippingNumber.vue";
import ShippingDetails from "@/components/blocks/ShippingDetails.vue";
import OrderAddress from "@/components/blocks/OrderAddress.vue";
import ProductDetails from "@/components/blocks/ProductDetails.vue";

import { iframeMixin } from "@/mixins/iframeMixin";

export default {
  name: "Customizations",
  mixins: [iframeMixin], // imports handleHover, openDialog & data isInIframe, dataType
  components: {
    LoadingComponent,
    MainWrapper,
    ProgressBar,
    ProgressBarMobile,
    OrderName,
    ShippingDetails,
    OrderAddress,
    ProductDetails,
    ShippingNumber,
    ChatComponent,
  },
  props: {
    company: {
      type: Object,
      default: () => {},
    },
    customTextsObject: {
      type: Object,
      default: () => {},
    },
    prefixName: {
      type: String,
      default: () => "",
    },
    setLoading: {
      type: Function,
    },
  },
  data() {
    return {
      customCompany: {},
      customText: {},
      loading: true,
      fakeOrder: {
        company_id: "amphora",
        shipping_address: "Travessera de Gràcia, 58",
        id: "SHP 913190762565114828",
        traking_url:
          "https://www.gls-spain.es/es/recibir-paquetes/seguimiento-envio/",
        name: "#00000",
        shipping: "GLS",
        shipping_address_city: "Barcelona",
        customer_email: "no-reply@amphoralogistics.com",
        shipping_address_zip: "08006",
        shipping_address_country_code: "ES",
        internal_status: "SHIPPED",
        shipping_address_name: "Ramirio",
        shipping_number: "102030405060708090",
        products: [
          {
            quantity: "1",
            product: {
              company_id: "amphora",
              img: "https://sls-core-analytics-data-bucket.s3.eu-central-1.amazonaws.com/www.somecompany.com%2Fimages%2Fr0SBPVt2iM3uXOhaTtCr.jpg",
              title: "Nombre producto",
              variant_title: "Talla S",
            },
          },
        ],
      },
      error: null,
    };
  },
  methods: {
    updateImages(data) {
      const { type, src } = data;
      if (type === "banner") {
        this.customCompany.advertising[type] = src;
      } else if (type === "background") {
        this.customCompany.company_img = src.company_img;
        this.customCompany.company_video = src.company_video;
      } else {
        this.customCompany[type] = src;
      }
    },
    updateColor(data) {
      document.body.style.setProperty("--amph-light", data.src + "1A");
    },
    updateTextValue(data) {
      const { type, key, value } = data;
      if (type === "socialMedia") {
        this.customCompany.advertising.socialMedia[key] = value;
      }
      if (type === "footer") {
        this.customCompany.advertising.urls[key] = value;
      }
    },
    defaultData(data) {
      this.customCompany = {
        ...this.customCompany,
        company_id: data.company_id,
        logo_url: data.logoUrl,
        company_img: data.company_img,
        customer_email: data.customer_email,
        company_name: data.company_name,
        company_video: data.company_video,
        advertising: {
          banner: data.banner,
          urls: {
            web: data.web,
            contact: data.contact,
            help: data.help,
            policy: data.policy,
          },
          socialMedia: {
            instagram: data.instagram,
            tiktok: data.tiktok,
            twitter: data.twitter,
            youtube: data.youtube,
            facebook: data.facebook,
            spotify: data.spotify,
          },
        },
      };
      if (typeof data.colors === "string") {
        document.body.style.setProperty("--amph-light", data.colors + "1A");
      } else if (Array.isArray(data.colors) && data.colors.length > 0) {
        document.body.style.setProperty("--amph-light", data.colors[0] + "1A");
      }
      this.customText = {
        ...this.customText,
        ...data.text_costum,
      };
    },
    isEmptyObject(obj) {
      for (let key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          if (typeof obj[key] === "object" && obj[key] !== null) {
            if (!this.isEmptyObject(obj[key])) {
              return false;
            }
          } else if (
            obj[key] !== undefined &&
            obj[key] !== null &&
            obj[key] !== ""
          ) {
            return false;
          }
        }
      }
      return true;
    },
  },
  mounted() {
    this.setLoading(false);
    window.addEventListener("message", (event) => {
      const { action, ...data } = event.data;
      switch (action) {
        case "updateImage":
          this.updateImages(data);
          break;
        case "updateColor":
          this.updateColor(data);
          break;
        case "updateTextValue":
          this.updateTextValue(data);
          break;
        default:
          this.defaultData(data);
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.container {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: auto;
  margin: 0 -44px;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgb(var(--v-theme-infoLight));
  box-shadow: 0px 0px 0px 2px rgb(var(--v-theme-info)) inset;
}

.container:hover .overlay {
  cursor: url("../../assets/img/click-to-edit.png"), auto;
  opacity: 0.5;
}
</style>
