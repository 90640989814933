<template>
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="btn-icon"
  >
    <g clip-path="url(#clip0_564_26558)">
      <path
        d="M7.16667 11.8563C9.744 11.8563 11.8333 9.76694 11.8333 7.18962C11.8333 4.61229 9.744 2.52295 7.16667 2.52295C4.58934 2.52295 2.5 4.61229 2.5 7.18962C2.5 9.76694 4.58934 11.8563 7.16667 11.8563Z"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.1665 7.18945H9.1665"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.1665 5.18945V9.18945"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.5 14.5229L10.5 10.5229"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_564_26558">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0.5 0.522949)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "Lens",
};
</script>

<style lang="scss" scoped></style>
