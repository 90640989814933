<template>
  <div class="mb-10">
    <a @click="openDialog('banner')">
      <div
        style="position: sticky; top: 0"
        :class="{ container: isInIframe }"
        @mouseover="handleHover"
        class="mb-5"
      >
        <div v-if="checkForBanner()">
          <a
            v-if="promotions.banner.url"
            :href="promotions.banner.url"
            target="_blank"
          >
            <v-img
              :src="promotions.banner.img"
              :height="250"
              class="mb-5"
              width="100%"
              cover
            />
          </a>
          <v-img
            v-else
            :src="promotions.banner.img"
            :height="250"
            width="100%"
            class="mb-5"
            cover
          />
        </div>
        <div v-if="!checkForBanner() && isIframe" class="container">
          <v-row justify="center" align="center">
            <v-icon
              class="bg-infoLight rounded-pill"
              size="40"
              color="info"
              icon="mdi-plus"
            />
            <p class="text-info mx-2">
              {{ $t("iframe.addBanner") }}
            </p>
            <v-card :height="250" class="align-center" flat />
          </v-row>
        </div>
        <div v-if="isInIframe" class="overlay"></div>
      </div>
    </a>
    <div
      class="d-flex flex-column justify-center justify-lg-start text-center flex-lg-row mx-10"
      v-if="promotions.urls"
    >
      <v-btn
        v-if="webUrl"
        class="btn-tertiary pl-md-0"
        :ripple="false"
        :href="getUrlWithProtocol(webUrl)"
        target="_blank"
        variant="plain"
      >
        {{ cleanWebUrl(webUrl) }}
      </v-btn>
      <div v-for="(url, key) in promotions.urls" :key="key">
        <v-btn
          v-if="url && key !== 'web'"
          class="btn-tertiary pl-md-0 text-neutral40"
          :ripple="false"
          :href="getUrlWithProtocol(url)"
          target="_blank"
          variant="plain"
        >
          {{ $t(`footer.${key}`) }}
        </v-btn>
      </div>
    </div>
    <div
      class="d-flex flex-row justify-center justify-lg-start align-center mx-8"
      v-if="promotions.socialMedia"
    >
      <div v-for="(url, key) in promotions.socialMedia" :key="key">
        <a
          v-if="url"
          class="mx-2"
          :href="getUrlWithProtocol(url)"
          target="_blank"
        >
          <v-img :src="`${s3url}${key}.png`" width="32" class="mx-2"> </v-img>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { iframeMixin } from "@/mixins/iframeMixin";

export default {
  mixins: [iframeMixin], // imports handleHover, openDialog & data isInIframe
  props: {
    promotions: {
      type: Object,
      default: () => {},
    },
    webUrl: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      s3url: "https://amphora-images.s3.eu-central-1.amazonaws.com/logos/",
    };
  },
  computed: {
    isIframe() {
      return window !== window.parent;
    },
  },
  methods: {
    getUrlWithProtocol(url) {
      if (!url.startsWith("http")) {
        return "https://" + url;
      }
      return url;
    },
    cleanWebUrl(url) {
      return url.replace(/(^\w+:|^)\/\//, "").replace(/\/$/, "");
    },
    checkForBanner() {
      return !!(
        this.promotions.banner &&
        this.promotions.banner.img &&
        this.promotions.banner.img.trim() !== ""
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  height: 250px;
}
.overlay {
  height: 250px;
}
</style>
