import { INTERNAL_STATUS } from '@/services/_const.service.js'
import i18n from "@/i18n"

const ERROR_CODES = {
  CARRIER_EXCEPTION: {
    code: "ECARRIER001",
    message: "An error ocurred while trying to generate the shipping label",
  },
  CARRIER_WRONG_POSTAL_CODE: {
    code: "ECARRIER002",
    message: "The postal code is wrong",
    title: i18n.global.t("exceptionsCarrier.CARRIER_WRONG_POSTAL_CODE.title"),
    subtitle: i18n.global.t("exceptionsCarrier.CARRIER_WRONG_POSTAL_CODE.subtitle"),
  },
  CARRIER_WRONG_ADDRESS: {
    code: "ECARRIER003",
    message: "The address is wrong",
    title: i18n.global.t("exceptionsCarrier.carrierMessage"),
  },
  CARRIER_ADDRESS_TOO_LONG: {
    code: "ECARRIER004",
    message: "The address is too long",
    title: i18n.global.t("exceptionsCarrier.CARRIER_ADDRESS_TOO_LONG.title"),
    subtitle: i18n.global.t("exceptionsCarrier.CARRIER_ADDRESS_TOO_LONG.subtitle"),
  },
  CARRIER_ID_REQUIRED: {
    code: "ECARRIER005",
    message: "Id is required to generate shipping label",
  },
  CARRIER_EMAIL_REQUIRED: {
    code: "ECARRIER006",
    message: "Email is required to generate shipping label",
  },
  CARRIER_PHONE_REQUIRED: {
    code: "ECARRIER007",
    message: "Phone is required to generate shipping label",
    title: i18n.global.t("exceptionsCarrier.CARRIER_PHONE_REQUIRED.title"),
    subtitle: i18n.global.t("exceptionsCarrier.CARRIER_PHONE_REQUIRED.subtitle"),
  },
  CARRIER_NON_LATIN_CHARACTERS: {
    code: "ECARRIER009",
    message: "Some information contains non-Latin characters",
  },
  CARRIER_PHONE_TOO_LONG: {
    code: "ECARRIER011",
    message: "Phone number too long",
    title: i18n.global.t("exceptionsCarrier.CARRIER_PHONE_TOO_LONG.title"),
    subtitle: i18n.global.t("exceptionsCarrier.CARRIER_PHONE_TOO_LONG.subtitle"),
  },
  CARRIER_PHONE_TOO_SHORT: {
    code: "ECARRIER011",
    message: "Phone number too short",
    title: i18n.global.t("exceptionsCarrier.CARRIER_PHONE_TOO_SHORT.title"),
    subtitle: i18n.global.t("exceptionsCarrier.CARRIER_PHONE_TOO_SHORT.subtitle"),
  },
  CARRIER_ADDRESS_TOO_SHORT: {
    code: "ECARRIER011",
    message: "The address is too short",
    title: i18n.global.t("exceptionsCarrier.CARRIER_ADDRESS_TOO_SHORT.title"),
    subtitle: i18n.global.t("exceptionsCarrier.CARRIER_ADDRESS_TOO_SHORT.subtitle"),
  },
  CARRIER_WRONG_CUSTOMER_NAME: {
    code: "ECARRIER014",
    message: "The customer name is wrong (either to short or too long)",
    title: i18n.global.t("exceptionsCarrier.carrierMessage"),
  },
}

export const isCarrierError = (errorCode) => {
  return Object.keys(ERROR_CODES).some(
    (error) => ERROR_CODES[error].code == errorCode
  )
}

const getExceptionCarrierCode = (exceptionMessage) => {
  const match = exceptionMessage.match(/ECARRIER\d{3}/);
  return match ? match[0] : "";
};

export const exceptionIdCard = (exceptionMessage) => {
  return exceptionMessage.includes(ERROR_CODES.CARRIER_ID_REQUIRED.code);
}

export const checkCarrierError = (order) => {
  const carrierException = order?.internal_status === INTERNAL_STATUS.STATUS_EXCEPTION_CURRIER;
  const exceptionMessage = order?.exception_message;

  if (!carrierException || !exceptionMessage) {
    return false;
  }

  const errorCode = getExceptionCarrierCode(exceptionMessage);
  return isCarrierError(errorCode);
}

export const getErrorDetails = (exceptionMessage) => {
  const errorCode = getExceptionCarrierCode(exceptionMessage);
  const extraMessage = exceptionMessage.split("/")[1];
  for (const key in ERROR_CODES) {
    if (ERROR_CODES[key].code === errorCode) {
      return {
        title: ERROR_CODES[key].title,
        subtitle: ERROR_CODES[key].subtitle || extraMessage,
      };
    }
  }
  return null;
};