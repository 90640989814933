<template>
  <v-container class="ma-0 pa-0" fluid>
    <v-row no-gutters>
      <v-col class="hidden-md-and-up" :cols="12" :sm="12">
        <div class="discount-text text-center">
          <span>
            {{ $t("upselling.discount.text") }}
          </span>
        </div>
        <v-img
          class="rounded-b-xl elevation-strong"
          height="270px"
          :src="companyImg"
          :alt="company.company_id"
          cover
        />
      </v-col>
      <v-col class="px-11" :cols="12" :sm="12" :md="6" :lg="6" :xl="6">
        <v-row align="center" class="justify-content-start" no-gutters>
          <v-col :order="2" :order-md="1" :cols="12" :sm="6" :md="7">
            <div class="mb-5 my-md-10">
              <p class="caption-1 pb-1 text-no-wrap text-left font-weight-bold">
                {{ $t("upselling.upsellingProducts.timeText") }}
              </p>
              <CountDown
                :initialSeconds="initialTime"
                @countdown-finished="timeOut"
              />
            </div>
          </v-col>
          <v-col :order-sm="1" :order-md="2" :cols="12" :sm="6" :md="5">
            <logo :company="company" :webUrl="webUrl" upselling />
          </v-col>
        </v-row>
        <slot />
        <CompanyInfo
          v-if="company.advertising"
          class="mx-n11"
          :promotions="promotions"
          :webUrl="webUrl"
        />
        <Footer :customTextsObject="customTextsObject" />
      </v-col>
      <v-col class="hidden-sm-and-down bg-neutral05 pa-0 ma-0" :lg="6" :xl="6">
        <a @click="openDialog('background')">
          <div
            style="position: sticky; top: 0"
            :class="{ container: isInIframe }"
            @mouseover="handleHover"
          >
            <p class="discount">{{ company.upselling_discount }}%</p>
            <div class="discount-text text-center">
              <span> {{ $t("upselling.discount.text") }} </span>
            </div>
            <a v-if="webUrl" :href="getUrlWithProtocol(webUrl)" target="_blank">
              <video
                v-if="company.company_video"
                :src="companyVideo"
                class="h-screen ma-0 pa-0"
                style="position: sticky; top: 0"
                autoplay="autoplay"
                type="video/mp4"
                playsinline=""
                muted=""
                loop="loop"
              ></video>
              <v-img
                v-else
                class="h-screen ma-0 pa-0"
                style="position: sticky; top: 0"
                :src="companyImg"
                :alt="company.company_id"
                cover
              />
            </a>
            <template v-else>
              <video
                v-if="company.company_video"
                :src="companyVideo"
                class="h-screen ma-0 pa-0"
                style="position: sticky; top: 0"
                autoplay="autoplay"
                type="video/mp4"
                playsinline=""
                muted=""
                loop="loop"
              ></video>
              <v-img
                v-else
                class="h-screen ma-0 pa-0"
                style="position: sticky; top: 0"
                :src="companyImg"
                :alt="company.company_id"
                cover
              />
            </template>
            <div v-if="isInIframe" class="overlay"></div>
          </div>
        </a>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { imageUrl } from "@/assets/utils/s3Urls.js"
import Logo from "@/components/shared/Logo.vue";
import Footer from "@/components/blocks/Footer.vue";
import CompanyInfo from "@/components/blocks/CompanyInfo.vue";
import { iframeMixin } from "@/mixins/iframeMixin";
import CountDown from "@/components/utils/CountDown.vue";

export default {
  name: "UpsellingWrapper",
  mixins: [iframeMixin], // imports handleHover, openDialog & data isInIframe
  data() {
    return {
      imageUrl,
      webUrl: null,
    };
  },
  components: {
    Logo,
    Footer,
    CompanyInfo,
    CountDown,
  },
  props: {
    customTextsObject: {
      type: Object,
      default: () => {},
    },
    company: {
      type: Object,
      default: () => {},
    },
    prefixName: {
      type: String,
      default: () => "",
    },
    order: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    this.checkAdvertising();
  },
  watch: {
    company: {
      handler: "checkAdvertising",
      deep: true,
    },
  },
  computed: {
    companyImg() {
      return this.company.company_img
        ? this.company.company_img
        : this.imageUrl;
    },
    companyVideo() {
      return this.company ? this.company.company_video : undefined;
    },
    initialTime() {
      if (!this.order || !this.order.time_upselling) {
        return 0;
      }
      const orderTime = new Date(this.order.time_upselling);

      const currentTime = new Date();

      const timeDifferenceMs = currentTime - orderTime;

      const timeDifferenceSeconds = Math.floor(timeDifferenceMs / 1000);

      const hoursElapsed = timeDifferenceSeconds / 3600;

      const remainingTimeSeconds = Math.max(0, 3 * 3600 - hoursElapsed * 3600);

      return remainingTimeSeconds;
    },
  },
  methods: {
    checkAdvertising() {
      if (this.company.advertising) {
        this.promotions = this.company.advertising;
        this.webUrl = this.company.advertising.urls.web;
      }
    },
    getUrlWithProtocol(url) {
      if (!url.startsWith("http")) {
        return "https://" + url;
      }
      return url;
    },
    timeOut() {
      this.$emit("timeOut");
    },
  },
};
</script>

<style lang="scss" scoped>
video {
  width: 100%;
  object-fit: cover;
  z-index: 4;
}

@media screen and (max-width: 600px) {
  video {
    width: 50%;
  }
}
.discount {
  position: absolute;
  color: rgba(242, 35, 35, 0.55);
  font-size: 480px;
  line-height: 1;
  font-weight: 900;
  z-index: 5;
  font-style: normal;
  top: 51%;
}

.discount-text {
  position: absolute;
  color: rgb(255, 255, 255);
  background-color: rgba(242, 35, 35, 0.55);
  font-weight: 600;
  font-size: 20px;
  line-height: 2;
  z-index: 5;
  top: 96%;
  left: 0%;
  right: 0%;
}

@media (max-width: 1900px) {
  .discount {
    font-size: 400px;
  }
  .discount-text {
    font-size: 20px;
  }
}
@media (max-width: 1300px) {
  .discount {
    font-size: 299px;
  }
  .discount-text {
    font-size: 18px;
  }
}

@media (max-width: 1150px) {
  .discount-text {
    font-size: 16px;
    line-height: 3;
  }
}

@media (max-width: 960px) {
  .discount-text {
    font-size: 12px;
    top: 0%;
  }
}

@media (max-width: 360px) {
  .discount-text {
    font-size: 10px;
    top: 0%;
  }
}

.overlay {
  height: 100%;
}
</style>
