<template>
  <v-fade-transition>
    <MainWrapper
      v-if="!loading"
      :company="company"
      :customTextsObject="customTextsObject"
    >
      <ReturnSteps>
        <template v-slot:step> {{ steps[currentStep].step }} </template>
        <template v-slot:content>
          {{ steps[currentStep].content }}
        </template>
      </ReturnSteps>
      <CustomAlert :error="error?.error" :text="translatedErrorMessage" />
      <p
        class="subtitle-3 text-success mb-10 text-center text-lg-start"
        v-if="!returned"
      >
        {{ $t(`product.return.date`) }} {{ returnDate }}
      </p>
      <h5 class="mb-10">{{ $t(`info.title.your_products`) }}</h5>
      <ShippingNumber :order="order" />
      <template v-for="item in order.products" :key="item.id">
        <ProductDialog
          v-if="item.product"
          :item="item"
          :disabled="returned"
          :returned="returned && returnedList.includes(item.id)"
          :returnProducts="returnProducts"
          :company="company"
          @canceled="cancelProduct($event)"
          @selected="returnedProduct($event)"
        />
      </template>
      <OrderAddress :order="order" />
      <EmailDialog @customerEmail="getEmail($event)" v-model="showDialog" />
      <ChatComponent :company="company" :order="order" />
    </MainWrapper>
  </v-fade-transition>
  <ReturnButtons
    v-if="returnProducts.length > 0"
    :cancel="cancel"
    :submit="submit"
    :buttonLoading="buttonLoading"
    :returnProducts="returnProducts"
  />
</template>

<script>
import { mapActions } from "vuex";
import { toBase } from "@/router/navigate";
import { toReturnsInfo } from "@/router/navigate";
import {
  getReturnByOrder,
  createReturn,
  saveReturnImages,
} from "@/services/returns.service.js";
import { replaceErrorMessage } from "@/helpers/const.utils";
import MainWrapper from "@/components/containers/MainWrapper.vue";
import ChatComponent from "@/components/shared/ChatComponent.vue";
import ProductDialog from "@/components/dialogs/ProductDialog.vue";
import EmailDialog from "@/components/dialogs/EmailDialog.vue";

import OrderAddress from "@/components/blocks/OrderAddress.vue";
import ReturnSteps from "@/components/blocks/ReturnSteps.vue";
import ReturnButtons from "@/components/blocks/ReturnButtons.vue";
import ShippingNumber from "@/components/blocks/ShippingNumber.vue";
import CustomAlert from "@/components/blocks/CustomAlert.vue";

export default {
  name: "Returns",
  components: {
    MainWrapper,
    OrderAddress,
    ShippingNumber,
    ProductDialog,
    ReturnSteps,
    ReturnButtons,
    EmailDialog,
    ChatComponent,
    CustomAlert,
  },
  props: {
    customTextsObject: {
      type: Object,
      default: () => {},
    },
    rules: {
      type: Object,
      default: () => {},
    },
    setLoading: {
      type: Function,
    },
    company: {
      type: Object,
      default: () => {},
    },
    item: {
      type: Object,
      default: () => undefined,
    },
    index: {
      type: Number,
      default: () => null,
    },
    selectedMessage: {
      type: String,
      default: () => undefined,
    },
    prefixName: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      loading: true,
      buttonLoading: false,
      returned: false,
      order: {},
      returnedList: [],
      returnProducts: [],
      currentStep: 0,
      showDialog: false,
      error: null,
    };
  },
  created() {
    const parsedParams = {};
    this.setLoading(true);
    this.$route.hash
      .split("&")
      .map((part) => part.replace(/#/, ""))
      .forEach((param) => {
        const parts = [
          param.substr(0, param.indexOf("=")),
          param.substr(param.indexOf("=") + 1),
        ];
        parsedParams[parts[0]] = parts[1];
      });
    this.setOrder(parsedParams);
  },
  computed: {
    translatedErrorMessage() {
      if (this.error) {
        return replaceErrorMessage(this.error, this.$t);
      }
      return null;
    },
    returnDate() {
      if (this.order) {
        if (this.order.time_fulfilled) {
          const timeFulfilled = new Date(this.order.time_fulfilled);
          const fulfillmentDays = Number(this.rules.fulfillmentDays);
          const nextDate = new Date(
            timeFulfilled.getTime() + fulfillmentDays * 24 * 60 * 60 * 1000
          );
          return nextDate.toLocaleDateString("es-ES", {
            timeZone: "Europe/Madrid",
          });
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    steps() {
      return [
        {
          step: this.$t(`steps.return.step1.step`),
          content: this.$t(`steps.return.step1.desc`),
        },
        {
          step: this.$t(`steps.return.step2.step`),
          content:
            this.order.products.length > 1
              ? this.$t("steps.return.step2.desc2")
              : this.$t("steps.return.step2.desc"),
        },
        {
          step: "",
          content: this.$t(`steps.return.step3.desc`),
        },
      ];
    },
  },
  methods: {
    ...mapActions(["findOrder"]),
    async setOrder(params) {
      const orderParams = {
        trackingNumber: params.t,
        postalCode: params.zc,
        publicKey: this.$route.params.publicKey,
        orderId: params.oi,
        products: true,
      };

      try {
        const res = await this.findOrder(orderParams);

        this.order = res;
        const { company_id: companyId, id: orderId } = this.order;
        const returns = await getReturnByOrder({
          companyId,
          orderId,
        });
        if (returns.return !== null) {
          this.currentStep = 2;
          this.returned = true;
          if ("products_returns" in returns.return) {
            this.returnedList = Object.keys(returns.return.products_returns);
          }
          if ("products_returns_original" in returns.return) {
            this.returnedList = Object.keys(
              returns.return.products_returns_original
            );
          }
        }
      } catch (e) {
        if (e.error === 404 || e.error === 403 || e.error === 427) {
          toBase({ publicKey: this.$route.params.publicKey, error: e.message });
        } else this.error = e;
      } finally {
        this.loading = false;
        this.setLoading(false);
      }
    },
    getEmail(email) {
      this.order.customer_email = email;
      this.showDialog = false;
      this.submit();
    },
    async submit() {
      this.buttonLoading = true;
      if (!this.order.customer_email) {
        this.showDialog = true;
        this.buttonLoading = false;
        return; // exit the function to prevent further execution
      }
      if (!this.showDialog) {
        let aflora_images = [];
        if (
          this.returnProducts.length > 0 &&
          Object.keys(this.returnProducts[0].images).length !== 0
        ) {
          try {
            const res = await saveReturnImages({
              returnProducts: this.returnProducts,
              companyId: this.order.company_id,
              orderId: this.order.id,
            });
            if (res.ok) {
              aflora_images = await res.json();
            }
          } catch (e) {
            console.error(e);
          } finally {
            for (const product of this.returnProducts) {
              delete product.images;
            }
          }
        }
        try {
          await createReturn({
            returnProducts: this.returnProducts,
            costumerEmail: this.order.customer_email,
            companyId: this.order.company_id,
            orderId: this.order.id,
            afloraImages: aflora_images,
          });
          this.showDialog = false;
          this.returnedList.push(this.returnProducts);
        } catch (e) {
          console.error(e);
        } finally {
          this.currentStep = 2;
          this.returned = true;
          this.buttonLoading = false;
          this.toReturnInfoRoute();
        }
      }
    },
    cancel() {
      this.currentStep = 0;
      this.returnProducts = [];
    },
    cancelProduct(id) {
      const index = this.returnProducts.findIndex(
        (product) => product.id === id
      );
      if (index !== -1) {
        this.returnProducts.splice(index, 1);
        if (this.returnProducts.length === 0) {
          this.currentStep = 0;
        }
      }
    },
    returnedProduct(product) {
      const duplicate = this.returnProducts.find((item) => {
        return item.id == product.id;
      });

      if (duplicate) {
        duplicate.message = product.message;
        duplicate.quantity = product.quantity;
      } else {
        this.currentStep = 1;
        this.returnProducts.push(product);
      }
    },
    toReturnInfoRoute() {
      toReturnsInfo({
        publicKey: this.$route.params.publicKey,
        tracking: this.order.shipping_number,
        zip_code: this.order.shipping_address_zip,
        order_id: this.order.id,
      });
    },
  },
};
</script>
