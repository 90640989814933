<template>
  <v-fade-transition>
    <UpsellingWrapper
      v-if="!loading"
      :company="company"
      :customTextsObject="customTextsObject"
      upselling
      :order="order"
      @timeOut="upsellingTimeOut"
    >
      <div
        v-if="order.internal_status !== 'NEW_UPSELLING' || timeOut"
        class="bg-neutral05 mx-n11 px-11 my-10 mt-16"
      >
        <h5 class="py-5 subtitle-3">
          {{ $t("upselling.finished.condolences") }}
        </h5>
        <p class="pb-5 subtitle-3 text-neutral80" style="max-width: 750px">
          {{ $t("upselling.finished.condolencesBody1") }}
          {{ order.company_id }} {{ $t("upselling.finished.condolencesBody2") }}
        </p>

        <p class="pb-5 subtitle-3 text-neutral80" style="max-width: 790px">
          {{ $t("upselling.finished.condolencesBody3") }}
        </p>
      </div>
      <div v-else>
        <UpsellingProducts
          v-model="selectedProducts"
          :order="order"
          :upsellingProducts="upsellingProducts"
          :cartOpen="visualizeCart"
          :addProduct="addProduct"
          :modifyProduct="modifyProduct"
          :loading="productsLoading"
        />
        <OrderName :order="order" :needAddInfo="needAddInfo" />
        <template v-if="!needAddInfo">
          <ProgressBarMobile :order="order" />
          <ProgressBar :order="order" />
        </template>
        <ProductsPaySummary
          :showSummary="openSummary"
          :order="order"
          :selectedProducts="selectedProducts"
          @viewCartChild="viewCart"
          :deleteProduct="deleteProduct"
          @updateSummary="updateSummary"
        />

        <h6 class="my-10 subtitle-3">{{ $t(`info.title.your_order`) }}</h6>
        <OrderNumber :order="order" />
        <template v-for="item in order.products" :key="item.id">
          <ProductDetails
            v-if="item.product"
            :item="item"
            unicItem
            :border="false"
          />
        </template>
        <OrderAddress :order="order" />
      </div>

      <RecommendedProducts
        v-model="selectedProducts"
        :addProduct="addProduct"
        :modifyProduct="modifyProduct"
        :order="order"
        :recommendedProducts="recommendedProducts"
        :goToExternalLink="goToShopLink"
        :upsellingFinished="order.internal_status !== 'NEW_UPSELLING'"
        class="pb-8"
      />
      <div v-if="order.internal_status !== 'NEW_UPSELLING'">
        <h5 class="pt-16 subtitle-3">
          {{ $t("upselling.finished.titleTracking") }}
        </h5>
        <v-btn
          append-icon="mdi-arrow-bottom-right"
          class="btn btn-primary mb-5 mt-4"
          @click="toTrackingRoute()"
        >
          {{ $t("upselling.finished.trackingButton") }}
        </v-btn>

        <h1 class="my-10 subtitle-3">{{ $t(`info.title.your_order`) }}</h1>
        <OrderNumber :order="order" />
        <template v-for="item in order.products" :key="item.id">
          <ProductDetails
            v-if="item.product"
            :item="item"
            unicItem
            :border="false"
          />
        </template>
        <OrderAddress :order="order" />
      </div>
    </UpsellingWrapper>
  </v-fade-transition>
  <UpsellingCart
    v-if="visualizeCart"
    :selectedProducts="selectedProducts"
    :order="order"
    @deleteAll="deleteAll"
    class="position-fixed ma-0 pa-0"
    style="z-index: 6; top: 91%; left: 50%; transform: translate(-50%, -50%)"
  ></UpsellingCart>
</template>

<script>
import { mapActions } from "vuex";
import { toBase, toTracking } from "@/router/navigate";
import { getUpsellingProducts } from "@/services/upselling.services";
import ProgressBar from "@/components/blocks/ProgressBar.vue";
import ProgressBarMobile from "@/components/blocks/ProgressBarMobile.vue";
import OrderName from "@/components/blocks/OrderName.vue";
import OrderNumber from "@/components/blocks/OrderNumber.vue";
import OrderAddress from "@/components/blocks/OrderAddress.vue";

import UpsellingWrapper from "@/components/containers/UpsellingWrapper.vue";
import ProductDetails from "@/components/blocks/ProductDetails.vue";
import RecommendedProducts from "@/components/blocks/RecommendedProducts.vue";
import UpsellingProducts from "@/components/blocks/UpsellingProducts.vue";
import UpsellingCart from "@/components/blocks/UpsellingCart.vue";
import ProductsPaySummary from "@/components/dialogs/ProductsPaySummary";

export default {
  name: "Upselling",
  components: {
    UpsellingWrapper,
    ProductDetails,
    RecommendedProducts,
    UpsellingProducts,
    ProgressBar,
    ProgressBarMobile,
    OrderName,
    OrderNumber,
    OrderAddress,
    UpsellingCart,
    ProductsPaySummary,
  },
  props: {
    customTextsObject: {
      type: Object,
      default: () => {},
    },
    setLoading: {
      type: Function,
    },
    company: {
      type: Object,
      default: () => undefined,
    },
  },
  data() {
    return {
      loading: true,
      order: {},
      error: null,
      upsellingProducts: [],
      recommendedProducts: [],
      selectedProducts: {},
      visualizeCart: false,
      openSummary: false,
      timeOut: false,
      productsLoading: true,
    };
  },
  mounted() {
    const parsedParams = {};
    this.setLoading(true);
    this.$route.hash
      .split("&")
      .map((part) => part.replace(/#/, ""))
      .forEach((param) => {
        const parts = [
          param.substr(0, param.indexOf("=")),
          param.substr(param.indexOf("=") + 1),
        ];
        parsedParams[parts[0]] = parts[1];
      });
    this.setOrder(parsedParams);
    this.getUpsellingProducts(parsedParams.oi);
  },
  computed: {
    needAddInfo() {
      return this.order.customer_id_card === "required";
    },
  },
  methods: {
    ...mapActions(["findOrder"]),
    async getUpsellingProducts(order_id) {
      const company_id = this.company.company_id;
      const res = await getUpsellingProducts(company_id, order_id);
      this.upsellingProducts = res["upselling_products"];
      this.productsLoading = false;
    },
    viewCart(newValue) {
      this.visualizeCart = newValue;
      this.openSummary = false;
    },
    async setOrder(params) {
      const orderParams = {
        trackingNumber: params.t,
        postalCode: params.zc,
        publicKey: this.$route.params.publicKey,
        orderId: params.oi,
        products: true,
      };
      try {
        const res = await this.findOrder(orderParams);
        this.order = res;
        localStorage.setItem("upsellingOrder", JSON.stringify(this.order));
        this.recommendedProducts = this.company.recommended_products;
      } catch (e) {
        this.error = e;
        console.error(e);
        if (e.error === 404 || e.error === 403 || e.error === 427) {
          toBase({ publicKey: this.$route.params.publicKey, error: e.message });
        }
      } finally {
        this.loading = false;
        this.setLoading(false);
      }
    },
    toTrackingRoute() {
      toTracking({
        publicKey: this.$route.params.publicKey,
        order_id: this.order.id,
        zip_code: this.order.shipping_address_zip,
        traking: this.order.shipping_number,
      });
    },
    goToShopLink() {
      window.open(this.company.advertising.urls.web, "_blank");
    },
    addProduct(product, productId) {
      if (Object.keys(this.selectedProducts).length == 0) {
        this.openSummary = true;
      }
      this.selectedProducts = {
        ...this.selectedProducts,
        [productId]: product,
      };
    },
    deleteProduct(productId) {
      const updatedProducts = { ...this.selectedProducts };
      delete updatedProducts[productId];
      if (Object.keys(updatedProducts).length == 0) {
        this.openSummary = false;
        this.visualizeCart = false;
      }
      this.selectedProducts = updatedProducts;
    },
    modifyProduct() {
      this.openSummary = true;
    },
    updateSummary() {
      this.openSummary = false;
      if (Object.keys(this.selectedProducts).length == 1) {
        this.visualizeCart = false;
        this.selectedProducts = {};
      }
    },
    deleteAll() {
      this.selectedProducts = {};
      this.openSummary = false;
      this.visualizeCart = false;
    },
    upsellingTimeOut() {
      this.timeOut = true;
    },
  },
};
</script>
<style lang="scss" scoped></style>
