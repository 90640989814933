import Tracking from "@/views/order/Tracking.vue";

import path from "@/router/const/path";
import name from "@/router/const/name";

export default [
  {
    path: path.TRACKING,
    name: name.TRACKING,
    component: Tracking,
  },
  {
    path: path.SHOP_TRACKING,
    name: name.SHOP_TRACKING,
    component: Tracking,
  },
];
