{
    "error": {
      "ORDER_NOT_FOUND": "Commande $order_id introuvable",
      "INVALID_VALIDATION_PARAM": "Paramètre de validation invalide pour la commande $order_name",
      "INCOMPLETE_BODY_PARAMS": "Paramètres de corps incomplets",
      "INCOMPLETE_COMPANY_PARAMS": "Paramètres de l'entreprise incomplets",
      "CUSTOMIZATION_NOT_FOUND": "Personnalisation introuvable",
      "MISSING_CONTACT_EMAIL": "L'entreprise $company_id n'a pas d'e-mail de contact",
      "MISSING_CUSTOMER_EMAIL": "La commande $order_id n'a pas d'e-mail client",
      "ORDER_COMPANY_NOT_FOUND": "Commande avec l'ID : $order_id et l'entreprise : $company_id introuvable",
      "MISSING_BODY_PARAMS": "Paramètres de corps manquants",
      "DUPLICATE_RETURN_ID": "Un retour avec cette ID : $order_id existe déjà",
      "RETURN_ORDER_NOT_FOUND": "Commande $order_id introuvable",
      "EMPTY_RETURN_PRODUCTS": "Ce retour ne contient aucun produit"
    },
    "validationError": {
      "required": "Obligatoire",
      "email": "Veuillez entrer une adresse e-mail valide.",
      "phone": "Veuillez entrer un numéro de téléphone valide.",
      "image": "Erreur de format (Accepté : .png, .jpg, .jpeg) et taille de fichier < 5MB.",
      "number": "L'entrée doit être un nombre",
      "cjk": "Caractères CJK non autorisés",
      "arabic": "Caractères arabes non autorisés",
      "comma": "Le caractère virgule n'est pas autorisé",
      "hyphen": "Le caractère tiret n'est pas autorisé"
    }
  }
  