<template>
  <v-card
    class="rounded-lg position-relative overflow-visible"
    :color="selectedItem ? 'neutral05' : ''"
    elevation="0"
    max-width="650px"
    :style="selectedItem ? 'border: 1px solid rgb(var(--v-theme-info))' : ''"
  >
    <NumberBadge
      v-if="selectedItem"
      class="mt-3 mr-1 mr-sm-n2 position-absolute"
      >1</NumberBadge
    >
    <v-row class="pa-4" align="center" no-gutters>
      <v-col cols="auto">
        <v-avatar
          :size="mobile ? 170 : 110"
          rounded="0"
          class="elevation-soft rounded-lg"
        >
          <v-img :src="item.img" cover />
        </v-avatar>
      </v-col>
      <v-col class="d-flex flex-column ml-2">
        <v-row no-gutters>
          <v-col cols="7">
            <p class="body-1 font-weight-medium text-no-wrap">
              {{ item.title }}
            </p>
            <p class="caption-1 pt-0">
              {{ item.variant_title }}
            </p>
            <p class="caption-1 pt-5 pt-lg-10">-{{ percentage }}%</p>
          </v-col>
          <v-col>
            <p :class="mobile ? '' : 'text-end'" style="font-size: 13px">
              <del class="text-neutral60">{{ item.price }}</del>
            </p>
            <p :class="mobile ? '' : 'text-end'" style="font-size: 19px">
              <span class="text-neutral00 bg-error">
                {{ item.discount_price }}
              </span>
            </p>

            <p :class="mobile ? '' : 'text-end'">
              <v-btn
                v-if="!selectedItem"
                class="btn-custom custom-button mt-4"
                @click="addProduct(item, item.id)"
              >
                <span>{{ $t("upselling.recommendedProducts.addToCart") }}</span>
                <v-icon>mdi-arrow-right-thin</v-icon>
              </v-btn>
              <v-btn
                v-else
                class="btn btn-tertiary-icon ma-0 pt-sm-5 pa-0 caption-1"
                variant="plain"
                @click="modifyProduct"
              >
                <span class="btn-text">{{
                  $t("upselling.recommendedProducts.modifyCart")
                }}</span>
                <v-icon>mdi-arrow-right-thin</v-icon>
              </v-btn>
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-divider color="neutral20" class="border-opacity-100"></v-divider>
  </v-card>
</template>
<script>
import NumberBadge from "@/components/blocks/NumberBadge.vue";
import { useDisplay } from "vuetify";

export default {
  name: "UpsellingProductDetails",
  components: {
    NumberBadge,
  },
  data: () => ({
    selectedProducts: {},
  }),
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    addProduct: {
      type: Function,
      required: true,
    },
    modifyProduct: {
      type: Function,
      required: true,
    },
    selectedItem: {
      type: [Boolean, Number],
      default: false,
    },
  },
  setup() {
    const { mobile } = useDisplay();
    return { mobile };
  },
  computed: {
    percentage() {
      return parseInt(
        (1 -
          +this.item.discount_price.split(" ")[0] /
            +this.item.price.split(" ")[0]) *
          100
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.position-absolute {
  right: 10px;
  top: -10px;
}

.v-ripple__container {
  top: 10px !important;
}

.btn-custom {
  color: rgb(var(--v-theme-neutral80));
  background: rgb(var(--v-theme-neutral00));
  border: 1px solid #000;
  border-radius: 8px;
  height: 35px !important;
  padding: 0px 10px !important;
  font-size: 14px;
  font-weight: 600;
  box-shadow: none !important;

  .btn-icon {
    stroke: rgb(var(--v-theme-neutral00));
  }

  &:hover {
    background: rgb(var(--v-theme-neutral00));
    color: rgb(var(--v-theme-neutral80));

    .btn-icon {
      stroke: rgb(var(--v-theme-neutral80));
    }
  }
}
</style>
