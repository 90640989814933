export const ERROR_CODES = {
  AFLORA_VALIDATION: {
    code: "EAFLORA001",
    message: "An error has ocurred during the validation of the company.",
  },
  AFLORA_DEFAULT_CONFIG: {
    code: "EAFLORA002",
    message:
      "The company/shop does not have a custom configuration. Use the default one.",
  },
  AFLORA_CUSTOMIZATION_NOT_FOUND: {
    code: "EAFLORA003",
    message: "Customization not found",
  },
};
