<template>
  <div>
    <p
      v-if="order.name"
      class="subtitle-3 text-neutral40 mb-10 text-center text-md-start"
    >
      {{ checkOrderName }}: <span>{{ order.name || order.id }}</span>
    </p>
    <div class="mb-5" v-if="statusComputed || needAddInfo">
      <h5 class="mb-5 text-center text-md-start">{{ statusComputed }}</h5>
      <v-btn
        type="button"
        class="btn btn-primary"
        width="300px"
        @click="toAddInfoRoute"
        v-if="needAddInfo"
      >
        {{ $t("tracking.need") }}
        <LensIcon />
      </v-btn>
    </div>
    <h6 class="mb-10 text-center text-lg-start" v-if="isFulfilled">
      {{
        isRefused
          ? $t("tracking.messages.refused")
          : $t("tracking.messages.ful")
      }}
    </h6>
  </div>
</template>
<script>
import { INTERNAL_STATUS } from "@/services/_const.service.js";
import { toAddInfo } from "@/router/navigate";
import LensIcon from "@/components/icons/LensIcon.vue";

export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
    needAddInfo: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    LensIcon,
  },
  computed: {
    statusComputed() {
      if (this.needAddInfo) {
        return this.$t("tracking.messages.info");
      }
      if (this.order.internal_status) {
        const info = this.getDayInfo();
        switch (this.order.internal_status) {
          case INTERNAL_STATUS.STATUS_PROCESSING:
          case INTERNAL_STATUS.STATUS_PROCESSING_A:
          case INTERNAL_STATUS.STATUS_PROCESSING_RESERVED_STOCK:
          case INTERNAL_STATUS.STATUS_PROCESSING_WAIT:
          case INTERNAL_STATUS.STATUS_NEW:
          case INTERNAL_STATUS.STATUS_NEW_COD:
          case INTERNAL_STATUS.STATUS_NEW_HOLD:
            return this.$t("tracking.messages.processing");
          case INTERNAL_STATUS.STATUS_SHIPPED:
          case INTERNAL_STATUS.STATUS_SHIPPED_PUDO:
            return this.$t("tracking.messages.shipped");
          case INTERNAL_STATUS.STATUS_NEW_UPSELLING:
            return `${info.dayName} ${info.dayNumber} ${info.monthName}`;
        }
      }
      return null;
    },
    isFulfilled() {
      return this.order.internal_status === "FULFILLED";
    },
    isRefused() {
      return (
        this.order.internal_status === "FULFILLED" && this.order.refused_order
      );
    },
    checkOrderName() {
      const hasNumber = /\d/.test(this.order.name);
      if (hasNumber) {
        return this.$t("options.order_number");
      } else {
        return this.$t("options.order_name");
      }
    },
  },
  methods: {
    getDayInfo() {
      const date = new Date();
      const dayName = this.$t(`upselling.days[${date.getDay()}]`);
      const dayNumber = date.getDate();
      const monthName = this.$t(`upselling.months[${date.getDay()}]`);

      return { dayName, dayNumber, monthName };
    },
    toAddInfoRoute() {
      toAddInfo({
        publicKey: this.$route.params.publicKey,
        order_id: this.order.id,
        zip_code: this.order.shipping_address_zip,
        tracking: this.order.shipping_number,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
