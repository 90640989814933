<template>
  <div class="container hidden-md-and-up mb-10" v-if="!exceptionOrder">
    <ul id="order-tracking" class="progress-bar">
      <li
        :class="position !== '25%' || position !== '50%' ? 'is-complete' : ''"
        id="accepted"
      ></li>
      <div :class="position == '25%' ? 'description' : 'hidden'">
        <p>{{ $t(`tracking.progress.processing`) }}</p>
      </div>

      <li
        :class="position !== '25%' && position !== '75%' ? 'is-complete' : ''"
        id="processing"
      ></li>
      <div :class="position == '50%' ? 'description' : 'hidden'">
        <p>{{ $t(`tracking.progress.shipped`) }}</p>
      </div>

      <li
        :class="position === '75%' || position === '100%' ? 'is-complete' : ''"
        id="delivery"
      ></li>
      <div :class="position == '75%' ? 'description' : 'hidden'">
        <p>{{ $t(`tracking.progress.delivery`) }}</p>
      </div>

      <li :class="position === '100%' ? 'is-complete' : ''" id="fulfilled"></li>
      <div :class="position == '100%' ? 'description' : 'hidden'">
        <p v-if="!isRefused">{{ $t("tracking.progress.delivered") }}</p>
        <p v-else class="text-red ">{{ $t("tracking.progress.refused") }}</p>
      </div>
    </ul>
  </div>
</template>

<script>
import { INTERNAL_STATUS } from "@/services/_const.service.js";

export default {
  name: "ProgressBarMobile",
  props: {
    order: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    position() {
      if (this.order.internal_status) {
        switch (this.order.internal_status) {
          case INTERNAL_STATUS.STATUS_PROCESSING:
          case INTERNAL_STATUS.STATUS_PROCESSING_A:
          case INTERNAL_STATUS.STATUS_PROCESSING_WAIT:
          case INTERNAL_STATUS.STATUS_PROCESSING_RESERVED_STOCK:
          case INTERNAL_STATUS.STATUS_NEW_UPSELLING:
            return "25%";
          case INTERNAL_STATUS.STATUS_SHIPPED:
          case INTERNAL_STATUS.STATUS_SHIPPED_PUDO:
            return "50%";
          case INTERNAL_STATUS.STATUS_FULFILLED:
          case INTERNAL_STATUS.STATUS_FULFILLED_LOST:
            return "100%";
          case INTERNAL_STATUS.STATUS_NEW:
          case INTERNAL_STATUS.STATUS_NEW_COD:
          case INTERNAL_STATUS.STATUS_NEW_HOLD:
            return "0%";
        }
      }
      return "50%";
    },
    exceptionOrder() {
      if (this.order.internal_status) {
        return [INTERNAL_STATUS.STATUS_EXCEPTION].includes(
          this.order.internal_status
        );
      }
      return false;
    },
    isRefused() {
      return (
        this.order.internal_status === "FULFILLED" && this.order.refused_order
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;

  li {
    list-style: none;
    margin-left: 47%;
    width: 10px;
    background-color: rgb(var(--v-theme-successLight));
    height: 100px;
    border-radius: 10px;
  }

  li.is-complete {
    width: 12px;
    border-radius: 10px;

    background-image: linear-gradient(
      to bottom,
      rgb(var(--v-theme-success)) 50%,
      transparent 50%
    );
    background-size: 100% 200%;
    background-position: bottom;
  }

  #accepted {
    animation: fillBar 0.4s forwards;
    animation-delay: 0.3s;
  }

  #processing {
    animation: fillBar 0.3s forwards;
    animation-delay: 0.5s;
  }

  #delivery {
    animation: fillBar 0.3s forwards;
    animation-delay: 0.7s;
  }

  #fulfilled {
    animation: fillBar 0.3s forwards;
    animation-delay: 0.9s;
  }

  @keyframes fillBar {
    100% {
      background-position: top;
    }
  }
  .progress-bar li:before {
    content: "";
    display: block;
    width: 25px;
    height: 25px;
    background-color: rgb(var(--v-theme-successLight));
    border-radius: 50%;
    position: absolute;
    border: 1px solid rgb(var(--v-theme-successLight));
    margin: 85px 0 0 -6px;
    background-position: center;
    background-repeat: no-repeat;
  }

  #accepted::before {
    background-image: url("@/assets/svg/package.svg");
  }

  #processing::before,
  #delivery::before {
    background-image: url("@/assets/svg/truck.svg");
  }

  #fulfilled::before {
    background-image: url("@/assets/svg/home.svg");
  }
  .progress-bar li.is-complete:before {
    background-color: rgb(var(--v-theme-success));
    border: 1px solid rgb(var(--v-theme-success));
  }

  .description {
    padding: 20px 0 10px 0;

    p {
      color: rgb(var(--v-theme-success));
      text-align: center;
      font-weight: 300;
    }
  }

  .hidden {
    display: none;
  }
}
</style>
