<template>
  <v-row class="pa-0 ma-0">
    <!-- Hours -->
    <v-col cols="auto" class="pa-0">
      <v-card :color="timeColor" flat tile>
        <v-card-text class="white--text display-1 py-3 px-2 text-h5">
          {{ formattedHours[0] }}
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="auto" class="pa-0 pl-1">
      <v-card :color="timeColor" flat tile>
        <v-card-text class="white--text display-1 py-3 px-2 text-h5">
          <span class="text-size-14">
            {{ formattedHours[1] }}
          </span>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      cols="auto"
      class="pa-0 px-1 d-flex align-center justify-center display-1 white--text"
    >
      <span class="font-weight-bold text-h4">:</span>
    </v-col>
    <!-- Minutes -->
    <v-col cols="auto" class="pa-0">
      <v-card :color="timeColor" flat tile>
        <v-card-text class="white--text display-1 py-3 px-2 text-h5">
          {{ formattedMinutes[0] }}
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="auto" class="pa-0 pl-1">
      <v-card :color="timeColor" flat tile>
        <v-card-text class="white--text display-1 py-3 px-2 text-h5">
          {{ formattedMinutes[1] }}
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      cols="auto"
      class="pa-0 px-1 d-flex align-center justify-center display-1 white--text"
    >
      <span class="font-weight-bold text-h4">:</span>
    </v-col>
    <!-- Seconds -->
    <v-col cols="auto" class="pa-0">
      <v-card :color="timeColor" flat tile>
        <v-card-text class="white--text display-1 py-3 px-2 text-h5">
          {{ formattedSeconds[0] }}
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="auto" class="pa-0 pl-1">
      <v-card :color="timeColor" flat tile>
        <v-card-text class="white--text display-1 py-3 px-2 text-h5">
          {{ formattedSeconds[1] }}
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "CountDown",
  props: {
    initialSeconds: {
      type: Number,
      default: 3600,
    },
  },
  data() {
    return {
      totalSeconds: this.initialSeconds,
      timeColor: this.initialSeconds > 3600 ? "neutral80" : 'error',
    };
  },
  computed: {
    formattedHours() {
      const hours = Math.floor(this.totalSeconds / 3600);
      return hours < 10 ? "0" + hours : hours.toString();
    },
    formattedMinutes() {
      const minutes = Math.floor((this.totalSeconds % 3600) / 60);
      return minutes < 10 ? "0" + minutes : minutes.toString();
    },
    formattedSeconds() {
      const seconds = this.totalSeconds % 60;
      return seconds < 10 ? "0" + seconds : seconds.toString();
    },

  },
  mounted() {
    this.startCountdown();
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  methods: {
    startCountdown() {
      this.interval = setInterval(() => {
        if (this.totalSeconds > 0) {
          this.totalSeconds--;
        } else {
          clearInterval(this.interval);
          this.$emit("countdown-finished");
        }
      }, 1000);
    },
  },
  watch: {
    formattedHours(newVal) {
      this.timeColor = newVal <= 3600 ? "error" : "neutral80";
    },

  },
};
</script>

<style lang="scss" scoped></style>
