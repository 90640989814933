<template>
  <div>
    <h3 class="pb-4">{{ $t("checkout.paymentDetails") }}</h3>
    <p class="subtitle-3 pb-10">
      {{ $t("checkout.paymentDetailsText") }}
    </p>
    <v-row class="pb-4">
      <v-col cols="12" md="6">
        <v-row class="pl-3 align-baseline">
          <v-icon size="18">mdi-account-outline</v-icon>
          <p class="pl-1 pb-4">{{ $t("checkout.customerName") }}</p>
        </v-row>
        <InputFieldInnerIcon
          hideDetails
          :value="order.shipping_address_name.split(' ')[0]"
          disabled
          :innerIcon="'mdi-check'"
          iconColor="rgb(var(--v-theme-success))"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-row class="pl-3 align-baseline">
          <v-icon size="18">mdi-account-outline</v-icon>
          <p class="pl-1 pb-4">{{ $t("checkout.customerSurnames") }}</p>
        </v-row>
        <InputFieldInnerIcon
          hideDetails
          :value="formattedName"
          disabled
          innerIcon="mdi-check"
          iconColor="rgb(var(--v-theme-success))"
        />
      </v-col>
    </v-row>
    <v-row class="pl-3 align-baseline">
      <v-icon size="18">mdi-home-city-outline</v-icon>
      <p class="pl-1 pb-4">{{ $t("checkout.customerAdress") }}</p>
    </v-row>
    <InputFieldInnerIcon
      hideDetails
      :value="order.shipping_address"
      disabled
      innerIcon="mdi-check"
      iconColor="rgb(var(--v-theme-success))"
    />
    <v-row class="pt-10">
      <v-col cols="12" md="6">
        <v-row class="pl-3 align-baseline">
          <v-icon size="18">mdi-email-outline</v-icon>
          <p class="pl-1 pb-4">{{ $t("checkout.zipCode") }}</p>
        </v-row>
        <InputFieldInnerIcon
          hideDetails
          :value="order.shipping_address_zip"
          disabled
          innerIcon="mdi-check"
          iconColor="rgb(var(--v-theme-success))"
        />
      </v-col>
      <v-col>
        <v-row class="pl-3 align-baseline">
          <v-icon size="18">mdi-city-variant-outline</v-icon>
          <p class="pl-1 pb-4">{{ $t("checkout.city") }}</p>
        </v-row>
        <InputFieldInnerIcon
          hideDetails
          :value="order.shipping_address_city"
          disabled
          innerIcon="mdi-check"
          iconColor="rgb(var(--v-theme-success))"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import InputFieldInnerIcon from "@/components/inputs/InputFieldInnerIcon.vue";
export default {
  name: "PaymentDetails",
  components: {
    InputFieldInnerIcon,
  },
  props: {
    order: {
      type: Object,
      default: () => undefined,
    },
  },
  computed: {
    formattedName() {
      const nameParts = this.order.shipping_address_name.split(" ");
      const part1 = nameParts[1] || "";
      const part2 = nameParts[2] || "";
      return part1 + (part2 ? " " + part2 : "");
    },
  },
};
</script>

<style lang="scss" scoped></style>
