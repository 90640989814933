<template>
  <div class="d-flex flex-column w-100 h-screen align-center justify-center">
    <h1>404</h1>
    <h2 class="text-uppercase">page not found</h2>
    <v-btn
      append-icon="mdi-redo-variant"
      class="btn btn-secondary ma-5"
      href="javascript:javascript:history.go(-1)"
      >Return</v-btn
    >
  </div>
</template>

<script>
export default {
  name: "FourZeroFour",
};
</script>
<style lang="scss" scoped></style>
