export function replaceErrorMessage(error, $t) {
  try {
    if (error.error == 427) {
      const { internal_code, values } = error.message;
      const replacedMessage = $t(`error.${internal_code}`).replace(
        /\$([a-zA-Z_]+)/g,
        (match, key) => {
          const value = values && values[key];
          return value || "";
        }
      );
      return replacedMessage;
    }
  } catch {
    return JSON.stringify(error?.message);
  }
}
