<template>
  <div class="d-flex align-start">
    <v-icon color="neutral60">mdi-earth</v-icon>
    <v-select
      v-model="$i18n.locale"
      class="pl-3 py-0"
      append-inner-icon="mdi-chevron-down"
      :items="possibleLanguages()"
      item-title="text"
      item-value="value"
      variant="plain"
      hide-details
      @update:modelValue="changeLanguage"
    ></v-select>
  </div>
</template>

<script>
import { setStorageLang, getStorageLang } from "@/services/lang.service";
export default {
  name: "Lang",
  props: {
    customTextsObject: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      select: { text: "English", value: "en" },
      languages: [
        { text: "Deutsche", value: "de" },
        { text: "English", value: "en" },
        { text: "Español", value: "es" },
        { text: "Français", value: "fr" },
        { text: "Italiano", value: "it" },
        { text: "Português", value: "pt" },
      ],
    };
  },
  methods: {
    possibleLanguages() {
      if (this.customTextsObject.available_languages) {
        return this.languages.filter((l) =>
          this.customTextsObject.available_languages.includes(l.value)
        );
      } else {
        return this.languages;
      }
    },
    changeLanguage() {
      setStorageLang(this.$i18n.locale);
    },
  },
  mounted() {
    const storedLang = getStorageLang();
    if (storedLang && this.customTextsObject.available_languages) {
      if (!this.customTextsObject.available_languages.includes(storedLang)) {
        const defaultLang = this.customTextsObject.available_languages[0];
        setStorageLang(defaultLang);
        this.$i18n.locale = defaultLang;
      } else {
        this.$i18n.locale = storedLang;
      }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
:deep .v-field__append-inner {
  padding: 0;
}
:deep .v-field__input {
  padding: 0;
  padding-top: 3px;
}
</style>
