<template>
  <v-container class="ma-0 pa-0" fluid>
    <v-row no-gutters>
      <v-col class="px-11" :cols="12" :sm="12" :md="12" :lg="6" :xl="6">
        <logo :company="company" :webUrl="webUrl" />

        <v-row no-gutters>
          <v-col class="d-flex align-baseline" :cols="12" :xs="12">
            <h1 v-if="!mobile" class="py-8 py-sm-4 py-md-6">
              {{ $t("checkout.checkout") }}
            </h1>
            <h2 v-else class="py-8 py-sm-4 py-md-6">
              {{ $t("checkout.checkout") }}
            </h2>
            <v-divider color="neutral80" class="border-opacity-100"></v-divider>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col
        class="d-flex flex-column px-11 order-last order-lg-first"
        :cols="12"
        :sm="12"
        :md="12"
        :lg="6"
        :xl="6"
      >
        <slot />
        <div v-if="payment" class="flex-grow-1"></div>
        <CompanyInfo
          v-if="company.advertising"
          class="mx-n11 hidden-sm-and-down"
          :promotions="promotions"
          :webUrl="webUrl"
        />
        <Footer
          class="hidden--and-down"
          :customTextsObject="customTextsObject"
        />
      </v-col>
      <v-col
        class="--bg-neutral00 pb-11 order-first order-lg-last"
        :lg="6"
        :xl="6"
      >
        <CheckoutItemList
          :style="
            mobile ? 'position: sticky; bottom: 0' : 'position: sticky; top: 0'
          "
          :selected-products="selectedProducts"
          :order="order"
        ></CheckoutItemList>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { imageUrl } from "@/assets/utils/s3Urls.js"
import Logo from "@/components/shared/Logo.vue";
import Footer from "@/components/blocks/Footer.vue";
import CompanyInfo from "@/components/blocks/CompanyInfo.vue";
import CheckoutItemList from "@/components/blocks/CheckoutItemList.vue";
import { useDisplay } from "vuetify";

export default {
  name: "CheckoutWrapper",
  data() {
    return {
      imageUrl,
      webUrl: null,
    };
  },
  components: {
    Logo,
    Footer,
    CompanyInfo,
    CheckoutItemList,
  },
  props: {
    customTextsObject: {
      type: Object,
      default: () => {},
    },
    company: {
      type: Object,
      default: () => {},
    },
    selectedProducts: {
      type: Object,
      default: () => {},
    },
    order: {
      type: Object,
      default: () => {},
    },
    payment: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.checkAdvertising();
    this.deleteBanner();
  },
  watch: {
    company: {
      handler: "checkAdvertising",
      deep: true,
    },
  },
  setup() {
    const { mobile } = useDisplay();
    return { mobile };
  },
  computed: {},
  methods: {
    checkAdvertising() {
      if (this.company.advertising) {
        this.promotions = this.company.advertising;
        this.webUrl = this.company.advertising.urls.web;
      }
    },
    deleteBanner() {
      if (this.promotions.banner) {
        delete this.promotions.banner;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
