<template>
  <v-card class="mx-auto" elevation="0" width="auto">
    <h5 class="mx-auto my-4 subtitle-3">
      {{ $t("upselling.recommendedProducts.title") }}
    </h5>
    <v-row>
      <v-col cols="9">
        <p v-if="!upsellingFinished" class="mx-auto subtitle-3 text-neutral80">
          {{ $t("upselling.recommendedProducts.subtitleUpselling") }}
        </p>
        <p v-else class="mx-auto subtitle-3 text-neutral80">
          {{ $t("upselling.recommendedProducts.subtitleUpsellingEnd") }}
        </p>
      </v-col>
    </v-row>
    <v-slide-group v-model="model" class="pa-0 ma-0 pt-8" center-active>
      <v-slide-group-item
        v-for="[productId, product] in Object.entries(recommendedProducts)"
        :key="productId"
      >
        <RecommendedProductDetails
          :item="product"
          :selectedItem="productId in modelValue"
          ><template v-if="!upsellingFinished" v-slot:addButton>
            <v-btn
              v-if="!(productId in modelValue)"
              class="btn btn-tertiary-icon ma-0 pa-0 caption-1 text-neutral80"
              variant="plain"
              @click="addProduct(product, productId)"
            >
              <span class="btn-text">{{
                $t("upselling.recommendedProducts.addToCart")
              }}</span>
              <v-icon>mdi-arrow-right-thin</v-icon>
            </v-btn>
            <v-btn
              v-else
              class="btn btn-tertiary-icon ma-0 pa-0 pa-0 caption-1"
              variant="plain"
              @click="modifyProduct"
            >
              <span class="btn-text">{{
                $t("upselling.recommendedProducts.modifyCart")
              }}</span>
              <v-icon>mdi-arrow-right-thin</v-icon>
            </v-btn>
          </template>
          <template v-else v-slot:addButton>
            <v-btn
              v-if="!(productId in modelValue)"
              class="btn btn-tertiary-icon ma-0 pa-0 caption-1 text-neutral80"
              variant="plain"
              @click="goToExternalLink"
            >
              <span class="btn-text">{{
                $t("upselling.recommendedProducts.goToShop")
              }}</span>
              <v-icon>mdi-arrow-right-thin</v-icon>
            </v-btn>
          </template>
        </RecommendedProductDetails>
      </v-slide-group-item>
    </v-slide-group>
  </v-card>
</template>

<script>
import RecommendedProductDetails from "@/components/blocks/RecommendedProductDetails.vue";

export default {
  components: {
    RecommendedProductDetails,
  },
  data: () => ({
    model: null,
  }),
  props: {
    order: {
      type: Object,
      default: () => {},
    },
    recommendedProducts: {
      type: Object,
      default: () => {},
    },
    modelValue: {
      type: Object,
      required: true,
    },
    upsellingFinished: {
      type: Boolean,
      defualt: false,
    },
    goToExternalLink: {
      type: Function,
    },
    addProduct: {
      type: Function,
      required: true,
    },
    modifyProduct: {
      type: Function,
      required: true,
    },
  },
  methods: {
    isSelected(productId) {
      const exists = productId in this.modelValue;
      console.log(`Checking product ${productId}. Exists: ${exists}`);
      return exists;
    },
  },
  computed: {
    shop() {
      return this.order.shop ? this.order.shop : "https://www.thehillspress.es";
    },
  },
};
</script>
