<template>
  <PageNotFound v-if="fourZeroFour" :setLoading="setLoading" />
  <template v-else>
    <LoadingComponent v-if="loading" :company="company" />
    <template v-else>
      <LoadingComponent v-if="componentLoading" :company="company" />
      <router-view
        :rules="rules"
        :company="company"
        :setLoading="setLoading"
        :customTextsObject="customTextsObject"
        :prefixName="prefixName"
      />
    </template>
  </template>
</template>

<script>
import { PUBLIC_KEY_PARAM } from "@/router/const/path";
import PageNotFound from "@/views/helpers/PageNotFound";
import LoadingComponent from "@/components/shared/LoadingComponent";
import { getStorageLang } from "@/services/lang.service";
import { mapActions } from "vuex";

const AMPHORA_COLORS = "#E3B307";

const AMPHORA_RULES = {
  showTracking: true,
  canChange: false,
  canReturn: false,
};

export default {
  name: "App",
  components: {
    PageNotFound,
    LoadingComponent,
  },
  data() {
    return {
      loading: true,
      componentLoading: true,
      fourZeroFour: false,
      company: undefined,
      rules: AMPHORA_RULES,
      customTextsObject: {},
      prefixName: "",
      customerEmail: "",
    };
  },
  methods: {
    ...mapActions(["findCompanyCustomization"]),
    setBodyColors(color) {
      document.body.style.setProperty("--amph-primary", color);
      document.body.style.setProperty("--amph-light", color + "1A");
    },
    getPrimaryColor(res) {
      if (res.colors && res.colors.length > 0) {
        return res.colors[0];
      }
      if (res.primary_color) {
        return res.primary_color;
      }
    },
    setPageNotFound() {
      this.setBodyColors(AMPHORA_COLORS);
      this.rules = AMPHORA_RULES;
      this.fourZeroFour = true;
    },
    setLoading(bool) {
      this.componentLoading = bool;
    },
    async setCompany() {
      this.$router.afterEach(async (route) => {
        const { params } = route;
        this.loading = true;
        try {
          const res = await this.findCompanyCustomization(
            params[PUBLIC_KEY_PARAM]
          );
          this.company = res;
          this.rules = res.rules;
          this.prefixName = res.prefix_name ? res.prefix_name : "";
          
          //LEGACY
          this.customTextsObject = res.text_costum ? res.text_costum : {};
          const primaryColor = this.getPrimaryColor(res);
          this.setBodyColors(primaryColor);

        } catch (e) {
          this.setPageNotFound();
        } finally {
          this.loading = false;
        }
      });
    },
  },
  computed: {
    companyCustomization() {
      return this.$store.getters.companyCustomization;
    },
  },
  watch: {
    companyCustomization() {
      this.company = this.companyCustomization;
      this.setBodyColors(this.companyCustomization.colors);
      this.rules = this.companyCustomization.rules;
      this.customTextsObject = this.companyCustomization.text_costum
        ? this.companyCustomization.text_costum
        : {};
      this.operationsEmail = this.companyCustomization.operations_email
        ? this.companyCustomization.operations_email
        : "";
      this.prefixName = this.companyCustomization.prefix_name
        ? this.companyCustomization.prefixName
        : "";
    },
  },
  mounted() {
    this.setCompany();
    this.$i18n.locale = getStorageLang() || this.$i18n.locale || "en";
  },
};
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
