export default {
// Amphora route names
  BASE: "base",
  OPTIONS: "options",
  TRACKING: "tracking",
  RETURNS: "returns",
  CHANGES: "changes",
  DATA: "data",
  UPSELLING: "upselling",
  CHECKOUT: "checkout",
  GO_DESIGN_VIEW: "GoDesignView",
  MOCK_TRACKING_PAGE: "MockTrackingPage",
  // Company and shop route names
  SHOP_BASE: "shopBase",
  SHOP_OPTIONS: "shopOptions",
  SHOP_TRACKING: "shopTracking",
  SHOP_RETURNS: "shopReturns",
  SHOP_RETURN_INFO: "shopReturnInfo",
  SHOP_CHANGES: "shopChanges",
  SHOP_DATA: "shopData",
  SHOP_UPSELLING: "shopUpselling",
  SHOP_CHECKOUT: "shopCheckout",
};
