import { $fetchJson, GET_UPSELLING_PRODUCTS } from "@/services/_index.service";

export const getUpsellingProducts = async (companyId, orderId) => {
  return $fetchJson({
    method: "POST",
    body: {
      company_id: companyId,
      order_id: orderId,
    },
    url: GET_UPSELLING_PRODUCTS,
  });
};
