import GoDesignView from "@/views/helpers/GoDesignView.vue";

import path from "@/router/const/path";
import name from "@/router/const/name";

export default [
  {
    path: path.GO_DESIGN_VIEW,
    name: name.GO_DESIGN_VIEW,
    component: GoDesignView,
  },
];
