{
  "chat": {
    "title": "Kontaktieren Sie uns",
    "email": "E-Mail-Adresse",
    "email_placeholder": "z. B. beispiel{'@'}gmail.com",
    "tracking": "Sendungsnummer",
    "tracking_placeholder": "z. B. #01921883712873",
    "subject": "Wählen Sie den Grund für Ihre Anfrage",
    "placeholder": "Geben Sie Ihre Nachricht ein",
    "inform": "Hallo! Vielen Dank, dass Sie uns über diesen Chat kontaktiert haben. Wir können Ihre Anfrage nicht sofort über diesen Kanal bearbeiten, aber wir werden uns so schnell wie möglich per E-Mail bei Ihnen melden. Vielen Dank!",
    "send": "Senden",
    "options": {
      "select": "Wählen Sie eine Option aus",
      "op1": "Eine Anfrage zum Versand stellen",
      "op2": "Einen Vorfall melden",
      "op3": "Anfrage zur Rückgabepolitik",
      "op4": "Anfrage zum Rückerstattungsprozess",
      "op5": "Bestellung stornieren",
      "op6": "Meine Bestellung ändern"
    }
  },
  "chAndRe": {
    "tellUs": "Sagen Sie uns, warum Sie die Bestellung zurückgeben möchten",
    "writeHere": "Hier schreiben",
    "fulfillmentDays": "Die Rückgabefrist (DAYS_TO Tage) für diese Bestellung ist abgelaufen.",
    "expired": "Die Rückgabefrist ist abgelaufen",
    "change": "Ändern",
    "errorEmail": "Bitte geben Sie Ihre E-Mail-Adresse ein",
    "invalidEmail": "Falsche E-Mail-Adresse",
    "unit": "Menge",
    "to_tracking": "Zurück zur Sendungsverfolgung"
  },
  "app": {
    "power": "Bereitgestellt von"
  },
  "add": {
    "submit": "Senden",
    "id_card": "Fügen Sie Ihren Personalausweis hinzu",
    "success": "Erfolgreich!",
    "message": "Ihre Bestellung ist zur Bearbeitung bereit.",
    "invalidDNI": "Ungültige Personalausweisnummer",
    "required": "Erforderliches Feld",
    "info": "Die Identifikationsnummer oder der Personalausweis wird normalerweise auf dem Dokument selbst gedruckt und besteht aus acht Ziffern, gefolgt von einem Buchstaben, der als Kontrollziffer fungiert.",
    "country": {
      "label": "Versandland",
      "placeholder": "Auswählen"
    },
    "zipCode": {
      "label": "Postleitzahl",
      "placeholder": "Geben Sie die Postleitzahl ein"
    },
    "city": {
      "label": "Stadt",
      "placeholder": "Geben Sie die Stadt ein"
    },
    "address": {
      "label": "Adresse",
      "placeholder": "Geben Sie die Adresse ein"
    },
    "address2": {
      "label": "Zusätzliche Adressinformationen",
      "placeholder": "Geben Sie die Adresse ein"
    },
    "name": {
      "label": "Name",
      "placeholder": "Geben Sie den Namen ein"
    },
    "phone": {
      "label": "Telefon",
      "placeholder": "Geben Sie die Telefonnummer ein"
    },
    "searchCountry": "Land suchen"
  },
  "dialog": {
    "title": "Bitte geben Sie Ihre E-Mail-Adresse ein, damit wir Ihre Anfrage bearbeiten können"
  },
  "info": {
    "form": {
      "tracking": "Bestellnummer oder Sendungsverfolgung",
      "tracking_ej": "z. B. #02692F0405",
      "required_tracking": "Die Bestellnummer ist erforderlich.",
      "postal": "Postleitzahl",
      "postal_ej": "z. B. 08006",
      "required_postal": "Die Postleitzahl ist erforderlich.",
      "search": "Suchen",
      "tooltip_order": "Finden Sie Ihre Sendungsverfolgungsnummer in der Bestellbestätigungs-E-Mail, die Sie erhalten haben. Sie finden sie auch auf dem Lieferschein im Paket.",
      "tooltip_code": "Es handelt sich um einen numerischen Code, der einen Standort bestimmt. Sie können ihn leicht telefonisch bei der Post erfragen oder im Internet suchen.",
      "error": "Bestellung nicht gefunden"
    },
    "title": {
      "need": "Finden Sie Ihre Bestellung",
      "tracking": "Sendungsverfolgungsnummer",
      "your_order": "Ihre Bestellung",
      "your_products": "Ihre Produkte"
    }
  },
  "options": {
    "tracking": "Sendungsverfolgung: ",
    "order_number": "Bestellnummer",
    "order_name": "Bestellname",
    "id": "Bestell-ID",
    "return": "Möchten Sie eine Rücksendung vornehmen?",
    "change": "Möchten Sie eine Umtauschaktion durchführen?",
    "my_return": "Meine Rücksendung anzeigen",
    "my_change": "Meinen Umtausch anzeigen",
    "return_progress": "Sie haben eine laufende Rücksendung",
    "change_progress": "Sie haben eine laufende Umtauschaktion",
    "return_or_change": "Möchten Sie einen Umtausch oder eine Rücksendung vornehmen?",
    "make_return": "Rücksendung vornehmen",
    "make_change": "Umtauschaktion durchführen",
    "courier": {
      "name": "Ihre Bestellung wird bearbeitet von",
      "contact": "vertrauenswürdiger Trägerdienst",
      "details": "Kurierdetails"
    },
    "noData": "Keine Optionen verfügbar"
  },
  "steps": {
    "return": {
      "step1": {
        "step": "Schritt 1:",
        "desc": "Wählen Sie das Produkt aus, das Sie zurücksenden möchten"
      },
      "step2": {
        "step": "Schritt 2:",
        "desc": "Bestätige die Rückgabe",
        "desc2": "Bestätige die Rückgabe oder wähle mehr Produkte aus"
      },
      "step3": {
        "desc": "Wir haben die Rücksendeanfrage gesendet. Sie erhalten in Kürze eine Antwort per E-Mail."
      }
    },
    "change": {
      "step1": {
        "step": "Schritt 1:",
        "desc": "Wählen Sie das Produkt aus, das Sie umtauschen möchten"
      },
      "step2": {
        "step": "Schritt 2:",
        "desc": "Bestätigen Sie die Änderung",
        "desc2": "Bestätigen Sie die Änderung oder wählen Sie weitere Produkte aus"
      },
      "step3": {
        "desc": "Wir haben die Umtauschanfrage gesendet. Sie erhalten in Kürze eine Antwort per E-Mail."
      }
    }
  },
  "instructions": {
    "title": "Anweisungen für die Rücksendung",
    "title_steps": "Wie sende ich ein Produkt zurück?",
    "subtitle_return": "Rücksendung",
    "subtitle_change": "Umtausch",
    "item_return": "Ihre Artikel zur Rücksendung",
    "item_change": "Ihre Artikel zum Umtausch",
    "prepare": "Vorbereiten",
    "send": "Bestellung zurückschicken",
    "refund": "Rückerstattung",
    "information": {
      "desc_url_1": "Um die Rücksendung durchzuführen, befolgen Sie die Anweisungen auf dem Etikett. Beachten Sie, dass Sie, wenn Sie die Rücksendung aus einem Land außerhalb der Europäischen Union vornehmen, die Rechnung drucken und in das Paket legen müssen.",
      "desc_url_2": "Um einen Artikel zurückzugeben, kleben Sie einfach dieses Etikett auf das Paket, und wir werden die Abholung planen.",
      "desc_url_3": "Um die Rücksendung abzuschließen, klicken Sie auf die Schaltfläche 'Weitere Informationen' und folgen Sie den Anweisungen.",
      "desc_cex": "Um die Rücksendung abzuschließen, geben Sie das Paket in einem Postamt ab und erwähnen Sie, dass es sich um eine 'Logística Inversa de Correos express' (Correos express Rücksendelogistik) Sendung handelt, damit sie ein Rücksendeetikett generieren können. Stellen Sie sicher, dass die Informationen auf dem Etikett genau mit Ihrer Auftragsreferenz '{ref}') übereinstimmen, einschließlich Zahlen und Buchstaben."
    },
    "qr": {
      "step1": "Laden Sie 2 Kopien des QR-Codes herunter und drucken Sie sie aus. Kleben Sie eine Kopie außerhalb des Pakets und legen Sie die andere im Inneren bei. So können wir Ihre Rücksendung identifizieren und mit der Rückerstattung fortfahren.",
      "important": "WICHTIG: Der QR-Code ist kein Versandetikett; es handelt sich um einen internen Identifikationscode für unser Lager, um das Produkt zu identifizieren. Sie müssen auch das Versandetikett, das Ihnen das von Ihnen gewählte Transportunternehmen gibt, an Ihr Paket anbringen.",
      "step2": "Bringen Sie das Paket zum für Sie bequemsten Transportunternehmen und senden Sie es an die im E-Mail angegebene Adresse.",
      "step3": "Erhalten Sie Ihre Rückerstattung, sobald wir das Rücksendepaket erhalten haben."
    },
    "defaultInstructions": {
      "step1": "Verpacken Sie die Artikel für die Rücksendung sorgfältig.",
      "step2": "Befolgen Sie die bereitgestellten Anweisungen, um mit der Rücksendung fortzufahren.",
      "step3": "Erhalten Sie Ihre Rückerstattung, sobald wir das Rücksendepaket erhalten haben."
    },
    "return": {
      "title": "Sie können Ihre Rücksendung jetzt abschicken",
      "desc": "Um die Rücksendung durchzuführen, befolgen Sie bitte die Anweisungen, die wir an Ihre E-Mail-Adresse gesendet haben.",
      "print_label": "Rücksendeetikett drucken",
      "more_info": "Weitere Informationen",
      "qr_return": "Den QR-Code herunterladen",
      "identifier": "Bezeichner zurückgeben:"
    },
    "received": {
      "title": "Wir haben Ihre Rücksendung erhalten",
      "desc": "Ihre Rücksendung wurde erfolgreich empfangen. Für weitere Informationen kontaktieren Sie uns bitte."
    }
  },
  "product": {
    "return": {
      "title": "Möchten Sie diesen Artikel zurücksenden?",
      "label": "Warum möchten Sie ihn zurücksenden?",
      "qty": "Wie viele möchten Sie zurücksenden?",
      "reason": "Geben Sie hier den Grund für die Rücksendung ein",
      "required": "Bitte geben Sie einen Grund für die Rücksendung an.",
      "date": "Rücksendung möglich bis",
      "return": "Rücksendung",
      "item": "Artikel",
      "items": "Artikel",
      "uploadImageDamage": "Füge das Bild des beschädigten Artikels hinzu",
      "uploadImageWrong": "Füge das Bild des falschen Artikels hinzu",
      "uploadImageDamagePlus": "Füge die Bilder der beschädigten Artikel hinzu",
      "uploadImageWrongPlus": "Füge die Bilder der falschen Artikel hinzu",
      "maxUpload": "Du kannst maximal 1 Bild pro Produkt anhängen",
      "addImage": "Hinzufügen",
      "notReturnable": "Dieses Produkt ist nicht für Rücksendungen oder Umtäusche berechtigt.",
      "allProductsNoReturnable": "Rückgaben oder Umtäusche sind für keine der Produkte möglich"
    },
    "change": {
      "title": "Möchten Sie diesen Artikel umtauschen?",
      "label": "Warum möchten Sie ihn umtauschen?",
      "product": "Umtauschoptionen",
      "reason": "Geben Sie hier den Grund für den Umtausch ein",
      "required": "Bitte geben Sie einen Grund für den Umtausch an.",
      "date": "Umtausch möglich bis",
      "noStock": "Kein Lagerbestand vorhanden"
    },
    "confirm": "Bestätigen",
    "cancel": "Abbrechen",
    "options": {
      "select": "Wählen Sie eine Option",
      "DAMAGED": "Es ist beschädigt angekommen",
      "TOO_BIG": "Zu groß",
      "TOO_SMALL": "Zu klein",
      "NOT_EXPECTED": "Es ist nicht, was ich erwartet habe",
      "NOT_ORDERED": "Es ist nicht das, was ich bestellt habe",
      "OTHER": "Anderes",
      "eg": "z.B.: es erfüllt meine Erwartungen nicht"
    }
  },
  "tracking": {
    "need": "Information hinzufügen",
    "resume": {
      "order": {
        "title": "Versandadresse "
      }
    },
    "messages": {
      "processing": "Status: in Vorbereitung",
      "shipped": "Status: versendet",
      "out": "Status: im Versand",
      "ful": "Status: zugestellt",
      "refused": "Status: abgelehnt",
      "info": "Um diese Bestellung zu versenden, benötigen wir zusätzliche Informationen.",
      "wrongAddress": "Ups, es sieht so aus, als ob in einigen Feldern ein Fehler vorliegt.",
      "verifyField": "Bitte überprüfen Sie, ob alle Angaben korrekt sind."
    },
    "progress": {
      "processing": "Wir bereiten Ihre Bestellung so schnell wie möglich vor",
      "shipped": "Ihre Bestellung hat unser Lager verlassen",
      "delivery": "Fast fertig, Ihre Bestellung wird ausgeliefert",
      "delivered": "Wir haben Ihre Bestellung zugestellt",
      "refused": "Wir haben versucht, Ihr Paket zuzustellen, aber es wurde abgelehnt. Bitte kontaktieren Sie uns für weitere Informationen."
    }
  },
  "footer": {
    "contact": "Kontakt",
    "privacy": "Datenschutz",
    "terms": "Nutzungsbedingungen"
  },
  "checkout": {
    "checkout": "Checkout",
    "paymentDetails": "Zahlungsdetails",
    "paymentDetailsText": "Du bist nur einen Schritt davon entfernt, deinen Kauf abzuschließen! Bitte überprüfe die Details deiner Bestellung und fahre mit der sicheren Bezahlung fort.",
    "customerName": "Vorname",
    "customerSurnames": "Nachname",
    "customerAdress": "Wohnadresse",
    "zipCode": "Postleitzahl",
    "city": "Stadt",
    "orderHeader": "Ihre Bestellung",
    "orderText": "Die neu zu Ihrem Warenkorb hinzugefügten Produkte werden zusammen mit Ihrer vorherigen Bestellung versendet. Das heißt, Sie erhalten alles in einem Paket.",
    "quantity": "Einheiten",
    "exclusivePrice": "Exklusiver Preis:",
    "totalPrice": "Zwischensumme",
    "shippment": "Versand im Auftrag enthalten",
    "thanks": "Vielen Dank!",
    "successText": "Sie haben die exklusiven Rabatte für Sie genutzt. Als nächstes werden wir mit der Vorbereitung Ihrer neuen Bestellung beginnen. Denken Sie daran, dass Sie alles zum ursprünglich erwarteten Datum erhalten werden. Genießen Sie Ihre neuen Produkte mit Rabatt!",
    "payNow": "Jetzt bezahlen",
    "paymentSuccess": "Ihre Zahlung wurde abgeschlossen",
    "paymentProcessing": "Ihre Zahlung wird bearbeitet",
    "paymentLabel": "Ihre Zahlungsdaten werden nicht auf unseren Servern gespeichert. Sie werden nur zur sicheren Abwicklung Ihrer Zahlung verwendet."
  },
  "upselling": {
    "discount": {
      "text": "KAUFE MIT RABATTEN UND ERHALTE ES IN EINER EINZIGEN BESTELLUNG!"
    },
    "days": [
      "Sonntag",
      "Montag",
      "Dienstag",
      "Mittwoch",
      "Donnerstag",
      "Freitag",
      "Samstag"
    ],
    "months": [
      "Januar",
      "Februar",
      "März",
      "April",
      "Mai",
      "Juni",
      "Juli",
      "August",
      "September",
      "Oktober",
      "November",
      "Dezember"
    ],
    "finished": {
      "condolences": "Es tut uns leid, dieses Angebot ist bereits beendet.",
      "condolencesBody1": "Es tut uns leid, dass Sie dieses Angebot nicht rechtzeitig nutzen konnten. Machen Sie sich keine Sorgen! Bei",
      "condolencesBody2": "arbeiten wir ständig daran, Ihnen neue und spannende Aktionen zu bieten.",
      "condolencesBody3": "In der Zwischenzeit laden wir Sie ein, unsere anderen Produkte und neuesten Entdeckungen zu erkunden, von denen wir sicher sind, dass sie Ihnen gefallen werden.",
      "titleTracking": "Prüfen Sie den Status Ihrer Bestellung",
      "trackingButton": "Verfolgen Sie es hier"
    },
    "recommendedProducts": {
      "title": "Empfohlen für Sie",
      "subtitleUpselling": "Wir glauben, dass diese Produkte zu Ihren Einkäufen passen. Jetzt in den Warenkorb legen und in der gleichen Bestellung erhalten.",
      "subtitleUpsellingEnd": "Wir denken, dass Sie an diesen Produkten interessiert sein könnten. Verpassen Sie nicht die Chance, diese versteckten Juwelen zu entdecken, die zu Ihren neuen Favoriten werden könnten.",
      "goToShop": "Zum Shop gehen",
      "addToCart": "Produkt hinzufügen",
      "modifyCart": "Warenkorb bearbeiten"
    },
    "upsellingProducts": {
      "title": ["Kaufe mit", "Rabatt", "und erhalte in derselben Bestellung"],
      "orderNumber": "Bestellnummer",
      "timeText": "ANGEBOTE IM COUNTDOWN!",
      "continue": "Fahre fort mit Lesen, um den Status deiner Bestellung zu sehen"
    },
    "paySummary": {
      "title1": "Ihr Warenkorb (",
      "title2": "neue Artikel )",
      "deleteCart": "Aus dem Warenkorb entfernen",
      "addMoreProducts": "Weitere Produkte hinzufügen",
      "endPurchase": "Kauf abschließen",
      "price": "Preis:"
    }
  },
  "iframe": {
    "addBanner": "Banner hinzufügen (optional)"
  },
  "exceptionsCarrier": {
    "CARRIER_WRONG_POSTAL_CODE": {
      "title": "Ungültige Postleitzahl",
      "subtitle": "Die eingegebene Postleitzahl ist falsch oder stimmt nicht mit der angegebenen Stadt überein."
    },
    "CARRIER_ADDRESS_TOO_LONG": {
      "title": "Adresse zu lang",
      "subtitle": "Die eingegebene Adresse ist zu lang, um das Versandetikett zu erstellen."
    },
    "CARRIER_PHONE_REQUIRED": {
      "title": "Telefonnummer erforderlich",
      "subtitle": "Das Versandetikett kann nicht erstellt werden, da die Telefonnummer fehlt."
    },
    "CARRIER_PHONE_TOO_LONG": {
      "title": "Telefonnummer zu lang",
      "subtitle": "Die eingegebene Telefonnummer ist zu lang, um das Versandetikett zu erstellen."
    },
    "CARRIER_PHONE_TOO_SHORT": {
      "title": "Telefonnummer zu kurz",
      "subtitle": "Die eingegebene Telefonnummer ist falsch; sie ist zu kurz, um das Versandetikett zu erstellen."
    },
    "CARRIER_ADDRESS_TOO_SHORT": {
      "title": "Adresse zu kurz",
      "subtitle": "Die eingegebene Adresse ist zu kurz, um das Versandetikett zu erstellen."
    },
    "carrierMessage": "Der Spediteur gibt folgende Nachricht an:"
  }
}
