<template>
  <v-fade-transition>
    <MainWrapper
      v-if="!loading"
      :company="company"
      :customTextsObject="customTextsObject"
    >
      <OrderName :order="order" :needAddInfo="needAddInfo" />
      <div
        v-if="returned || changed"
        class="d-flex flex-column justify-center align-center align-lg-start"
      >
        <h5 class="mb-10 text-center text-lg-start">
          {{
            changed
              ? $t(`options.change_progress`)
              : $t(`options.return_progress`)
          }}
        </h5>
        <v-btn
          type="button"
          class="btn btn-primary mb-10"
          width="300px"
          @click="toReturnInfoRoute"
        >
          {{ changed ? $t(`options.my_change`) : $t(`options.my_return`) }}
          <ChangeIcon />
        </v-btn>
      </div>
      <div
        v-if="
          order.internal_status === 'FULFILLED' && !returned && !daysChecked
        "
      >
        <h5 class="mb-10 text-center text-lg-start">
          {{ $t(`chAndRe.expired`) }}
        </h5>
      </div>
      <div
        v-if="
          order.internal_status === 'FULFILLED' &&
          !order.refused_order &&
          !returned &&
          daysChecked &&
          rules.canReturn
        "
      >
        <h5 class="mb-10 text-center text-lg-start">{{ rulesText }}</h5>
        <div
          class="d-flex flex-column flex-md-row justify-center align-center justify-lg-start"
        >
          <v-btn
            type="button"
            class="btn btn-primary mr-md-5 mb-10"
            width="280px"
            v-if="
              rules.canChange &&
              canChangeProduct &&
              !areAllProductsNonReturnable
            "
            @click="toChangesRoute"
          >
            {{ $t(`options.make_change`) }}
            <ChangeIcon />
          </v-btn>
          <v-btn
            type="button"
            :class="buttonStyle"
            width="280px"
            v-if="rules.canReturn && !areAllProductsNonReturnable"
            @click="toReturnsRoute"
          >
            {{ $t(`options.make_return`) }}
            <ReturnIcon />
          </v-btn>
          <p
            v-if="areAllProductsNonReturnable"
            class="body-2 text-error text-lg-start"
          >
            {{ $t('product.return.allProductsNoReturnable') }}
          </p>
        </div>
      </div>
      <template v-if="!needAddInfo">
        <ProgressBarMobile :order="order" />
        <ProgressBar :order="order" />
      </template>
      <ShippingDetails :order="order" />
      <h6 class="">{{ $t(`info.title.your_order`) }}</h6>
      <ShippingNumber :order="order" />
      <template v-for="item in order.products" :key="item.id">
        <ProductDetails v-if="item.product" :item="item" unicItem />
      </template>
      <OrderAddress :order="order" />
      <ChatComponent :company="company" :order="order" />
    </MainWrapper>
  </v-fade-transition>
</template>

<script>
import { mapActions } from 'vuex'
import { toBase, toChanges, toReturns, toReturnsInfo } from '@/router/navigate'
import { INTERNAL_STATUS } from '@/services/_const.service.js'
import { getReturnByOrder } from '@/services/returns.service.js'
import { checkCarrierError } from '@/helpers/const.carrierErrors.js'

import MainWrapper from '@/components/containers/MainWrapper.vue'
import ChatComponent from '@/components/shared/ChatComponent.vue'
import ProgressBar from '@/components/blocks/ProgressBar.vue'
import ProgressBarMobile from '@/components/blocks/ProgressBarMobile.vue'
import OrderName from '@/components/blocks/OrderName.vue'
import ShippingNumber from '@/components/blocks/ShippingNumber.vue'
import ShippingDetails from '@/components/blocks/ShippingDetails.vue'
import OrderAddress from '@/components/blocks/OrderAddress.vue'
import ProductDetails from '@/components/blocks/ProductDetails.vue'

import ChangeIcon from '@/components/icons/ChangeIcon.vue'
import ReturnIcon from '@/components/icons/ReturnIcon.vue'

const validStatus = [
  INTERNAL_STATUS.STATUS_PROCESSING,
  INTERNAL_STATUS.STATUS_PROCESSING_A,
  INTERNAL_STATUS.STATUS_SHIPPED,
  INTERNAL_STATUS.STATUS_SHIPPED_PUDO,
  INTERNAL_STATUS.STATUS_FULFILLED,
  INTERNAL_STATUS.STATUS_NEW,
  INTERNAL_STATUS.STATUS_NEW_COD,
  INTERNAL_STATUS.STATUS_NEW_HOLD,
  INTERNAL_STATUS.STATUS_EXCEPTION,
  INTERNAL_STATUS.STATUS_EXCEPTION_ID,
  INTERNAL_STATUS.STATUS_EXCEPTION_CURRIER,
  INTERNAL_STATUS.STATUS_FULFILLED_LOST,
  INTERNAL_STATUS.STATUS_PROCESSING_WAIT,
  INTERNAL_STATUS.STATUS_PROCESSING_RESERVED_STOCK,
]

export default {
  name: 'Tracking',
  components: {
    MainWrapper,
    ProgressBar,
    ProgressBarMobile,
    OrderName,
    ShippingDetails,
    OrderAddress,
    ProductDetails,
    ShippingNumber,
    ChangeIcon,
    ReturnIcon,
    ChatComponent,
  },
  props: {
    customTextsObject: {
      type: Object,
      default: () => {},
    },
    rules: {
      type: Object,
      default: () => {},
    },
    setLoading: {
      type: Function,
    },
    company: {
      type: Object,
      default: () => undefined,
    },
  },
  data() {
    return {
      loading: true,
      order: {},
      error: null,
      returned: false,
      changed: false,
      canChangeProduct: false,
      areAllProductsNonReturnable: false,
    }
  },
  mounted() {
    const parsedParams = {}
    this.setLoading(true)
    this.$route.hash
      .split('&')
      .map((part) => part.replace(/#/, ''))
      .forEach((param) => {
        const parts = [
          param.substr(0, param.indexOf('=')),
          param.substr(param.indexOf('=') + 1),
        ]
        parsedParams[parts[0]] = parts[1]
      })
    this.setTracking(parsedParams)
  },
  computed: {
    hasCarrierError() {
      return checkCarrierError(this.order)
    },
    needAddInfo() {
      return this.order.customer_id_card === 'required' || this.hasCarrierError
    },
    rulesText() {
      if (this.rules.canChange && this.rules.canReturn) {
        return this.$t(`options.return_or_change`)
      } else if (this.rules.canChange) {
        return this.$t(`options.change`)
      } else {
        return this.$t(`options.return`)
      }
    },
    buttonStyle() {
      if (this.rules.canChange && this.canChangeProduct) {
        return 'btn btn-secondary mb-10'
      } else if (this.rules.canReturn && !this.canChangeProduct) {
        return 'btn btn-primary mb-10'
      } else {
        return 'btn btn-primary mb-10'
      }
    },
    daysChecked() {
      if (this.order.time_fulfilled) {
        const today = new Date()
        const fulfillmentDay = new Date(this.order.time_fulfilled)
        const timeDiff = today.getTime() - fulfillmentDay.getTime() // Difference in milliseconds
        const daysPassed = Math.floor(timeDiff / (1000 * 60 * 60 * 24)) // Convert milliseconds to days
        return daysPassed <= Number(this.rules.fulfillmentDays)
      } else {
        return true
      }
    },
  },
  methods: {
    ...mapActions(['findOrder']),
    async setTracking(params) {
      const orderParams = {
        trackingNumber: params.t,
        postalCode: params.zc,
        publicKey: this.$route.params.publicKey,
        orderId: params.oi,
        products: true,
      }
      try {
        const res = await this.findOrder(orderParams)
        const products = res.products
        this.areAllProductsNonReturnable = products.every(
          (p) => p && p.product?.not_returnable
        )
        this.canChangeProduct =
          products &&
          products.some((p) => p && p.changes && p.changes.length > 0)
        if (!validStatus.includes(res.internal_status)) {
          toBase({
            publicKey: this.$route.params.publicKey,
          })
        }
        this.order = res
        const { company_id: companyId, id: orderId } = this.order
        // is refused, nothing to do with the return
        if (!this.order.refused_order){
          const returns = await getReturnByOrder({
            companyId,
            orderId,
          })
          if (returns.return !== null) {
            this.returned = true
          }
          if (
            returns.return &&
            (returns.return.products_changes !== undefined ||
              returns.return.products_changes_original !== undefined)
          ) {
            this.changed = true
          }
        }
      } catch (e) {
        this.error = e
        console.error(e)
        if (e.error === 404 || e.error === 403 || e.error === 427) {
          toBase({ publicKey: this.$route.params.publicKey, error: e.message })
        }
      } finally {
        this.loading = false
        this.setLoading(false)
      }
    },
    toChangesRoute() {
      toChanges({
        publicKey: this.$route.params.publicKey,
        tracking: this.order.shipping_number,
        zip_code: this.order.shipping_address_zip,
        order_id: this.order.id,
      })
    },
    toReturnsRoute() {
      toReturns({
        publicKey: this.$route.params.publicKey,
        tracking: this.order.shipping_number,
        zip_code: this.order.shipping_address_zip,
        order_id: this.order.id,
      })
    },
    toReturnInfoRoute() {
      toReturnsInfo({
        publicKey: this.$route.params.publicKey,
        tracking: this.order.shipping_number,
        zip_code: this.order.shipping_address_zip,
        order_id: this.order.id,
      })
    },
  },
}
</script>
<style lang="scss" scoped></style>
