import MockTrackingPage from "@/views/helpers/MockTrackingPage.vue";

import path from "@/router/const/path";
import name from "@/router/const/name";

export default [
  {
    path: path.MOCK_TRACKING_PAGE,
    name: name.MOCK_TRACKING_PAGE,
    component: MockTrackingPage,
  },
];
