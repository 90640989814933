{
  "error": {
    "ORDER_NOT_FOUND": "Pedido $order_id no encontrado",
    "INVALID_VALIDATION_PARAM": "Parámetro de validación incorrecto para el pedido $order_name",
    "INCOMPLETE_BODY_PARAMS": "Parámetros del cuerpo incompletos",
    "INCOMPLETE_COMPANY_PARAMS": "Parámetros del cuerpo incompletos",
    "CUSTOMIZATION_NOT_FOUND": "Personalización no encontrada",
    "MISSING_CONTACT_EMAIL": "La compañía $company_id no tiene correo de contacto",
    "MISSING_CUSTOMER_EMAIL": "El pedido $order_id no tiene correo del cliente",
    "ORDER_COMPANY_NOT_FOUND": "No se encontró el pedido con ID: $order_id y compañía: $company_id",
    "MISSING_BODY_PARAMS": "Parámetros del cuerpo faltantes",
    "DUPLICATE_RETURN_ID": "Ya existe una devolución con este ID: $order_id",
    "RETURN_ORDER_NOT_FOUND": "Pedido $order_id no encontrado",
    "EMPTY_RETURN_PRODUCTS": "Esta devolución no tiene productos"
  },
  "validationError": {
    "required": "Requerido",
    "email": "Por favor, introduce una dirección de correo válida.",
    "phone": "Por favor, introduce un número de teléfono válido.",
    "image": "Error de formato (Aceptados: .png, .jpg, .jpeg) y tamaño de archivo < 5MB.",
    "number": "La entrada debe ser un número",
    "cjk": "No se permiten caracteres CJK",
    "arabic": "No se permiten caracteres árabes",
    "comma": "No se permite el carácter COMA",
    "hyphen": "No se permite el carácter GUIóN"
  }
}
