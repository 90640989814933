<template>
  <v-dialog v-model="show" max-width="850" @update:modelValue="closeDialog">
    <v-card class="pa-0 rounded-lg">
      <v-row no-gutters>
        <v-spacer></v-spacer>
        <v-btn
          class="btn-icon-only-transparent text-error"
          :ripple="false"
          icon="mdi-close"
          @click="closeDialog"
        ></v-btn>
      </v-row>
      <v-row align="center" no-gutters>
        <v-col class="text-center ma-0 pa-0">
          <h3 class="mb-2 subtitle-3 text-no-wrap">
            {{ $t("upselling.paySummary.title1") }}
            {{ Object.keys(selectedProducts).length }}
            {{ $t("upselling.paySummary.title2") }}
          </h3>
        </v-col>
      </v-row>
      <v-table class="py-5 pa-3 px-sm-8 overflow-y-auto">
        <tbody>
          <tr
            v-for="[productId, item] in Object.entries(selectedProducts)"
            :key="productId"
          >
            <td class="py-4 px-0 ma-0" style="border-bottom: none">
              <v-row class="d-flex" align="center" no-gutters>
                <v-col class="ma-0 pa-0" cols="auto">
                  <v-avatar
                    variant="outlined"
                    :size="mobile ? 170 : 110"
                    rounded="0"
                    class="elevation-soft rounded-lg"
                    color="error"
                  >
                    <v-img :src="item.img" cover />
                  </v-avatar>
                </v-col>
                <v-col class="d-flex flex-column ml-2">
                  <v-row no-gutters>
                    <v-col cols="7">
                      <p class="body-1 font-weight-medium text-no-wrap">
                        {{ item.title }}
                      </p>
                      <p class="caption-1 pt-0">
                        {{ item.variant_title }}
                      </p>
                      <p class="caption-1 pt-5 pt-lg-10">
                        -{{
                          parseInt(
                            (1 -
                              +item.discount_price.split(" ")[0] /
                                +item.price.split(" ")[0]) *
                              100
                          )
                        }}%
                      </p>
                    </v-col>
                    <v-col>
                      <p
                        :class="mobile ? '' : 'text-end'"
                        style="font-size: 13px"
                      >
                        <del class="text-neutral60">{{ item.price }}</del>
                      </p>
                      <p
                        :class="mobile ? '' : 'text-end'"
                        style="font-size: 19px"
                      >
                        <span class="text-neutral00 bg-error">
                          {{ item.discount_price }}
                        </span>
                      </p>
                      <p :class="mobile ? '' : 'text-end'">
                        <v-btn
                          class="btn-tertiary ma-0 pl-sm-2 pa-0"
                          variant="plain"
                          @click="deleteProduct(productId)"
                        >
                          <span
                            :class="
                              mobile ? 'caption-1 pt-sm-5' : 'subtitle-3 pt-5'
                            "
                          >
                            {{ $t("upselling.paySummary.deleteCart") }}
                          </span>
                        </v-btn>
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </td>
          </tr>
        </tbody>
      </v-table>
      <v-divider color="neutral80" class="border-opacity-80 my-8"></v-divider>
      <v-row class="px-4 px-sm-8" no-gutters>
        <v-col cols="8">
          <span class="subtitle-3 font-weight-black">
            {{ $t("checkout.totalPrice") }}</span
          >
          <v-spacer></v-spacer>
          <span>{{ $t("checkout.shippment") }}</span>
        </v-col>
        <v-col class="text-end">
          <span class="subtitle-3 font-weight-black"> {{ totalPrice() }}</span>
        </v-col>
      </v-row>
      <v-row class="ma-4" align="center">
        <v-col cols="12" sm="6" :order-sm="1" :order="2" align="center">
          <v-btn class="btn btn-tertiary-icon" @click="passSelectedProducts">
            <span class="btn-text">
              {{ $t("upselling.paySummary.endPurchase") }}
            </span>
            <v-icon>mdi-arrow-right-thin</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" sm="6" :order-sm="2" :order="1" align="center">
          <v-btn class="btn btn-primary" @click="visualizeCart">
            {{ $t("upselling.paySummary.addMoreProducts") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { toCheckout } from "@/router/navigate";
import { useDisplay } from "vuetify";

export default {
  name: "ProductsPaySummary",
  props: {
    selectedProducts: {
      type: Object,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
    showSummary: {
      type: Boolean,
      default: false,
    },
    deleteProduct: {
      type: Function,
      required: true,
    },
  },

  data: () => ({
    quantity: 0,
    show: false,
  }),
  computed() {
    this.show = this.showSummary;
  },
  setup() {
    const { mobile } = useDisplay();
    return { mobile };
  },
  methods: {
    totalPrice() {
      const productsArray = Object.values(this.selectedProducts);
      if (productsArray.length === 0) return 0;

      const total = productsArray.reduce((acc, item) => {
        return (
          acc +
          (item.discount_price
            ? parseFloat(item.discount_price)
            : parseFloat(item.price))
        );
      }, 0);

      const currencyUnit = (
        productsArray[0].discount_price || productsArray[0].price
      ).split(" ")[1];

      return `${total.toFixed(2)} ${currencyUnit}`;
    },
    closeDialog() {
      this.show = false;
      this.$emit("updateSummary", false);
    },
    passSelectedProducts() {
      // eslint-disable-next-line no-unused-vars
      Object.entries(this.selectedProducts).forEach(([key, item]) => {
        item["quantity"] = 1;
      });
      localStorage.setItem(
        "selectedProducts",
        JSON.stringify(this.selectedProducts)
      );
      toCheckout({
        publicKey: this.$route.params.publicKey,
        tracking: this.order.shipping_number,
        zip_code: this.order.shipping_address_zip,
        order_id: this.order.id,
      });
    },
    visualizeCart() {
      this.show = false;
      let cart = Object.keys(this.selectedProducts).length != 0;
      this.$emit("viewCartChild", cart);
    },
  },
  watch: {
    showSummary(value) {
      this.show = value;
    },
  },
};
</script>

<style lang="scss" scoped></style>
