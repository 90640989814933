import ReturnInfo from "@/views/order/ReturnInfo.vue";

import path from "@/router/const/path";
import name from "@/router/const/name";

export default [
  {
    path: path.RETURN_INFO,
    name: name.RETURN_INFO,
    component: ReturnInfo,
  },
  {
    path: path.SHOP_RETURN_INFO,
    name: name.SHOP_RETURN_INFO,
    component: ReturnInfo,
  },
];
