{
    "error": {
      "ORDER_NOT_FOUND": "Bestellung $order_id nicht gefunden",
      "INVALID_VALIDATION_PARAM": "Ungültiger Validierungsparameter für Bestellung $order_name",
      "INCOMPLETE_BODY_PARAMS": "Unvollständige Körperparameter",
      "INCOMPLETE_COMPANY_PARAMS": "Unvollständige Unternehmensparameter",
      "CUSTOMIZATION_NOT_FOUND": "Personalisierung nicht gefunden",
      "MISSING_CONTACT_EMAIL": "Das Unternehmen $company_id hat keine Kontakt-E-Mail",
      "MISSING_CUSTOMER_EMAIL": "Die Bestellung $order_id hat keine Kunden-E-Mail",
      "ORDER_COMPANY_NOT_FOUND": "Bestellung mit ID: $order_id und Unternehmen: $company_id nicht gefunden",
      "MISSING_BODY_PARAMS": "Fehlende Körperparameter",
      "DUPLICATE_RETURN_ID": "Eine Rücksendung mit dieser ID: $order_id existiert bereits",
      "RETURN_ORDER_NOT_FOUND": "Bestellung $order_id nicht gefunden",
      "EMPTY_RETURN_PRODUCTS": "Diese Rücksendung enthält keine Produkte"
    },
    "validationError": {
      "required": "Erforderlich",
      "email": "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
      "phone": "Bitte geben Sie eine gültige Telefonnummer ein.",
      "image": "Fehlerformat (Akzeptiert: .png, .jpg, .jpeg) und Dateigröße < 5MB.",
      "number": "Die Eingabe muss eine Zahl sein",
      "cjk": "CJK-Zeichen nicht erlaubt",
      "arabic": "Arabische Zeichen nicht erlaubt",
      "comma": "Kommazeichen sind nicht zulässig",
      "hyphen": "Der Bindestrich ist nicht zulässig"
    }
  }
  