<template>
  <p
    class="pl-10 pb-5"
    style="font-size: 16px; font-weight: 505"
    v-if="quantityToReturn === 1"
  >
    {{ $t(`product.return.uploadImageDamage`) }}
  </p>
  <p class="pl-10 pb-5" style="font-size: 16px; font-weight: 505" v-else>
    {{ $t(`product.return.uploadImageDamagePlus`) }}
  </p>
  <p
    v-if="maxUpload"
    class="pl-10 text-error"
    style="font-size: 16px; font-weight: 500"
  >
    {{ $t(`product.return.maxUpload`) }}
  </p>
  <v-row class="pb-5 pt-5" align="center" no-gutters>
    <v-col :cols="8" :sm="10">
      <v-file-input
        id="fileUpload"
        accept="image/png, image/jpeg"
        prepend-icon=""
        hide-details
        variant="plain"
        color="info"
        multiple
        :clearable="false"
        v-model="uploadedFiles"
        @change="handleFileUpload"
        class="input-inner-c text-no-wrap pa-0 ma-0"
      />
    </v-col>
    <v-col :cols="4" :sm="2">
      <v-btn
        class="pa-0 ma-0 custom-outlined-btn w-100"
        outlined
        type="submit"
        :ripple="false"
        @click="getFile"
      >
        <div class="pa-2">
          <span class="text-neutral00">
            {{ $t(`product.return.addImage`) }}
          </span>
          <v-icon size="22" color="neutral00" class="pl-4"
            >mdi-file-upload-outline</v-icon
          >
        </div>
      </v-btn>
    </v-col>
  </v-row>
  <v-row class="mb-5" justify="center" align="center">
    <v-col
      v-for="(fileInfo, index) in imageSrcs"
      :key="index"
      cols="auto"
      class="mb-5 text-center"
    >
      <p class="text-info" style="font-size: 11px">
        {{ fileInfo.name }}
      </p>
      <v-hover v-slot="{ isHovering, props }">
        <v-card
          v-bind="props"
          @click="removeImage(index)"
          class="d-flex justify-center align-center mx-auto"
          variant="outlined"
          color="neutral40"
          width="150px"
          height="100px"
          flat
        >
          <v-icon
            color="error"
            size="38"
            style="position: absolute"
            v-if="isHovering"
            >mdi-trash-can-outline</v-icon
          >
          <v-img
            :class="{ 'image-opacity': isHovering }"
            width="150px"
            height="100px"
            :src="fileInfo.src"
          />
        </v-card>
      </v-hover>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ReturnInputUploader",
  emits: ["addImage", "removeImage"],

  props: {
    quantityToReturn: {
      type: [String, Number],
      default: 1,
    },
    return_product: {
      type: Object,
      default: () => undefined,
    },
    imageInfo: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      uploadedFiles: [],
      imageSrcs: [],
      maxUpload: false,
    };
  },

  computed: {
    ismaxUpload() {
      return this.imageSrcs.length >= this.quantityToReturn;
    },
  },
  methods: {
    handleFileUpload() {
      if (this.imageSrcs.length >= this.quantityToReturn) {
        this.maxUpload = true;
        return;
      }
      if (this.uploadedFiles && this.uploadedFiles.length > 0) {
        this.maxUpload = false;
        this.uploadedFiles.forEach((file) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.imageSrcs.push({
              src: e.target.result,
              name:
                this.return_product.id+ '_' + (this.imageSrcs.length + 1) +
                file.name.substring(file.name.indexOf(".")),
            });

            const imageInfo = {
              company_id: this.return_product.company_order.substring(
                0,
                this.return_product.company_order.indexOf(" ")
              ),
              order_id: this.return_product.company_order.substring(
                this.return_product.company_order.indexOf(" ") + 1
              ),
              src: e.target.result,
              name:
                this.return_product.id + '_'+ (this.imageSrcs.length + 1) +
                file.name.substring(file.name.indexOf(".")),
            };
            this.$emit("addImage", imageInfo);
          };
          reader.readAsDataURL(file);
        });
      }
      let fileUpload = document.getElementById("fileUpload");
      if (fileUpload) {
        fileUpload.value = "";
      }
    },
    createImageSrc(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imageSrcs.push(e.target.result);
      };
      reader.readAsDataURL(file);
    },
    removeImage(index) {
      this.maxUpload = false;
      this.uploadedFiles.splice(index, 1);
      this.imageSrcs.splice(index, 1);
      this.$emit("removeImage", index);
      let fileUpload = document.getElementById("fileUpload");
      if (fileUpload) {
        fileUpload.value = "";
      }
    },
    getFile() {
      let fileUpload = document.getElementById("fileUpload");
      if (fileUpload != null) {
        fileUpload.click();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-outlined-btn {
  background-color: rgb(var(--v-theme-info)) !important;
  border: 1px solid rgb(var(--v-theme-neutral60));
  border-radius: 4px;
}
.image-opacity {
  opacity: 0.2;
}

.input-inner-c {
  padding: 0px 40px !important;
  font-family: "Raleway", sans-serif !important;
  border-radius: 8px;
  border: 1px solid rgb(var(--v-theme-neutral20));
  box-shadow: 0px 4px 8px 0px rgba(143, 168, 191, 0.15) !important;
  transition: 0.2s ease-in-out;
  &:focus {
    border: 1px solid var(--amph-primary);
  }
  :deep .v-field__input {
    padding-top: 14px !important;
    padding-bottom: 0 !important;
  }
}
</style>
