import i18n from "@/i18n";

function getDefaultReturnMessages() {
  return [
    i18n.global.t(`product.options.DAMAGED`), // ALWAYS FIRST
    i18n.global.t(`product.options.TOO_BIG`),
    i18n.global.t(`product.options.TOO_SMALL`),
    i18n.global.t(`product.options.NOT_EXPECTED`),
    i18n.global.t(`product.options.NOT_ORDERED`),
    i18n.global.t(`product.options.OTHER`), // ALWAYS LAST
  ];
}

function filterReasonByLocale(customOptions) {
  const reasonsByLocale = customOptions.reduce((obj, item) => {
    obj[item.value] = item.reasons.filter((reason) => reason !== "");
    return obj;
  }, {});
  return reasonsByLocale;
}

export function getReturnMessages(company) {
  if (company.custom_options) {
    const customReasons = filterReasonByLocale(company.custom_options);
    if (customReasons[i18n.global.locale]) {
      return customReasons[i18n.global.locale];
    }
  }
  return getDefaultReturnMessages();
}
