<template>
  <svg
    width="417"
    height="33"
    viewBox="0 0 417 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="change">
      <path
        d="M17.5 1.21362L0 31.4136H12L14.6 26.8136H37.1L39.7 31.4136H51.7L34.4 1.21362H17.5ZM18.7 19.8136L24.4 9.91362H27.3L33 19.8136H18.7Z"
        fill="#282828"
      />
      <path
        d="M382.2 1.21362L364.8 31.4136H376.8L379.4 26.8136H401.9L404.5 31.4136H416.5L399.1 1.21362H382.2ZM383.5 19.8136L389.2 9.91362H392.1L397.8 19.8136H383.5Z"
        fill="#282828"
      />
      <path
        d="M86.5001 24.2136L68.3001 1.21362H56.6001V31.4136H68.3001V17.0136L78.4001 31.4136H94.6001L104.7 17.0136V31.4136H116.5V1.21362H104.7L86.5001 24.2136Z"
        fill="#282828"
      />
      <path
        d="M167.7 3.51362C166.2 2.71362 164.4 2.11362 162.3 1.71362C160.2 1.41362 158 1.21362 155.7 1.21362H123V31.4136H134.8V23.6136H155.7C158.1 23.6136 160.3 23.5136 162.3 23.3136C164.4 23.1136 166.2 22.6136 167.7 21.9136C169.2 21.2136 170.5 20.2136 171.3 18.7136C172.2 17.3136 172.6 15.4136 172.6 12.9136C172.6 10.5136 172.2 8.51362 171.3 7.01362C170.5 5.61362 169.3 4.41362 167.7 3.51362ZM159.9 14.9136C159.3 15.2136 158.5 15.3136 157.5 15.3136H134.8V10.6136H157.6C158.5 10.6136 159.3 10.7136 160 11.0136C160.6 11.3136 161 11.9136 161 12.9136C160.9 14.0136 160.6 14.6136 159.9 14.9136Z"
        fill="#282828"
      />
      <path
        d="M225.9 11.8136H190.7V1.21362H178.9V31.4136H190.7V20.8136H225.9V31.4136H237.7V1.21362H225.9V11.8136Z"
        fill="#282828"
      />
      <path
        d="M289 2.1136C284.8 0.913599 279.3 0.313599 272.4 0.313599C265.5 0.313599 260 0.913599 255.8 2.1136C251.6 3.3136 248.6 5.1136 246.7 7.5136C244.8 9.9136 243.8 12.8136 243.8 16.3136C243.8 19.8136 244.8 22.8136 246.7 25.1136C248.6 27.5136 251.6 29.3136 255.8 30.5136C260 31.7136 265.5 32.3136 272.4 32.3136C279.3 32.3136 284.8 31.7136 289 30.5136C293.2 29.3136 296.2 27.5136 298.1 25.1136C300 22.7136 301 19.8136 301 16.3136C301 12.8136 300 9.8136 298.1 7.5136C296.2 5.1136 293.2 3.3136 289 2.1136ZM286.3 20.7136C285 21.7136 283.2 22.4136 280.8 22.8136C278.4 23.2136 275.6 23.4136 272.4 23.4136C269.2 23.4136 266.3 23.2136 263.9 22.8136C261.5 22.4136 259.6 21.7136 258.2 20.7136C256.8 19.7136 256.1 18.2136 256.1 16.3136C256.1 14.4136 256.8 13.0136 258.2 11.9136C259.6 10.9136 261.5 10.2136 263.9 9.8136C266.3 9.4136 269.2 9.2136 272.4 9.2136C275.6 9.2136 278.4 9.4136 280.8 9.8136C283.2 10.2136 285 10.9136 286.3 11.9136C287.6 12.9136 288.2 14.4136 288.2 16.3136C288.2 18.2136 287.6 19.7136 286.3 20.7136Z"
        fill="#282828"
      />
      <path
        d="M353.7 2.21362C351.4 1.61362 348.9 1.21362 346 1.21362H308.1V31.4136H319.9V22.9136H339.8C342.3 22.9136 344.2 23.0136 345.4 23.2136C346.6 23.4136 347.4 23.7136 347.8 24.2136C348.2 24.7136 348.3 25.4136 348.3 26.3136V31.3136H360.1V24.8136C360.1 23.4136 359.7 22.4136 358.9 21.6136C358.1 20.9136 356.7 20.4136 354.7 20.1136C354.2 20.0136 353.6 20.0136 353 19.9136C353.3 19.9136 353.6 19.8136 353.9 19.8136C355.3 19.5136 356.6 19.1136 357.7 18.4136C358.8 17.8136 359.7 16.9136 360.3 15.8136C360.9 14.7136 361.2 13.2136 361.2 11.5136C361.2 8.91362 360.5 6.81362 359.2 5.31362C357.8 3.91362 356 2.91362 353.7 2.21362ZM319.9 15.1136V10.7136H346C346.9 10.7136 347.7 10.8136 348.4 11.1136C349 11.4136 349.4 12.0136 349.4 12.8136C349.4 13.7136 349.1 14.3136 348.4 14.6136C347.8 14.9136 347 15.1136 346 15.1136H319.9Z"
        fill="#282828"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "AmphoraLogo",
};
</script>
