{
    "error": {
      "ORDER_NOT_FOUND": "Pedido $order_id não encontrado",
      "INVALID_VALIDATION_PARAM": "Parâmetro de validação inválido para o pedido $order_name",
      "INCOMPLETE_BODY_PARAMS": "Parâmetros do corpo incompletos",
      "INCOMPLETE_COMPANY_PARAMS": "Parâmetros da empresa incompletos",
      "CUSTOMIZATION_NOT_FOUND": "Personalização não encontrada",
      "MISSING_CONTACT_EMAIL": "A empresa $company_id não possui e-mail de contato",
      "MISSING_CUSTOMER_EMAIL": "O pedido $order_id não possui e-mail do cliente",
      "ORDER_COMPANY_NOT_FOUND": "Pedido com ID: $order_id e empresa: $company_id não encontrado",
      "MISSING_BODY_PARAMS": "Parâmetros do corpo ausentes",
      "DUPLICATE_RETURN_ID": "Já existe uma devolução com este ID: $order_id",
      "RETURN_ORDER_NOT_FOUND": "Pedido $order_id não encontrado",
      "EMPTY_RETURN_PRODUCTS": "Esta devolução não possui produtos"
    },
    "validationError": {
      "required": "Obrigatório",
      "email": "Por favor, insira um endereço de e-mail válido.",
      "phone": "Por favor, insira um número de telefone válido.",
      "image": "Erro de formato (Aceitos: .png, .jpg, .jpeg) e tamanho do arquivo < 5MB.",
      "number": "A entrada deve ser um número",
      "cjk": "Caracteres CJK não permitidos",
      "arabic": "Caracteres árabes não permitidos",
      "comma": "Caractere vírgula não é permitido",
      "hyphen": "O caractere hífen não é permitido"
    }
  }
  