const STAGE = "prod"; // change for dev programming
const BASE_URL = `https://api.amphoralogistics.com/${STAGE}`;
const HEADERS = { "Content-Type": "application/json" };

// ----- ORDERS SERVICE ------

export const GET_ORDER_BY_TRACKING = `${BASE_URL}-aflora-orders/v1/getOrderByTracking`;
export const ADD_ID_CARD = `${BASE_URL}-aflora-orders/v1/addIdCard`;
export const ADD_SHIPPING_INFO = `${BASE_URL}-aflora-orders/v1/add_shipping_info`;
export const GET_INSTRUCTIONS_TRANSLATION = `${BASE_URL}-aflora-orders/v1/getInstructionsTranslation`;

export const SEND_MESSAGE_REQUEST = `${BASE_URL}-aflora-orders/v1/addOrderMessage`;

// ----- COMPANY SERVICE ------

export const GET_COMPANY = `${BASE_URL}-aflora-company/v1/getCompanyDataPublic`;

// ----- EMAILS SERVICE ------

export const SEND_CLIENT_EMAIL = `${BASE_URL}-helpers-emails/sendClientEmail`;

// ----- UPSELLING SERVICE ------

export const GET_UPSELLING_PRODUCTS = `${BASE_URL}-aflora-orders/v1/getUpsellingProducts`;
export const PAYMENT_INTENT = `${BASE_URL}-core-payments/upsellingPayment`;
export const ADD_SELECTED_PRODUCTS = `${BASE_URL}-core-payments/addUpsellingProducts`;

// ----- RETURNS SERVICE ------

export const GET_RETURN = `${BASE_URL}-aflora-returns/v1/getReturnPublic`;
export const CREATE_RETURN = `${BASE_URL}-aflora-returns/v1/createReturn`;
export const SAVE_RETURN_IMAGES = `${BASE_URL}-aflora-returns/v1/save_return_images`;
export const $fetchJson = async (options) => {
  const body = options.body;

  const requestOptions = {
    method: options.method,
    body: JSON.stringify(body),
    headers: HEADERS,
  };

  return fetch(options.url, requestOptions).then((response) => {
    if (!response.ok) {
      return response.json().then((_json) => {
        return Promise.reject({
          message: _json.message,
          code: _json.code,
          error: response.status,
        });
      });
    }
    return response.json();
  });
};
