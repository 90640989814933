<template>
  <div class="d-flex align-center jusitfy-center h-screen">
    <template v-if="logoImage">
      <v-img
        :src="logoImage"
        max-height="150px"
        width="150px"
        class="logo mx-6"
        contain
      />
      <v-progress-circular
        :size="200"
        :width="5"
        model-value="60"
        :color="companyPrimary"
        indeterminate
      ></v-progress-circular>
    </template>

    <template v-else>
      <AmphoraIcon class="logo mx-8" />
      <v-progress-circular
        :size="200"
        :width="5"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </template>
  </div>
</template>

<script>
import AmphoraIcon from "@/components/icons/AmphoraIcon.vue";
export default {
  name: "Loading",
  components: {
    AmphoraIcon,
  },
  props: {
    company: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    logoImage() {
      return this.company ? this.company.logo_url : undefined;
    },
    companyPrimary() {
      return this.company.primary_color || this.company.colors[0];
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.logo {
  position: absolute;
}
.spinner {
  width: 200px;
  height: 200px;
}
</style>
