import {
  $fetchJson,
  GET_COMPANY,
  SEND_MESSAGE_REQUEST,
} from "@/services/_index.service";

export const getCompanyCustomization = async ({ publicKey }) => {
  return $fetchJson({
    method: "POST",
    body: {
      public_key: publicKey,
    },
    url: GET_COMPANY,
  });
};

export const sendMessageRequest = async ({
  companyId,
  message,
  email,
  orderId,
  phone,
}) => {
  return $fetchJson({
    method: "POST",
    body: {
      company_id: companyId,
      order_id: orderId,
      message,
      email,
      phone_number: phone,
    },
    url: SEND_MESSAGE_REQUEST,
  });
};
