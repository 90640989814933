{
  "chat": {
    "title": "Parla con noi",
    "email": "Indirizzo e-mail",
    "email_placeholder": "per es: esempio{'@'}gmail.com",
    "tracking": "Numero di tracciamento",
    "tracking_placeholder": "per es: #01921883712873",
    "subject": "Selezionare il motivo della richiesta",
    "placeholder": "Scrivi un messaggio",
    "inform": "Ciao! Grazie per averci contattato tramite questa chat. Non possiamo gestire la tua richiesta immediatamente tramite questo canale, ma ti risponderemo via email il prima possibile. Grazie!",
    "send": "Invia",
    "options": {
      "select": "Seleziona un'opzione",
      "op1": "Fare una richiesta sulla spedizione",
      "op2": "Notificare un'incidenza",
      "op3": "Consultazione sulla politica di reso",
      "op4": "Consultazione sul processo di rimborso",
      "op5": "Richiedere l'annullamento del mio ordine",
      "op6": "Modificare il mio ordine"
    }
  },
  "chAndRe": {
    "tellUs": "Spiegaci perché desideri restituire l'ordine",
    "writeHere": "Scrivere qui",
    "fulfillmentDays": "Il periodo di ritorno (DAYS_TO giorni) di questo ordine è terminato.",
    "expired": "Il periodo di restituzione è scaduto",
    "change": "Modificare",
    "errorEmail": "E-mail mancante",
    "invalidEmail": "E-mail non valida",
    "unit": "Qtà.",
    "to_tracking": "Torna al tracciamento"
  },
  "app": {
    "power": "Offerto da"
  },
  "add": {
    "submit": "Invia",
    "id_card": "Aggiungi la tua carta d'identità",
    "success": "Successo!",
    "message": "Il tuo ordine è pronto per essere elaborato.",
    "invalidDNI": "Carta d'identità non valida",
    "required": "Campo obbligatorio",
    "info": "Il numero della carta d'identità o CIE di solito è stampato sul documento stesso ed è composto da otto cifre seguite da una lettera che funge da cifra di controllo.",
    "country": {
      "label": "Paese di Spedizione",
      "placeholder": "Seleziona"
    },
    "zipCode": {
      "label": "Codice Postale",
      "placeholder": "Inserisci il codice postale"
    },
    "city": {
      "label": "Città",
      "placeholder": "Inserisci la città"
    },
    "address": {
      "label": "Indirizzo",
      "placeholder": "Inserisci l'indirizzo"
    },
    "address2": {
      "label": "Informazioni aggiuntive sull'indirizzo",
      "placeholder": "Inserisci l'indirizzo"
    },
    "name": {
      "label": "Nome",
      "placeholder": "Inserisci il nome"
    },
    "phone": {
      "label": "Telefono",
      "placeholder": "Inserisci il numero di telefono"
    },
    "searchCountry": "Cerca paese"
  },
  "dialog": {
    "title": "Inserite il vostro indirizzo e-mail per poter elaborare la vostra domanda"
  },
  "info": {
    "form": {
      "tracking": "Numero d'ordine o di tracciamento",
      "tracking_ej": "per es. #02692F0405",
      "postal": "Codice Postale",
      "required_tracking": "È richiesto il numero d'ordine.",
      "postal_ej": "per es. 08006",
      "required_postal": "È richiesto il codice postale.",
      "search": "Ricerca",
      "tooltip_order": "Trova il tuo numero di tracciamento nell'email di conferma dell'ordine che hai ricevuto. Puoi trovarlo anche nella nota di consegna all'interno del pacco.",
      "tooltip_code": "È un codice numerico che determina una posizione. Puoi trovarlo facilmente chiamando l'ufficio postale o facendo una ricerca su internet.",
      "error": "Ordine non trovato"
    },
    "title": {
      "need": "Trova il tuo ordine",
      "tracking": "Numero di tracciamento",
      "your_order": "Il tuo ordine",
      "your_products": "I tuoi prodotti"
    }
  },
  "options": {
    "tracking": "Tracciamento: ",
    "order_number": "Numero d'ordine",
    "order_name": "Nome dell'ordine",
    "id": "ID dell'ordine",
    "return": "Vuoi effettuare un reso?",
    "change": "Vuoi effettuare un cambio?",
    "my_return": "Vedi il mio ritorno",
    "my_change": "Vedi il mio cambiamento",
    "return_progress": "Avete una restituzione in corso",
    "change_progress": "Avete un cambiamento in corso",
    "return_or_change": "Vuoi effettuare un cambio o un reso?",
    "make_return": "Effettuare un reso",
    "make_change": "Effettuare un cambio",
    "courier": {
      "name": "Il tuo ordine è gestito da",
      "contact": "servizio di trasporto di fiducia",
      "details": "Dettagli del corriere"
    },
    "noData": "Nessuna opzione disponibile"
  },
  "steps": {
    "return": {
      "step1": {
        "step": "Passo 1:",
        "desc": "Seleziona il prodotto che desideri restituire"
      },
      "step2": {
        "step": "Passo 2:",
        "desc": "Conferma il reso",
        "desc2": "Conferma il reso o seleziona altri prodotti"
      },
      "step3": {
        "desc": "Abbiamo inviato la richiesta di reso. Riceverai una risposta via email a breve."
      }
    },
    "change": {
      "step1": {
        "step": "Passo 1:",
        "desc": "Seleziona il prodotto che desideri scambiare"
      },
      "step2": {
        "step": "Passo 2:",
        "desc": "Conferma lo cambio",
        "desc2": "Conferma lo cambio o seleziona altri prodotti"
      },
      "step3": {
        "desc": "Abbiamo inviato la richiesta di cambio. Riceverai una risposta via email a breve."
      }
    }
  },
  "instructions": {
    "title": "Istruzioni per la Restituzione",
    "title_steps": "Come restituire un prodotto?",
    "subtitle_return": "Ritorno",
    "subtitle_change": "Cambiamento",
    "item_return": "I tuoi articoli da restituire",
    "item_change": "I tuoi articoli da cambiare",
    "prepare": "Prepara",
    "send": "Invia l'ordine di nuovo",
    "refund": "Rimborso",
    "information": {
      "desc_url_1": "Per effettuare il reso, segui le istruzioni riportate sull'etichetta. Tieni presente che se stai effettuando il reso da un Paese che non fa parte dell'Unione Europea, è essenziale stampare la fattura e inserirla nel pacco.",
      "desc_url_2": "Per restituire un articolo, basta attaccare questa etichetta al pacco e pianificheremo il ritiro.",
      "desc_url_3": "Per completare il reso, fai clic sul pulsante 'Maggiori informazioni' e segui le istruzioni.",
      "desc_cex": "Per finalizzare il reso, consegna il pacco in un ufficio postale e menziona che si tratta di una spedizione con 'Logistica Inversa de Correos express' in modo che possano generare un'etichetta di reso. Assicurati che le informazioni sull'etichetta corrispondano esattamente al riferimento dell'ordine '{ref}', inclusi numeri e lettere."
    },
    "qr": {
      "step1": "Téléchargez et imprimez 2 exemplaires du code QR. Collez une copie à l'extérieur du colis et incluez l'autre à l'intérieur. Ainsi, nous pouvons identifier votre retour et procéder au remboursement.",
      "important": "IMPORTANT : Le QR n'est pas une étiquette d'expédition ; c'est un code d'identification interne pour notre entrepôt afin d'identifier le produit. Vous devez également attacher l'étiquette d'expédition fournie par la société de transport de votre choix à votre colis.",
      "step2": "Apportez le colis à la société de transport qui vous convient le mieux et envoyez-le à l'adresse indiquée dans l'e-mail.",
      "step3": "Recevez votre remboursement une fois que nous avons reçu le colis de retour."
    },
    "defaultInstructions": {
      "step1": "Emballez soigneusement les articles à retourner.",
      "step2": "Suivez les instructions fournies pour procéder au retour.",
      "step3": "Recevez votre remboursement une fois que nous avons reçu le colis de retour."
    },
    "return": {
      "title": "Puoi ora inviare il reso",
      "desc": "Per effettuare il reso, ti preghiamo di seguire le istruzioni che abbiamo inviato al tuo indirizzo email.",
      "print_label": "Stampa l'etichetta di reso",
      "more_info": "Ulteriori informazioni",
      "qr_return": "Scaricare il codice QR",
      "identifier": "Identificatore di ritorno:"
    },
    "received": {
      "title": "Abbiamo ricevuto il tuo reso",
      "desc": "Il tuo reso è stato ricevuto con successo. Per ulteriori informazioni, contattaci."
    }
  },
  "product": {
    "return": {
      "title": "Vuoi restituire questo articolo?",
      "label": "Perché vuoi restituirlo?",
      "qty": "Quanti vuoi restituire?",
      "reason": "Scrivi qui il motivo del reso",
      "required": "Per favore, scrivi un motivo per il reso.",
      "date": "Reso disponibile fino al",
      "return": "Restituisci",
      "item": "articolo",
      "items": "articoli",
      "uploadImageDamage": "Allega l'immagine dell'articolo danneggiato",
      "uploadImageWrong": "Allega l'immagine dell'articolo sbagliato",
      "uploadImageDamagePlus": "Allega le immagini degli articoli danneggiati",
      "uploadImageWrongPlus": "Allega le immagini degli articoli sbagliati",
      "maxUpload": "Puoi allegare al massimo 1 immagine per prodotto",
      "addImage": "Aggiungi",
      "notReturnable": "Questo prodotto non è idoneo per resi o scambi.",
      "allProductsNoReturnable": "Non è possibile effettuare resi o scambi per nessuno dei prodotti"
    },
    "change": {
      "title": "Vuoi scambiare questo articolo?",
      "label": "Perché vuoi scambiarlo?",
      "product": "Opzioni di cambio",
      "reason": "Scrivi qui il motivo dello cambio",
      "required": "Per favore, scrivi un motivo per lo cambio.",
      "date": "Cambio disponibile fino al",
      "noStock": "Non ci sono scorte disponibili"
    },
    "confirm": "Conferma",
    "cancel": "Annulla",
    "options": {
      "select": "Seleziona un'opzione",
      "DAMAGED": "È arrivato danneggiato",
      "TOO_BIG": "Troppo grande",
      "TOO_SMALL": "Troppo piccolo",
      "NOT_EXPECTED": "Non è quello che mi aspettavo",
      "NOT_ORDERED": "Non è quello che ho ordinato",
      "OTHER": "Altro",
      "eg": "per es. non soddisfa le mie aspettative"
    }
  },
  "tracking": {
    "need": "Aggiungi informazioni",
    "resume": {
      "order": {
        "title": "Indirizzo di spedizione"
      }
    },
    "messages": {
      "processing": "Questo ordine è in elaborazione.",
      "shipped": "Questo ordine viene spedito.",
      "out": "Questo ordine è pronto per la consegna.",
      "ful": "Questo ordine è completo.",
      "refused": "Stato: rifiutato",
      "info": "Per soddisfare questa richiesta, abbiamo bisogno di ulteriori informazioni.",
      "wrongAddress": "Ops, sembra che ci sia un errore in alcuni campi.",
      "verifyField": "Per favore, verifica che tutte le informazioni siano corrette."
    },
    "progress": {
      "processing": "Stiamo elaborando il tuo ordine il più velocemente possibile",
      "shipped": "Il tuo ordine è già partito dal nostro magazzino",
      "delivery": "Quasi arrivato, il tuo ordine è in consegna",
      "delivered": "Abbiamo consegnato il tuo ordine",
      "refused": "Abbiamo tentato di consegnare il tuo pacco, ma è stato rifiutato. Per ulteriori informazioni, contattaci."
    }
  },
  "footer": {
    "contact": "Contatto",
    "help": "Aiuto",
    "policy": "Politica sulla riservatezza"
  },
  "checkout": {
    "checkout": "Checkout",
    "paymentDetails": "Dettagli del pagamento",
    "paymentDetailsText": "Sei a un solo passo dal completare il tuo acquisto! Si prega di rivedere i dettagli del tuo ordine e procedere con il pagamento sicuro.",
    "customerName": "Nome",
    "customerSurnames": "Cognome",
    "customerAdress": "Indirizzo di casa",
    "zipCode": "CAP",
    "city": "Città",
    "orderHeader": "Il tuo ordine",
    "orderText": "I nuovi prodotti aggiunti al tuo carrello verranno spediti insieme al tuo ordine precedente. Ovvero, riceverai tutto in un unico pacco.",
    "quantity": "unità",
    "exclusivePrice": "Prezzo esclusivo:",
    "totalPrice": "Subtotale",
    "shippment": "Spedizione inclusa nell'ordine",
    "thanks": "Grazie mille!",
    "successText": "Hai approfittato degli sconti esclusivi per te. Successivamente, procederemo a preparare il tuo nuovo ordine. Ricorda che riceverai tutto nella data prevista iniziale. Goditi i tuoi nuovi prodotti scontati!",
    "payNow": "Paga ora",
    "paymentSuccess": "Il tuo pagamento è stato completato",
    "paymentProcessing": "Il tuo pagamento è in elaborazione",
    "paymentLabel": "I tuoi dati di pagamento non vengono memorizzati sui nostri server. Vengono utilizzati solo per elaborare il tuo pagamento in modo sicuro."
  },
  "upselling": {
    "discount": {
      "text": "ACQUISTA CON SCONTI E RICEVILO IN UN UNICO ORDINE!"
    },
    "days": [
      "Domenica",
      "Lunedì",
      "Martedì",
      "Mercoledì",
      "Giovedì",
      "Venerdì",
      "Sabato"
    ],
    "months": [
      "Gennaio",
      "Febbraio",
      "Marzo",
      "Aprile",
      "Maggio",
      "Giugno",
      "Luglio",
      "Agosto",
      "Settembre",
      "Ottobre",
      "Novembre",
      "Dicembre"
    ],
    "finished": {
      "condolences": "Spiacente, questa offerta è già terminata.",
      "condolencesBody1": "Ci dispiace che tu non abbia potuto approfittare di questa offerta in tempo. Non preoccuparti! A",
      "condolencesBody2": "siamo sempre al lavoro per portarti nuove ed entusiasmanti promozioni.",
      "condolencesBody3": "Nel frattempo, ti invitiamo a esplorare i nostri altri prodotti e le recenti scoperte che siamo sicuri ti piaceranno.",
      "titleTracking": "Controlla lo stato del tuo ordine",
      "trackingButton": "Traccialo qui"
    },
    "recommendedProducts": {
      "title": "Raccomandato per te",
      "subtitleUpselling": "Crediamo che questi prodotti corrispondano ai tuoi acquisti. Aggiungi al carrello ora e ricevilo nello stesso ordine.",
      "subtitleUpsellingEnd": "Pensiamo che potresti essere interessato a questi prodotti. Non perdere l'occasione di scoprire queste gemme nascoste che potrebbero diventare i tuoi nuovi preferiti.",
      "goToShop": "Vai al negozio",
      "addToCart": "Aggiungi al carrello",
      "modifyCart": "Modifica il carrello"
    },
    "upsellingProducts": {
      "title": ["Acquista con", "sconto", "e ricevi nello stesso ordine"],
      "orderNumber": "Numero d'ordine",
      "timeText": "OFFERTE A COUNTDOWN!",
      "continue": "Continua a leggere per vedere lo stato del tuo ordine"
    },
    "paySummary": {
      "title1": "Il tuo carrello (",
      "title2": "nuovi articoli )",
      "deleteCart": "Rimuovi dal carrello",
      "addMoreProducts": "Aggiungi altri prodotti",
      "endPurchase": "Completa l'acquisto",
      "price": "Prezzo:"
    }
  },
  "iframe": {
    "addBanner": "Aggiungi un banner (opzionale)"
  },
  "exceptionsCarrier": {
    "CARRIER_WRONG_POSTAL_CODE": {
      "title": "Codice postale non valido",
      "subtitle": "Il codice postale inserito è errato o non corrisponde alla città specificata."
    },
    "CARRIER_ADDRESS_TOO_LONG": {
      "title": "Indirizzo troppo lungo",
      "subtitle": "L'indirizzo inserito è troppo lungo per generare l'etichetta di spedizione."
    },
    "CARRIER_PHONE_REQUIRED": {
      "title": "Numero di telefono richiesto",
      "subtitle": "L'etichetta di spedizione non può essere generata perché manca il numero di telefono."
    },
    "CARRIER_PHONE_TOO_LONG": {
      "title": "Numero di telefono troppo lungo",
      "subtitle": "Il numero di telefono inserito è troppo lungo per generare l'etichetta di spedizione."
    },
    "CARRIER_PHONE_TOO_SHORT": {
      "title": "Numero di telefono troppo corto",
      "subtitle": "Il numero di telefono inserito è errato; è troppo corto per generare l'etichetta di spedizione."
    },
    "CARRIER_ADDRESS_TOO_SHORT": {
      "title": "Indirizzo troppo corto",
      "subtitle": "L'indirizzo inserito è troppo corto per generare l'etichetta di spedizione."
    },
    "carrierMessage": "Il trasportatore indica il seguente messaggio:"
  }
}
