<template>
  <v-form ref="form" class="mb-10" v-model="valid" @submit.prevent="submitForm">
    <TopTitle>{{ $t("tracking.messages.wrongAddress") }}
    <template v-slot:subtitle>
      <p class="subtitle-2 mt-3">
        {{ $t("tracking.messages.verifyField") }}
      </p>
    </template>
    </TopTitle>
    <v-row
      v-if="errorDetails && errorDetails.title && errorDetails.subtitle"
      class="mb-5"
    >
      <v-col>
        <v-alert color="error" variant="tonal">
          <p
            class="body-1 font-weight-medium text-neutral80 d-flex align-baseline"
          >
            <v-icon size="16" class="mr-1">mdi-alert-circle-outline</v-icon>
            {{ errorDetails.title }}
          </p>
          <p class="body-1 text-neutral80">
            {{ errorDetails.subtitle }}
          </p>
        </v-alert>
      </v-col>
    </v-row>
    <v-row
      v-for="(field, key) in form"
      :key="key"
      justify="center"
      align="center"
    >
      <v-col :cols="12" :md="3">
        <p class="mb-0 mb-md-8">{{ $t(`add.${key}.label`) }}:</p>
      </v-col>
      <v-col :cols="12" :md="9">
        <v-select
          v-if="field.type === 'select'"
          v-model="field.value"
          class="option-select mb-8"
          :items="field.items"
          :placeholder="$t(`add.${key}.placeholder`)"
          :disabled="field.disabled"
          append-inner-icon="mdi-chevron-down"
          variant="plain"
          hide-details
        >
          <template v-slot:prepend-item>
            <v-list-item class="py-0">
              <v-text-field
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                color="info"
                :placeholder="$t('add.searchCountry')"
                variant="underlined"
                hide-details
                class="customSearch"
              ></v-text-field>
            </v-list-item>
          </template>
          <template v-slot:selection="{ item }">
            <CountryFlag :countryCode="item.title" class="mr-2 mt-1" />
            {{ countryLabel(item.title) }}
          </template>
          <template v-slot:item="{ props, item }">
            <v-list-item
              v-if="
                !search ||
                countryLabel(item.title)
                  .toLowerCase()
                  .includes(search.toLowerCase())
              "
              v-bind="props"
              :title="countryLabel(item.title)"
            >
              <template #prepend>
                <CountryFlag :countryCode="item.title" class="mr-2" />
              </template>
            </v-list-item>
          </template>
        </v-select>
        <v-text-field
          v-if="field.type === 'input'"
          v-model="field.value"
          :placeholder="$t(`add.${key}.placeholder`)"
          :rules="field.customRules || getRules()"
          class="input-custom"
          variant="plain"
        />
      </v-col>
    </v-row>
    <v-btn
      :disabled="!hasChanges || !valid"
      :loading="loading"
      type="submit"
      class="btn btn-primary wide-btn my-10 align-self-start"
    >
      {{ $t("add.submit") }}
    </v-btn>
  </v-form>
  <CustomAlert v-if="errorMessage" error :text="errorMessage" />
</template>

<script>
import { toTracking } from "@/router/navigate";
import { add_shipping_info } from "@/services/orders.service.js";
import { countryName, countryCodes } from "@/services/utils/countryCodes";
import { getErrorDetails } from "@/helpers/const.carrierErrors.js";
import CustomAlert from "@/components/blocks/CustomAlert.vue";
import {
  requiredRule,
  noCJKRule,
  noArabicRule,
  noCommaRule,
  noHyphenRule,
  phoneValidationRule,
} from "@/utils/inputValidators.js";
import TopTitle from "@/components/blocks/TopTitle.vue";
import CountryFlag from "@/components/countryFlags/CountryFlag.vue";

export default {
  name: "AddAddressInfo",
  props: {
    order: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    TopTitle,
    CustomAlert,
    CountryFlag,
  },
  data() {
    return {
      form: {
        name: {
          value: this.order.shipping_address_name,
          type: "input",
          customRules: [noCJKRule, noArabicRule, noCommaRule],
        },
        address: {
          value: this.order.shipping_address,
          type: "input",
          customRules: [noCJKRule, noArabicRule, noCommaRule, noHyphenRule],
        },
        address2: {
          value: this.order.shipping_address2,
          type: "input",
          customRules: [noCJKRule, noArabicRule, noCommaRule, noHyphenRule],
        },
        zipCode: {
          value: this.order.shipping_address_zip,
          type: "input",
          customRules: [noCJKRule, noArabicRule, noCommaRule],
        },
        city: {
          value: this.order.shipping_address_city,
          type: "input",
          customRules: [noCJKRule, noArabicRule, noCommaRule],
        },
        country: {
          value: this.order.shipping_address_country_code,
          items: countryCodes,
          type: "select",
          disabled: true,
        },
        phone: {
          value: this.order.customer_phone,
          type: "input",
          customRules: [
            noCJKRule,
            noArabicRule,
            noCommaRule,
            noHyphenRule,
            phoneValidationRule,
          ],
        },
      },
      loading: false,
      valid: false,
      errorMessage: null,
      search: null,
      countryName: countryName,
      errorDetails: {},
    };
  },
  mounted() {
    // validate the data coming from the server
    this.$refs.form.validate();
    this.errorDetails = getErrorDetails(this.order?.exception_message);
  },
  computed: {
    hasChanges() {
      return (
        this.order.shipping_address_country_code !== this.form.country.value ||
        this.order.shipping_address_zip !== this.form.zipCode.value ||
        this.order.shipping_address_city !== this.form.city.value ||
        this.order.shipping_address !== this.form.address.value ||
        this.order.shipping_address2 !== this.form.address2.value ||
        this.order.customer_phone !== this.form.phone.value ||
        this.order.shipping_address_name !== this.form.name.value
      );
    },
  },
  methods: {
    countryLabel(code) {
      return `${code} | ${countryName[code]}`;
    },
    async submitForm(event) {
      event.preventDefault();
      this.loading = true;
      try {
        await add_shipping_info({
          companyId: this.order.company_id,
          orderId: this.order.id,
          countryCode: this.form.country.value,
          zipCode: this.form.zipCode.value,
          addressCity: this.form.city.value,
          shippingAddress: this.form.address.value,
          shippingAddress2: this.form.address2.value,
          shippingAddressName: this.form.name.value,
          customerPhone: this.form.phone.value,
        });
        this.toTrackingRoute();
      } catch (e) {
        this.errorMessage = e.message;
      } finally {
        this.loading = false;
      }
    },

    toTrackingRoute() {
      toTracking({
        publicKey: this.$route.params.publicKey,
        order_id: this.order.id,
        zip_code: this.form.zipCode.value,
        tracking: this.order.shipping_number,
      });
    },
    getRules() {
      return [requiredRule, noCJKRule, noArabicRule, noCommaRule, noHyphenRule];
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.v-select {
  border-radius: 8px;
  font-size: 10px;
  border: 1px solid rgb(var(--v-theme-neutral20));
  box-shadow: 0px 34px 100px rgba(143, 168, 191, 0.3);
  &:hover {
    background-color: rgb(var(--v-theme-neutral05));
    // background-color: var(--amph-light);
  }
  &:focus-within {
    border: 1px solid var(--amph-primary);
  }
}
:deep .v-field__input {
  padding-left: 40px;
  padding-top: 15px !important;
}
:deep .v-field__append-inner {
  padding-right: 15px !important;
}
:deep .v-overlay__scrim {
  opacity: 0.4 !important;
}
:deep.v-field__append-inner {
  padding-right: 20px;
  padding-bottom: 12px;
}
.v-input__control {
  max-width: 200px;
}

:deep .v-field__field {
  padding-bottom: 6px;
  padding-top: 2px;
}

:deep .customSearch .v-field__input {
  padding-left: 10px;
  padding-top: 0px !important;
}
</style>
