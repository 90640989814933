import Returns from "@/views/order/Returns.vue";

import path from "@/router/const/path";
import name from "@/router/const/name";

export default [
  {
    path: path.RETURNS,
    name: name.RETURNS,
    component: Returns,
  },
  {
    path: path.SHOP_RETURNS,
    name: name.SHOP_RETURNS,
    component: Returns,
  },
];
