<template>
  <v-dialog
    v-model="show"
    width="800"
    scroll-strategy="none"
    class="overflow-y-auto"
  >
    <template v-slot:activator="{ props }">
      <ProductDetails v-bind="props" :item="item" :class="productClass()">
        <template v-slot:badge>
          <NumberBadge v-if="toReturn">{{ quantityToReturn }}</NumberBadge>
        </template>
      </ProductDetails>
    </template>
    <v-card class="rounded-xl pa-3 pa-lg-8">
      <h5 class="my-8 text-center" v-if="!canChange">
        {{ $t(`product.return.title`) }}
      </h5>
      <h5 class="my-8 text-center" v-else>{{ $t(`product.change.title`) }}</h5>
      <ProductDetails :item="item" width="100%" />
      <template v-if="item.quantity > 1">
        <CustomSelect
          :items="quantity"
          :placeholder="$t(`product.options.select`)"
          :value="quantityToReturn"
          @input="handleValueQty"
        >
          <p>{{ $t(`product.return.qty`) }}</p>
        </CustomSelect>
      </template>
      <CustomSelect
        :items="returnMessages"
        :placeholder="$t(`product.options.select`)"
        :value="selectedValue"
        @input="handleValueMessage"
      >
        <p v-if="!canChange">{{ $t(`product.return.label`) }}</p>
        <p v-else>{{ $t(`product.change.label`) }}</p>
      </CustomSelect>
      <template v-if="selectedValue === returnMessages.at(-1)">
        <InputField
          :placeholder="$t(`product.options.eg`)"
          :rules="returnRules"
          :value="otherMessage"
          @input="otherField"
        >
          <p v-if="!canChange">{{ $t(`product.return.reason`) }}</p>
          <p v-else>{{ $t(`product.change.reason`) }}</p>
        </InputField>
      </template>
      <template v-if="selectedValue === returnMessages.at(0)">
        <ReturnInputUploader
          :quantityToReturn="quantityToReturn"
          :return_product="item"
          :imageInfo="imageInfo"
          @addImage="addImageInfo"
          @removeImage="removeImage"
        />
      </template>
      <template v-if="item.changes && item.changes.length !== 0 && canChange">
        <CustomSelect
          :items="changeOptions"
          :noChange="changeOptions.length > 0"
          :placeholder="$t(`product.options.select`)"
          :value="selectedChange"
          @input="handleValueChange"
        >
          <p>{{ $t(`product.change.product`) }}</p>
        </CustomSelect>
      </template>
      <div class="d-flex justify-center">
        <v-btn
          class="btn btn-tertiary mb-10"
          type="submit"
          :ripple="false"
          variant="plain"
          v-on:click="show = false"
          @click="cancelSelectedValue"
        >
          {{ $t(`product.cancel`) }}
        </v-btn>
        <v-btn
          id="submit"
          :disabled="isDisabled"
          class="btn btn-primary mb-10"
          type="submit"
          v-on:click="show = false"
          @click="submitSelectedValue"
        >
          {{ $t(`product.confirm`) }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { getReturnMessages } from "@/utils/returnMessages.js";
import ProductDetails from "@/components/blocks/ProductDetails.vue";
import NumberBadge from "@/components/blocks/NumberBadge.vue";
import InputField from "@/components/inputs/InputField.vue";
import CustomSelect from "@/components/inputs/CustomSelect.vue";
import ReturnInputUploader from "@/components/inputs/ReturnInputUploader.vue";

export default {
  name: "ProductDialog",
  components: {
    ProductDetails,
    NumberBadge,
    InputField,
    CustomSelect,
    ReturnInputUploader,
  },
  props: {
    item: {
      type: Object,
      default: () => undefined,
    },
    returnProducts: {
      type: Array,
      default: () => [],
    },
    returned: {
      type: Boolean,
      default: false,
    },
    canChange: {
      type: Boolean,
      default: false,
    },
    company: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      show: false,
      toReturn: false,
      selectedValue: null,
      selectedChange: null,
      returnedProduct: {},
      otherMessage: "",
      quantityToReturn: "1",
      changeOptions: [],
      change: {},
      quantity: [],
      returnRules: [
        (value) => {
          if (value) return true;

          return this.$t(`product.return.required`);
        },
      ],
      imageInfo: [],
    };
  },
  created() {
    this.totalQuantity();
    this.getChangeOptions();
  },
  watch: {
    show(val) {
      if (!val) {
        this.imageInfo = [];
      }
    },
    returnProducts(newVal) {
      if (newVal.length === 0) {
        this.toReturn = false;
      }
    },
    selectedValue(newValue) {
      this.getChangeOptions(newValue);
    },
  },
  computed: {
    returnMessages() {
      return getReturnMessages(this.company)
    },
    isDisabled() {
      if (this.canChange) {
        if (this.selectedValue === this.returnMessages.at(-1)) {
          return this.otherMessage === "" || this.selectedChange === null;
        } else {
          return this.selectedValue === null || this.selectedChange === null;
        }
      } else {
        if (this.selectedValue === this.returnMessages.at(-1)) {
          return this.otherMessage === "";
        } else {
          return this.selectedValue === null;
        }
      }
    },
    returnMessageCode() {
      return [
        "DAMAGED",
        "TOO_BIG", 
        "TOO_SMALL",
        "NOT_EXPECTED",
        "NOT_ORDERED",
        "OTHER",
      ];
    },
  },
  methods: {
    addImageInfo(imageInfo) {
      this.imageInfo.push(imageInfo);
    },
    removeImage(index) {
      this.imageInfo.splice(index, 1);
    },
    productClass() {
      if (this.toReturn) {
        return "return-card";
      } else if (this.returned) {
        return "disabled-card";
      } else {
        return "default-card";
      }
    },
    getChangeOptions(selectedValue) {
      if (this.item.changes) {
        const hasStock = this.item.changes.filter((i) => i.has_stock === true);
        const withoutSameItem = hasStock.filter((i) => i.id !== this.item.id);
        //show all changes options if "damaged" option is selected
        if (selectedValue === this.returnMessages.at(0)) {
          this.changeOptions = hasStock.map((item) => item.variant_title);
        } else {
          this.changeOptions = withoutSameItem.map(
            (item) => item.variant_title
          );
        }
      }
    },
    cancelSelectedValue() {
      this.toReturn = false;
      this.selectedValue = null;
      this.selectedChange = null;
      this.otherMessage = "";
      this.imageInfo = [];
      this.$emit("canceled", this.item.id);
    },
    submitSelectedValue() {
      let message;
      if (this.selectedValue === this.returnMessages.at(-1)) {
        message = this.otherMessage;
      } else {
        message = this.returnMessageCode.at(
          this.returnMessages.indexOf(this.selectedValue)
        );
      }
      this.returnedProduct = {
        id: this.item.id,
        message: message,
        quantity: this.quantityToReturn,
        images: this.imageInfo,
      };

      if (this.canChange) {
        this.change = this.item.changes.find(
          (p) => p.variant_title === this.selectedChange
        );
        this.$emit(
          "selectedChange",
          this.change,
          this.returnedProduct,
          this.quantityToReturn,
        );
      }
      this.$emit("selected", this.returnedProduct);
      this.toReturn = true;
    },
    totalQuantity() {
      if (this.item.quantity > 1) {
        let qty = this.item.quantity;
        for (let i = 1; i <= qty; i++) {
          this.quantity.push(i);
        }
      }
    },
    // Receive "value" from child select component
    handleValueChange(value) {
      this.selectedChange = value;
    },
    handleValueMessage(value) {
      this.selectedValue = value;
    },
    handleValueQty(quantity) {
      this.quantityToReturn = quantity;
    },
    otherField(value) {
      this.otherMessage = value;
    },
  },
  emits: ["value", "selected", "canceled", "selectedChange"], // Declare the custom event listener
};
</script>

<style lang="scss" scoped>
.v-card {
  overflow-y: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
.v-dialog > .v-overlay__content > .v-card {
  box-shadow: 0px 34px 100px rgba(143, 168, 191, 0.3) !important;
}
.default-card {
  &:hover {
    background-color: rgb(var(--v-theme-neutral05));
  }
}
.return-card {
  background-color: rgb(var(--v-theme-infoLight));
  border-color: rgb(var(--v-theme-info));
}
.disabled-card {
  background-color: rgb(var(--v-theme-neutral05));
  color: rgb(var(--v-theme-neutral20));
  cursor: auto;
  :deep .v-ripple__container {
    display: none !important;
  }
}
@media (min-width: 768px) {
  #submit {
    width: 250px;
  }
}
</style>
