<template>
  <v-card class="mx-n11 pb-10" elevation="0" width="auto" max-width="750">
    <p class="mx-11 pb-5 text-neutral80 subtitle-sm-1 responsive-text">
      {{ $t("upselling.upsellingProducts.title[0]") }}
      <span class="text-error">{{
        $t("upselling.upsellingProducts.title[1]")
      }}</span>
      {{ $t("upselling.upsellingProducts.title[2]") }}
    </p>

    <div class="d-flex justify-center">
      <v-progress-circular
        v-if="loading"
        indeterminate
        color="neutral80"
        :size="100"
        :width="5"
        class="mb-10"
      ></v-progress-circular>
    </div>
    <v-table class="pr-md-0">
      <tbody>
        <tr v-for="item in upsellingProducts" :key="item.id">
          <td class="pa-0 ma-0 py-2 px-sm-11" style="border-bottom: none">
            <UpsellingProductDetails
              :item="item"
              :addProduct="addProduct"
              :modifyProduct="modifyProduct"
              :selectedItem="(item.id in modelValue) & cartOpen"
            >
            </UpsellingProductDetails>
          </td>
        </tr>
      </tbody>
    </v-table>
    <p class="mx-6 footnote pt-5">
      {{ $t("upselling.upsellingProducts.continue") }} &darr;
    </p>
  </v-card>
</template>

<script>
import UpsellingProductDetails from "@/components/blocks/UpsellingProductDetails.vue";
export default {
  name: "UpsellingProducts",
  components: {
    UpsellingProductDetails,
  },
  data: () => ({
    selectedProducts: {},
    cart: false,
  }),
  props: {
    order: {
      type: Object,
      default: () => {},
    },
    upsellingProducts: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: Object,
      required: true,
    },
    addProduct: {
      type: Function,
      required: true,
    },
    modifyProduct: {
      type: Function,
      required: true,
    },
    cartOpen: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    itemRowBackground(item) {
      return item.id in this.modelValue ? "infoLight" : "";
    },
    goToExternalLink(link) {
      window.location.href = link;
    },
  },
  computed: {
    shop() {
      return this.order.shop ? this.order.shop : "https://www.thehillspress.es";
    },
  },
};
</script>
<style lang="scss" scoped>
.responsive-text {
  font-family: Syne;
  font-size: 33px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
}

@media (max-width: 600px) {
  /* Mobile devices */
  .responsive-text {
    font-size: 23px;
  }
}
</style>
