<template>
  <div>
    <v-sheet height="400">
      <div class="success" v-if="success">
        <h3 class="mb-10 text-success">{{ $t(`add.success`) }}</h3>
        <h4 class="mb-10">{{ $t(`add.message`) }}</h4>
        <v-btn
          append-icon="mdi-redo-variant"
          class="btn btn-primary mb-10"
          @click="toTrackingRoute()"
        >
          {{ $t(`chAndRe.to_tracking`) }}
        </v-btn>
      </div>
      <v-form v-model="valid" @submit="submitForm" v-else>
        <TopTitle>{{ $t(`tracking.messages.info`) }}</TopTitle>
        <InputField
          :placeholder="$t(`chAndRe.writeHere`)"
          :rules="rules"
          :readonly="loading"
          :value="customerIdCard"
          @input="idField"
        >
          {{ $t(`add.id_card`) }}
        </InputField>
        <v-btn
          :disabled="!valid"
          :loading="loading"
          type="submit"
          class="btn btn-primary wide-btn mb-10 align-self-start"
        >
          {{ $t(`add.submit`) }}
        </v-btn>
      </v-form>
    </v-sheet>
  </div>
</template>

<script>
import { toTracking } from "@/router/navigate";
import { addIdCard } from "@/services/orders.service.js";

import TopTitle from "@/components/blocks/TopTitle.vue";
import InputField from "@/components/inputs/InputField.vue";

export default {
  name: "InfoComponent",
  props: {
    order: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    TopTitle,
    InputField,
  },
  data() {
    return {
      loading: false,
      success: false,
      valid: false,
      submitted: false,
      error: null,
      customerIdCard: "",
      idCardRegex: /^(\d{8}[A-Za-z])|([A-Za-z]\d{7}[A-Za-z])$/,
      rules: [
        (value) => {
          if (!value) return this.$t("add.required");
          if (!this.idCardRegex.test(value)) {
            if (this.submitted) {
              return this.$t("add.invalidDNI");
            } else {
              return true;
            }
          }
          return true;
        },
      ],
    };
  },
  methods: {
    submitForm(event) {
      event.preventDefault();

      this.loading = true;
      addIdCard({
        companyId: this.order.company_id,
        orderId: this.order.id,
        idCard: this.customerIdCard,
      })
        .then(() => {
          this.success = true;
          this.submitted = true;
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.valid = true;
          this.loading = false;
        });
    },
    toTrackingRoute() {
      toTracking({
        publicKey: this.$route.params.publicKey,
        order_id: this.order.id,
        zip_code: this.order.shipping_address_zip,
        tracking: this.order.shipping_number,
      });
    },
    idField(value) {
      this.customerIdCard = value;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped></style>
