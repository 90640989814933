<template>
  <template v-if="company.customer_email && order.customer_email">
    <v-btn class="btn-icon-only ma-5 open-btn" @click="toggleChat">
      <EmailIcon :stroke="iconFill" :stroke-width="strokeWidth" />
    </v-btn>
    <v-sheet
      class="mb-5"
      color="transparent"
      position="fixed"
      style="z-index: 20"
    >
      <v-scale-transition origin="bottom">
        <v-sheet
          v-show="active"
          color="neutral00"
          class="rounded-left elevation-strong"
          :width="mobile ? 350 : 600"
          :height="mobile ? 590 : 560"
        >
          <v-btn class="btn-icon-only ma-n6" @click="toggleChat">
            <v-icon size="x-large">mdi-close</v-icon>
          </v-btn>
          <div class="d-flex justify-end align-end pa-3">
            <h6 class="text-neutral40 text-uppercase px-3">
              {{ $t(`chat.title`) }}
            </h6>
          </div>
          <v-divider></v-divider>
          <div class="d-flex flex-row-reverse">
            <v-sheet
              :max-width="mobile ? 320 : 600"
              :max-height="mobile ? 280 : 300"
              position="absolute"
              class="overflow"
            >
              <v-slide-x-reverse-transition>
                <div v-show="show" class="bg-custom rounded-xl pa-5 ma-5">
                  <p class="text-neutral80">
                    {{ message }}
                  </p>
                </div>
              </v-slide-x-reverse-transition>
            </v-sheet>
          </div>
          <v-sheet width="100%" height="auto" position="absolute" class="mb-5">
            <v-slide-x-transition class="delay">
              <div v-show="show" class="bg-neutral05 rounded-xl pa-5 mx-5">
                <p class="text-neutral80 text-wrap">
                  {{ $t(`chat.inform`) }}
                </p>
              </div>
            </v-slide-x-transition>
          </v-sheet>
          <CustomAlert :error="showAlert" :text="translatedErrorMessage" />
          <v-form
            v-if="!show"
            class="d-flex flex-column mt-5 px-4"
            @submit.prevent="submit"
          >
            <CustomSelect
              :items="returnMessage"
              :placeholder="$t(`chat.options.select`)"
              :value="subjectEmail"
              @input="handleSubject"
            >
              <p>{{ $t(`chat.subject`) }}:</p>
            </CustomSelect>
            <div class="d-flex flex-column">
              <v-textarea
                class="pb-3"
                no-resize
                outlined
                :placeholder="$t(`chat.placeholder`)"
                v-model="message"
                maxlength="500"
                counter
                variant="plain"
              ></v-textarea>
              <div class="d-flex justify-space-between align-center mt-5">
                <v-spacer />
                <v-btn
                  class="align-self-end btn btn-primary"
                  type="submit"
                  :disabled="isDisabled"
                  :loading="loading"
                  width="200px"
                >
                  {{ $t(`chat.send`) }}
                  <LensIcon />
                </v-btn>
              </div>
            </div>
          </v-form>
        </v-sheet>
      </v-scale-transition>
    </v-sheet>
  </template>
</template>

<script>
import { useDisplay } from "vuetify";

import { replaceErrorMessage } from "@/helpers/const.utils";
import { sendClientEmail } from "@/services/emails.service.js";
import EmailIcon from "@/components/icons/EmailIcon.vue";
import LensIcon from "@/components/icons/LensIcon.vue";
import CustomSelect from "@/components/inputs/CustomSelect.vue";
import CustomAlert from "@/components/blocks/CustomAlert.vue";
export default {
  props: {
    order: {
      type: Object,
      default: () => ({}),
    },
    company: {
      type: Object,
      default: () => undefined,
    },
  },
  components: {
    EmailIcon,
    LensIcon,
    CustomSelect,
    CustomAlert,
  },
  setup() {
    const { mobile } = useDisplay();
    return { mobile };
  },
  data() {
    return {
      showAlert: false,
      hide: true,
      active: false,
      loading: false,
      message: "",
      sended: false,
      show: false,
      iconFill: "rgb(var(--v-theme-neutral80))",
      strokeWidth: 0.65,
      subjectEmail: null,
      uploadedFiles: [],
      error: null,
    };
  },
  computed: {
    translatedErrorMessage() {
      if (this.error) {
        return replaceErrorMessage(this.error, this.$t);
      }
      return null;
    },
    returnMessage() {
      return [
        this.$t(`chat.options.op1`),
        this.$t(`chat.options.op2`),
        this.$t(`chat.options.op3`),
        this.$t(`chat.options.op4`),
        this.$t(`chat.options.op5`),
        this.$t(`chat.options.op6`),
      ];
    },
    isDisabled() {
      return !this.subjectEmail || !this.message;
    },
  },
  methods: {
    toggleChat() {
      this.active = !this.active;
    },
    async submit() {
      this.loading = true;
      this.show = true;
      this.sended = true;
      sendClientEmail({
        companyId: this.company.company_id,
        id: this.order.id,
        subject: this.subjectEmail,
        message: this.message,
      })
        .then(() => {})
        .catch((e) => {
          this.error = e;
          this.showAlert = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleSubject(subject) {
      this.subjectEmail = subject;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-sheet {
  bottom: 0;
  right: 0;
}
.open-btn {
  z-index: 20;
  position: fixed;
  bottom: 0;
  right: 0;
  box-shadow: 0px 8px 16px rgba(143, 168, 191, 0.3);
}
.bg-custom {
  background-color: var(--amph-light);
}
.rounded-left {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.delay {
  transition-delay: 0.8s;
}
.overflow {
  overflow: scroll;
  overflow-x: hidden;
  margin-bottom: 240px;
}
@media (min-width: 768px) {
  .overflow {
    margin-bottom: 160px;
  }
}
.v-list-item-title {
  font-size: 14px !important;
}
</style>
