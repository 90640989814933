<template>
  <svg
    width="35"
    height="35"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_39_31647)">
      <path
        d="M10.2917 2.70825H2.70833C2.11002 2.70825 1.625 3.19328 1.625 3.79159V9.20825C1.625 9.80656 2.11002 10.2916 2.70833 10.2916H10.2917C10.89 10.2916 11.375 9.80656 11.375 9.20825V3.79159C11.375 3.19328 10.89 2.70825 10.2917 2.70825Z"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.625 3.79175L6.5 7.04175L11.375 3.79175"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_39_31647">
        <rect width="13" height="13" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "Mail",
};
</script>

<style lang="scss" scoped></style>
