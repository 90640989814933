// iframeMixin.js
export const iframeMixin = {
  data() {
    return {
      isInIframe: false,
    };
  },
  methods: {
    handleHover() {
      // Handle hover event, change color, etc.
      if (window.parent !== window) {
        // The component is inside an iframe (GO), apply hover styles
        this.isInIframe = true;
      }
    },
    openDialog(type) {
      window.parent.postMessage({ action: "openDialog", type }, "*");
    },
  },
};
