{
    "error": {
      "ORDER_NOT_FOUND": "Order $order_id not found",
      "INVALID_VALIDATION_PARAM": "Invalid validation parameter for order $order_name",
      "INCOMPLETE_BODY_PARAMS": "Incomplete body parameters",
      "INCOMPLETE_COMPANY_PARAMS": "Incomplete company parameters",
      "CUSTOMIZATION_NOT_FOUND": "Customization not found",
      "MISSING_CONTACT_EMAIL": "Company $company_id does not have a contact email",
      "MISSING_CUSTOMER_EMAIL": "Order $order_id does not have a customer email",
      "ORDER_COMPANY_NOT_FOUND": "Order with ID: $order_id and company: $company_id not found",
      "MISSING_BODY_PARAMS": "Missing body parameters",
      "DUPLICATE_RETURN_ID": "A return with this ID: $order_id already exists",
      "RETURN_ORDER_NOT_FOUND": "Order $order_id not found",
      "EMPTY_RETURN_PRODUCTS": "This return has no products"
    },
    "validationError": {
      "required": "Required",
      "email": "Please enter a valid email address.",
      "phone": "Please enter a valid phone number.",
      "image": "Error format (Accepted: .png, .jpg, .jpeg) and file size < 5MB.",
      "number": "Input must be a number",
      "cjk": "CJK characters not allowed",
      "arabic": "Arabic characters not allowed",
      "comma": "Comma character is not allowed",
      "hyphen": "Hyphen character is not allowed"
    }
  }
  