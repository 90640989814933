<template>
  <a v-if="order.traking_url" :href="order.traking_url" target="_blank">
    <div class="bg-custom mx-n11">
      <div class="text-neutral80 mx-11 mb-10 py-15 font-weight-light">
        <p>
          {{ $t(`options.courier.name`) }}
          <span>
            <img
              class="courier-img"
              :src="src(order.shipping)"
              alt="Courier"
            />
          </span>
          {{ $t(`options.courier.contact`) }}
        </p>
        <p class="my-6">
          {{ $t(`info.title.tracking`) }}: {{ order.shipping_number }}
        </p>
        <v-btn class="btn btn-transparent">
          {{ $t(`options.courier.details`) }}
          <LensIcon />
        </v-btn>
      </div>
    </div>
  </a>
</template>

<script>
import { CARRIER_SOURCE } from "@/helpers/const.curriers";

import LensIcon from "@/components/icons/LensIcon.vue";

export default {
  name: "ShippingDetails",
  components: {
    LensIcon,
  },
  props: {
    order: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    src(currier) {
      return CARRIER_SOURCE(currier);
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-custom {
  background-color: var(--amph-light);
  mix-blend-mode: multiply;
  // border-top: 1px solid var(--amph-primary);
  // border-bottom: 1px solid var(--amph-primary);
}
.courier-img {
  max-height: 30px;
  max-width: 75px;
  padding: 0 0.3em;
}
</style>
