import {
  $fetchJson,
  PAYMENT_INTENT,
  ADD_SELECTED_PRODUCTS,
} from "@/services/_index.service";

export const createPaymentIntent = async (
  order_id,
  public_key,
  items,
  currency
) => {
  return $fetchJson({
    method: "POST",
    body: {
      order_id,
      public_key,
      items,
      currency,
    },
    url: PAYMENT_INTENT,
  });
};

export const addSelectedProducts = async (
  public_key,
  order_id,
  selected_products,
  intent_id
) => {
  return $fetchJson({
    method: "POST",
    body: {
      public_key,
      order_id,
      selected_products,
      intent_id,
    },
    url: ADD_SELECTED_PRODUCTS,
  });
};
