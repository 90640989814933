<template>
  <MainWrapper :company="customCompany" :customTextsObject="customText">
    <info
      :company="customCompany"
      :customTextsObject="customText"
      :prefixName="prefixName"
    />
  </MainWrapper>
</template>

<script>
import MainWrapper from "@/components/containers/MainWrapper.vue";
import Info from "@/components/forms/Info.vue";

export default {
  name: "Customizations",
  components: {
    MainWrapper,
    Info,
  },
  props: {
    company: {
      type: Object,
      default: () => {},
    },
    customTextsObject: {
      type: Object,
      default: () => {},
    },
    prefixName: {
      type: String,
      default: () => "",
    },
    setLoading: {
      type: Function,
    },
  },
  data() {
    return {
      customCompany: {},
      customText: {},
    };
  },
  mounted() {
    this.setLoading(false);
    window.addEventListener("message", (event) => {
      if (event.data.colors && event.data.colors.length > 0) {
        document.body.style.setProperty("--amph-primary", event.data.colors[0]);
      }
      this.customCompany = {
        ...this.customCompany,
        logo_url: event.data.logoUrl,
        company_img: event.data.company_img,
        company_video: event.data.company_video,
        advertising: {
          banner: {
            img: event.data.banner_img,
            url: event.data.banner_url,
          },
          urls: {
            web: event.data.web,
            contact: event.data.contact,
            help: event.data.help,
            policy: event.data.policy,
          },
          socialMedia: {
            instagram: event.data.instagram,
            tiktok: event.data.tiktok,
            twitter: event.data.twitter,
            youtube: event.data.youtube,
            facebook: event.data.facebook,
            spotify: event.data.spotify,
          },
        },
      };
      this.customText = {
        ...this.customText,
        ...event.data.text_costum,
      };
    });
  },
};
</script>
