export const INTERNAL_STATUS = {
  STATUS_NEW: "NEW",
  STATUS_NEW_HOLD: "NEW_HOLD",
  STATUS_NEW_COD: "NEW_COD",
  STATUS_EXCEPTION: "EXCEPTION",
  STATUS_EXCEPTION_ID: "EXCEPTION_ID_CARD",
  STATUS_EXCEPTION_BLOCK: "EXCEPTION_BLOCK",
  STATUS_EXCEPTION_CURRIER: "EXCEPTION_CURRIER",
  STATUS_CANCELLED: "CANCELLED",
  STATUS_CANCELLED_BLACKLISTED: "CANCELLED_BLACKLISTED",
  STATUS_PROCESSING: "PROCESSING",
  STATUS_PROCESSING_A: "PROCESSING_A",
  STATUS_PROCESSING_WAIT: "PROCESSING_WAIT",
  STATUS_PROCESSING_RESERVED_STOCK: "PROCESSING_RESERVED_STOCK",
  STATUS_SHIPPED: "SHIPPED",
  STATUS_SHIPPED_PUDO: "SHIPPED_PUDO",
  STATUS_FULFILLED: "FULFILLED",
  STATUS_FULFILLED_LOST: "FULFILLED_LOST",
  STATUS_NEW_UPSELLING: "NEW_UPSELLING",
};
