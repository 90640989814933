<template>
  <v-fade-transition>
    <MainWrapper
      v-if="!loading"
      :company="company"
      :customTextsObject="customTextsObject"
    >
      <p class="subtitle-3 text-neutral40 text-center text-md-start">
        {{
          changed
            ? $t(`instructions.subtitle_change`)
            : $t(`instructions.subtitle_return`)
        }}
      </p>
      <p v-if="identifierReturn" class="subtitle-3 pt-6 mb-3">
        {{ $t("instructions.return.identifier") }}
        <span class="font-weight-bold">
          {{ identifierReturn }}
        </span>
      </p>
      <!-- Return Received or Finished -->
      <div v-if="isReceivedOrFinished" class="bg-custom py-4 px-10 mx-n11">
        <h5>
          {{ $t("instructions.received.title") }}
        </h5>
        <p class="body-1 text-neutral40">
          {{ $t("instructions.received.desc") }}
        </p>
      </div>
      <!-- Return not approved yet -->
      <h5 v-else-if="isLabelPending" class="my-10">
        {{
          changed
            ? $t(`steps.change.step3.desc`)
            : $t(`steps.return.step3.desc`)
        }}
      </h5>
      <div v-else class="bg-custom py-4 px-10 mx-n11">
        <h5 class="ma-0 py-6">{{ $t(`instructions.title`) }}</h5>
        <ReturnInstructions
          :returnCustomInstructions="returnCustomInstructions"
          :identifierReturn="identifierReturn"
          :returnWarehouseAddress="returnWarehouseAddress"
          :pickupReference="pickupReference"
        />
        <v-btn
          v-if="amphoraLabel && returnCustomInstructions.buttonText"
          :href="url"
          type="button"
          target="_blank"
          class="btn btn-primary my-6"
        >
          {{ returnCustomInstructions.buttonText }}
          <PrintIcon />
        </v-btn>
        <v-btn
          v-if="!amphoraLabel && returnCustomInstructions.buttonText"
          type="button"
          class="btn btn-primary my-6"
          width="300px"
          @click="toQRReturn"
        >
          {{ returnCustomInstructions.buttonText }}
          <PrintIcon />
        </v-btn>
        <v-alert
          v-if="returnCustomInstructions.alert"
          color="error"
          variant="tonal"
          class="mb-6"
        >
          <p class="body-1 text-neutral80">
            {{ returnCustomInstructions.alert }}
          </p>
        </v-alert>
      </div>
      <div class="d-flex flex-column">
        <v-btn
          class="btn btn-secondary my-10 align-self-center align-self-md-start"
          @click="toTrackingRoute()"
        >
          {{ $t(`chAndRe.to_tracking`) }}
        </v-btn>
      </div>
      <h5 class="mb-10 heading-5">
        {{
          changed
            ? $t(`instructions.item_change`)
            : $t(`instructions.item_return`)
        }}
      </h5>
      <template v-for="item in order.products" :key="item.id">
        <ProductDetails
          v-if="returnedList.includes(item.id)"
          :item="item"
          unicItem
        />
      </template>
      <ChatComponent :company="company" :order="order" />
    </MainWrapper>
  </v-fade-transition>
</template>
<script>
import { mapActions } from "vuex";
import { getReturnByOrder } from "@/services/returns.service.js";
import { getInstructionsTranslation } from "@/services/orders.service.js";
import { toBase, toTracking } from "@/router/navigate";
import MainWrapper from "@/components/containers/MainWrapper.vue";
import ChatComponent from "@/components/shared/ChatComponent.vue";
import ProductDetails from "@/components/blocks/ProductDetails.vue";
import ReturnInstructions from "@/components/blocks/ReturnInstructions.vue";
import PrintIcon from "@/components/icons/PrintIcon.vue";
export default {
  name: "ReturnInfo",
  components: {
    MainWrapper,
    ProductDetails,
    ChatComponent,
    ReturnInstructions,
    PrintIcon,
  },
  props: {
    customTextsObject: {
      type: Object,
      default: () => {},
    },
    rules: {
      type: Object,
      default: () => {},
    },
    setLoading: {
      type: Function,
    },
    company: {
      type: Object,
      default: () => {},
    },
    item: {
      type: Object,
      default: () => undefined,
    },
  },
  data() {
    return {
      loading: true,
      changed: false,
      identifierReturn: undefined,
      error: null,
      order: {},
      returnedList: [],
      returnRequest: {},
      returnCustomInstructions: null,
      currierShippingReturn: null,
      typeReturn: null,
      returnWarehouseAddress: "",
      pickupReference: "",
    };
  },
  created() {
    const parsedParams = {};
    this.setLoading(true);
    this.$route.hash
      .split("&")
      .map((part) => part.replace(/#/, ""))
      .forEach((param) => {
        const parts = [
          param.substr(0, param.indexOf("=")),
          param.substr(param.indexOf("=") + 1),
        ];
        parsedParams[parts[0]] = parts[1];
      });
    this.setOrder(parsedParams);
  },
  watch: {
    "$i18n.locale"() {
      if (!this.isLabelPending) this.getInstructions();
    },
  },
  computed: {
    returnInfo() {
      return this.returnRequest.return;
    },
    amphoraLabel() {
      return this.returnInfo && this.returnInfo.amphora_label;
    },
    isLabelPending() {
      return this.amphoraLabel && this.amphoraLabel.pending;
    },
    isReceivedOrFinished() {
      return (
        (this.returnInfo && this.returnInfo.status === "RECEIVED") ||
        this.returnInfo.status === "FINISHED"
      );
    },
    getQRRoute() {
      const path = "https://api.amphoralogistics.com/prod-core-shippingLabels";
      return `${path}/returns?company_id=${this.order.id}&order_id=${this.order.company_id}`;
    },
    url() {
      let url = "";
      const regex =
        // eslint-disable-next-line no-useless-escape
        "https:\/\/[^/]+\/return-labels\/[^/]+\/[^/]+\.pdf|https:\/\/[^/]+\/uel\/llp\/[^/]+\/link\/labelAll\/[^/]+\/[^/]+\/[^/]+\?loc=[^&]+&pdr=false";
      if (!this.isLabelPending) {
        url = this.amphoraLabel.info.match(regex);
        if (Array.isArray(url)) {
          url = url[0];
        }
      }
      return url;
    },
  },
  methods: {
    ...mapActions(["findOrder"]),
    async setOrder(params) {
      const orderParams = {
        trackingNumber: params.t,
        postalCode: params.zc,
        publicKey: this.$route.params.publicKey,
        orderId: params.oi,
        products: true,
      };
      try {
        this.order = await this.findOrder(orderParams);
        await this.processReturnInfo();
        if (!this.isLabelPending) await this.getInstructions();
      } catch (e) {
        this.handleError(e);
      } finally {
        this.loading = false;
        this.setLoading(false);
      }
    },
    async processReturnInfo() {
      const { company_id: companyId, id: orderId } = this.order;
      this.returnRequest = await getReturnByOrder({
        companyId,
        orderId,
      });
      if (this.returnInfo) {
        this.currierShippingReturn = this.returnInfo.courier;
        this.typeReturn = this.returnInfo.type;
        this.updateReturnedList();
        this.changed =
          this.returnInfo.products_changes !== undefined ||
          this.returnInfo.products_changes_original !== undefined;
        this.identifierReturn =
          this.amphoraLabel?.identificator ?? this.identifierReturn;
        this.pickupReference =
          this.amphoraLabel?.pickup_reference ?? this.pickupReference;
      }
    },
    async updateReturnedList() {
      if ("products_returns" in this.returnInfo) {
        this.returnedList = Object.keys(this.returnInfo.products_returns);
      }
      if ("products_returns_original" in this.returnInfo) {
        this.returnedList = Object.keys(
          this.returnInfo.products_returns_original
        );
      }
    },
    handleError(e) {
      this.error = e;
      if (e.error === 404 || e.error === 403) {
        toBase({ publicKey: this.$route.params.publicKey, error: e.message });
      }
    },
    toTrackingRoute() {
      toTracking({
        publicKey: this.$route.params.publicKey,
        order_id: this.order.id,
        zip_code: this.order.shipping_address_zip,
        tracking: this.order.shipping_number,
      });
    },
    toQRReturn() {
      window.open(this.getQRRoute, "_blank");
    },
    async getInstructions() {
      try {
        this.returnWarehouseAddress = this.returnInfo.return_warehouse_address;
        if (this.amphoraLabel) {
          // case dropdelivery or homepickup different instructions
          if (["dropdelivery", "homepickup"].includes(this.typeReturn)) {
            this.returnCustomInstructions = await getInstructionsTranslation(
              this.$i18n.locale,
              this.typeReturn
            );
          } else {
            // if back no have instructions about currier return common instructions qr
            this.returnCustomInstructions = await getInstructionsTranslation(
              this.$i18n.locale,
              this.currierShippingReturn
            );
          }
          // if not amphora label return common instructions in qr
        } else {
          this.returnCustomInstructions = await getInstructionsTranslation(
            this.$i18n.locale,
            "qr"
          );
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.bg-custom {
  background-color: var(--amph-light);
  mix-blend-mode: multiply;
}
</style>
