import Changes from "@/views/order/Changes.vue";

import path from "@/router/const/path";
import name from "@/router/const/name";

export default [
  {
    path: path.CHANGES,
    name: name.CHANGES,
    component: Changes,
  },
  {
    path: path.SHOP_CHANGES,
    name: name.SHOP_CHANGES,
    component: Changes,
  },
];
