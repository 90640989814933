<template>
  <div>
    <div class="px-11">
      <h3 class="pb-4">{{ $t("checkout.orderHeader") }}</h3>
      <p class="subtitle-3 pb-10">
        {{ $t("checkout.orderText") }}
      </p>
    </div>
    <v-table
      class="bg-neutral80 pa-5 pa-md-14 mx-sm-11"
      style="max-height: 800px; overflow-y: auto"
    >
      <tbody>
        <tr v-for="item in selectedProducts" :key="item.id">
          <td class="ma-0 pa-0">
            <CheckoutProductDetails :item="item"></CheckoutProductDetails>
          </td>
        </tr>
      </tbody>
    </v-table>
    <v-table class="bg-neutral80 pa-5 pt-0 pa-md-14 mx-sm-11 mr-sm-11">
      <tbody>
        <tr>
          <td class="ma-0 pa-0">
            <v-row class="ma-0 pa-0" no-gutters>
              <v-col cols="8">
                <span class="subtitle-3 font-weight-black">
                  {{ $t("checkout.totalPrice") }}
                </span>
                <v-spacer></v-spacer>
                <span>{{ $t("checkout.shippment") }}</span>
              </v-col>
              <v-col class="text-end">
                <span class="subtitle-2 d-none d-md-inline font-weight-black">
                  {{ totalPrice }}
                </span>
                <span class="subtitle-3 d-md-none font-weight-black">
                  {{ totalPrice }}
                </span>
              </v-col>
            </v-row>
          </td>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>

<script>
import CheckoutProductDetails from "@/components/blocks/CheckoutProductDetails.vue";
export default {
  name: "CheckoutItemList",
  components: {
    CheckoutProductDetails,
  },
  data: () => ({}),
  props: {
    order: {
      type: Object,
      default: () => {},
    },
    selectedProducts: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    totalPrice() {
      const productsArray = Object.values(this.selectedProducts);
      if (productsArray.length === 0) return 0;

      const total = productsArray.reduce((acc, item) => {
        return (
          acc +
          (item.discount_price
            ? parseFloat(item.discount_price)
            : parseFloat(item.price))
        );
      }, 0);

      const currencyUnit = (
        productsArray[0].discount_price || productsArray[0].price
      ).split(" ")[1];

      return `${total.toFixed(2)} ${currencyUnit}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.my-table td {
  padding: 10px !important;
}
</style>
