{
  "chat": {
    "title": "Fale Conosco",
    "email": "Endereço de e-mail",
    "email_placeholder": "por exemplo, exemplo{'@'}gmail.com",
    "tracking": "Número de rastreamento",
    "tracking_placeholder": "por exemplo, #01921883712873",
    "subject": "Seleccione o motivo da sua consulta",
    "placeholder": "Digite sua mensagem",
    "inform": "Olá! Obrigado por entrar em contato conosco por meio deste chat. Não podemos atender sua solicitação imediatamente por este canal, mas entraremos em contato com você por e-mail o mais breve possível. Obrigado!",
    "send": "Enviar",
    "options": {
      "select": "Selecione uma opção",
      "op1": "Fazer uma consulta sobre o envio",
      "op2": "Notificar um problema",
      "op3": "Consulta sobre a política de devoluções",
      "op4": "Consulta sobre o processo de reembolso",
      "op5": "Solicitar o cancelamento do meu pedido",
      "op6": "Modificar o meu pedido"
    }
  },
  "chAndRe": {
    "tellUs": "Diga-nos por que você quer devolver o pedido",
    "writeHere": "Escreva aqui",
    "fulfillmentDays": "O prazo de devolução (DAYS_TO dias) deste pedido acabou.",
    "expired": "O período de devolução expirou",
    "change": "Alteração",
    "errorEmail": "Por favor, introduza o seu email",
    "invalidEmail": "Email incorreto",
    "unit": "Qtd.",
    "to_tracking": "Voltar ao rastreamento"
  },
  "app": {
    "power": "Desenvolvido por"
  },
  "add": {
    "submit": "Enviar",
    "id_card": "Adicione seu cartão de identificação",
    "success": "Sucesso!",
    "message": "Seu pedido está pronto para ser processado.",
    "invalidDNI": "Cartão de identificação inválido",
    "required": "Campo obrigatório",
    "info": "O número de identificação ou ID geralmente é impresso no próprio documento e consiste em oito dígitos seguidos de uma letra que atua como dígito de controle.",
    "country": {
      "label": "País de Entrega",
      "placeholder": "Selecionar"
    },
    "zipCode": {
      "label": "Código Postal",
      "placeholder": "Inserir o código postal"
    },
    "city": {
      "label": "Cidade",
      "placeholder": "Inserir a cidade"
    },
    "address": {
      "label": "Endereço",
      "placeholder": "Inserir o endereço"
    },
    "address2": {
      "label": "Informações adicionais sobre o endereço",
      "placeholder": "Inserir o endereço"
    },
    "name": {
      "label": "Nome",
      "placeholder": "Digite o nome"
    },
    "phone": {
      "label": "Telefone",
      "placeholder": "Digite o número de telefone"
    },
    "searchCountry": "Procurar país"
  },
  "dialog": {
    "title": "Por favor, introduza o seu email para que possamos processar o seu pedido"
  },
  "info": {
    "form": {
      "tracking": "Número do pedido ou rastreamento",
      "tracking_ej": "por exemplo, #02692F0405",
      "required_tracking": "O número do pedido é obrigatório.",
      "postal": "Código Postal",
      "postal_ej": "por exemplo, 08006",
      "required_postal": "O código postal é obrigatório.",
      "search": "Buscar",
      "tooltip_order": "Encontre seu número de rastreamento no e-mail de confirmação do pedido que você recebeu. Você também pode encontrá-lo na nota de entrega dentro da embalagem.",
      "tooltip_code": "É um código numérico que determina uma localização. Você pode facilmente encontrá-lo ligando para o correio ou fazendo uma pesquisa na internet.",
      "error": "Pedido não encontrado"
    },
    "title": {
      "need": "Encontre seu pedido",
      "tracking": "Número de rastreamento",
      "your_order": "Seu pedido",
      "your_products": "Seus produtos"
    }
  },
  "options": {
    "tracking": "Rastreamento: ",
    "order_number": "Número do pedido",
    "order_name": "Nome do pedido",
    "id": "ID do pedido",
    "return": "Você gostaria de fazer uma devolução?",
    "change": "Você gostaria de fazer uma troca?",
    "my_return": "Ver minha devolução",
    "my_change": "Ver minha troca",
    "return_progress": "Você possui uma devolução em andamento",
    "change_progress": "Você possui uma troca em andamento",
    "return_or_change": "Você gostaria de fazer uma troca ou devolução?",
    "make_return": "Fazer uma devolução",
    "make_change": "Fazer uma troca",
    "courier": {
      "name": "Seu pedido está sendo processado por",
      "contact": "serviço de transportadora de confiança",
      "details": "Detalhes da transportadora"
    },
    "noData": "Nenhuma opção disponível"
  },
  "steps": {
    "return": {
      "step1": {
        "step": "Etapa 1:",
        "desc": "Selecione o produto que deseja devolver"
      },
      "step2": {
        "step": "Passo 2:",
        "desc": "Confirme a devolução",
        "desc2": "Confirme a devolução ou selecione mais produtos"
      },
      "step3": {
        "desc": "Enviamos a solicitação de devolução. Você receberá uma resposta por e-mail em breve."
      }
    },
    "change": {
      "step1": {
        "step": "Etapa 1:",
        "desc": "Selecione o produto que deseja trocar"
      },
      "step2": {
        "step": "Passo 2:",
        "desc": "Confirme a alteração",
        "desc2": "Confirme a alteração ou selecione mais produtos"
      },
      "step3": {
        "desc": "Enviamos a solicitação de troca. Você receberá uma resposta por e-mail em breve."
      }
    }
  },
  "instructions": {
    "title": "Instruções para Devolução",
    "title_steps": "Como devolver um produto?",
    "subtitle_return": "Devolução",
    "subtitle_change": "Troca",
    "item_return": "Seus itens para devolver",
    "item_change": "Seus itens para trocar",
    "prepare": "Prepare",
    "send": "Envie o pedido de volta",
    "refund": "Reembolso",
    "information": {
      "desc_url_1": "Para fazer a devolução, siga as instruções na etiqueta. Lembre-se que se você está fazendo a devolução de um país fora da União Europeia, é essencial imprimir a fatura e incluí-la no pacote.",
      "desc_url_2": "Para devolver um item, apenas cole este rótulo no pacote e nós agendaremos sua coleta.",
      "desc_url_3": "Para completar a devolução, clique no botão 'Mais informações' e siga as instruções.",
      "desc_cex": "Para concluir a devolução, entregue o pacote em uma agência dos Correios e mencione que é um envio com 'Logística Reversa dos Correios express' para que eles possam gerar uma etiqueta de devolução. Certifique-se de que as informações na etiqueta coincidam exatamente com a referência '{ref}' do seu pedido, incluindo números e letras."
    },
    "qr": {
      "step1": "Baixe e imprima 2 cópias do código QR. Cole uma cópia fora da embalagem e inclua a outra dentro. Dessa forma, podemos identificar sua devolução e proceder ao reembolso.",
      "important": "IMPORTANTE: O QR não é um rótulo de envio; é um código de identificação interno para nosso depósito identificar o produto. Você também deve anexar o rótulo de envio fornecido pela empresa de transporte de sua escolha à sua embalagem.",
      "step2": "Leve o pacote para a empresa de transporte que for mais conveniente para você e envie para o endereço indicado no e-mail.",
      "step3": "Receba seu reembolso assim que recebermos o pacote de devolução."
    },
    "defaultInstructions": {
      "step1": "Embalage os itens a serem devolvidos com cuidado.",
      "step2": "Siga as instruções fornecidas para prosseguir com a devolução.",
      "step3": "Receba seu reembolso assim que recebermos o pacote de devolução."
    },
    "return": {
      "title": "Você já pode enviar sua devolução",
      "desc": "Para efetuar a devolução, por favor, siga as instruções que enviamos para o seu endereço de e-mail.",
      "print_label": "Imprimir etiqueta de devolução",
      "more_info": "Mais informações",
      "qr_return": "Descarregar o código QR",
      "identifier": "Identificador de retorno:"
    },
    "received": {
      "title": "Recebemos sua devolução",
      "desc": "Sua devolução foi recebida com sucesso. Para mais informações, entre em contato conosco."
    }
  },
  "product": {
    "return": {
      "title": "Você quer devolver este item?",
      "label": "Por que você quer devolvê-lo?",
      "qty": "Qual quantidade você quer devolver?",
      "reason": "Escreva aqui o motivo da devolução",
      "required": "Por favor, escreva um motivo para a devolução.",
      "date": "Devolução disponível até",
      "return": "Devolver",
      "item": "item",
      "items": "itens",
      "uploadImageDamage": "Anexe a imagem do item danificado",
      "uploadImageWrong": "Anexe a imagem do item errado",
      "uploadImageDamagePlus": "Anexe as imagens dos itens danificados",
      "uploadImageWrongPlus": "Anexe as imagens dos itens errados",
      "maxUpload": "Você pode anexar no máximo 1 imagem por produto",
      "addImage": "Adicionar",
      "notReturnable": "Este produto não é elegível para devoluções ou trocas",
      "allProductsNoReturnable": "Não é possível fazer devoluções ou trocas de nenhum dos produtos"
    },
    "change": {
      "title": "Você quer trocar este item?",
      "label": "Por que você quer trocá-lo?",
      "product": "Opções de troca",
      "reason": "Escreva aqui o motivo da troca",
      "required": "Por favor, escreva um motivo para a troca.",
      "date": "Troca disponível até",
      "noStock": "Não há stock disponível"
    },
    "confirm": "Confirmar",
    "cancel": "Cancelar",
    "options": {
      "select": "Selecione uma opção",
      "DAMAGED": "Chegou danificado",
      "TOO_BIG": "Muito grande",
      "TOO_SMALL": "Muito pequeno",
      "NOT_EXPECTED": "Não é o que eu esperava",
      "NOT_ORDERED": "Não é o que eu pedi",
      "OTHER": "Outro",
      "eg": "ex: não atende às minhas expectativas"
    }
  },
  "tracking": {
    "need": "Adicionar informação",
    "resume": {
      "order": {
        "title": "Endereço de envio "
      }
    },
    "messages": {
      "processing": "Estado: em preparação",
      "shipped": "Estado: enviado",
      "out": "Estado: em trânsito",
      "ful": "Estado: entregue",
      "refused": "Estado: recusado",
      "info": "Para enviar este pedido, precisamos de informações adicionais.",
      "wrongAddress": "Opa, parece que há um erro em alguns campos.",
      "verifyField": "Por favor, verifique se todas as informações estão corretas."
    },
    "progress": {
      "processing": "Estamos preparando seu pedido o mais rápido possível",
      "shipped": "Seu pedido já saiu do nosso armazém",
      "delivery": "Quase lá, seu pedido está em trânsito",
      "delivered": "Já entregamos o seu pedido",
      "refused": "Tentamos entregar seu pacote, mas ele foi recusado. Por favor, entre em contato conosco para mais informações."
    }
  },
  "footer": {
    "contact": "Contato",
    "help": "Ajuda",
    "policy": "Política de privacidade"
  },
  "checkout": {
    "checkout": "Checkout",
    "paymentDetails": "Detalhes do pagamento",
    "paymentDetailsText": "Você está a apenas um passo de concluir sua compra! Por favor, revise os detalhes do seu pedido e prossiga com o pagamento seguro.",
    "customerName": "Nome",
    "customerSurnames": "Sobrenome",
    "customerAdress": "Endereço de casa",
    "zipCode": "Código postal",
    "city": "Cidade",
    "orderHeader": "Seu pedido",
    "orderText": "Os novos produtos adicionados ao seu carrinho serão enviados juntamente com o seu pedido anterior. Ou seja, você receberá tudo em um único pacote.",
    "quantity": "unidades",
    "exclusivePrice": "Preço exclusivo:",
    "totalPrice": "Subtotal",
    "shippment": "Envio incluído no pedido",
    "thanks": "Muito obrigado!",
    "successText": "Você aproveitou os descontos exclusivos para você. A seguir, procederemos à preparação do seu novo pedido. Lembre-se de que receberá tudo na data prevista inicialmente. Aproveite os seus novos produtos com desconto!",
    "payNow": "Pagar agora",
    "paymentSuccess": "Seu pagamento foi concluído",
    "paymentProcessing": "Seu pagamento está sendo processado",
    "paymentLabel": "Os seus dados de pagamento não são armazenados nos nossos servidores. São apenas usados para processar o seu pagamento com segurança."
  },
  "upselling": {
    "discount": {
      "text": "COMPRE COM DESCONTOS E RECEBA EM UM ÚNICO PEDIDO!"
    },
    "days": [
      "Domingo",
      "Segunda-feira",
      "Terça-feira",
      "Quarta-feira",
      "Quinta-feira",
      "Sexta-feira",
      "Sábado"
    ],
    "months": [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro"
    ],
    "finished": {
      "condolences": "Desculpe, esta oferta já terminou.",
      "condolencesBody1": "Lamentamos que você não tenha conseguido aproveitar esta oferta a tempo. Não se preocupe! Em",
      "condolencesBody2": "estamos sempre trabalhando para trazer novas e empolgantes promoções.",
      "condolencesBody3": "Enquanto isso, convidamos você a explorar nossos outros produtos e descobertas recentes que temos certeza de que você vai adorar.",
      "titleTracking": "Verifique o status do seu pedido",
      "trackingButton": "Rastreie aqui"
    },
    "recommendedProducts": {
      "title": "Recomendado para você",
      "subtitleUpselling": "Acreditamos que estes produtos combinam com suas compras. Adicione ao carrinho agora e receba no mesmo pedido.",
      "subtitleUpsellingEnd": "Achamos que você pode se interessar por esses produtos. Não perca a chance de descobrir estas jóias escondidas que podem se tornar seus novos favoritos.",
      "goToShop": "Ir para a loja",
      "addToCart": "Adicionar ao carrinho",
      "modifyCart": "Modificar o carrinho"
    },
    "upsellingProducts": {
      "title": ["Compre com", "desconto", "e receba no mesmo pedido"],
      "orderNumber": "Número do pedido",
      "timeText": "OFERTAS EM CONTAGEM REGRESSIVA!",
      "continue": "Continue lendo para ver o status do seu pedido"
    },
    "paySummary": {
      "title1": "Seu carrinho (",
      "title2": "novos itens )",
      "deleteCart": "Remover do carrinho",
      "addMoreProducts": "Adicionar mais produtos",
      "endPurchase": "Finalizar compra",
      "price": "Preço:"
    }
  },
  "iframe": {
    "addBanner": "Adicionar um banner (opcional)"
  },
  "exceptionsCarrier": {
    "CARRIER_WRONG_POSTAL_CODE": {
      "title": "Código postal inválido",
      "subtitle": "O código postal inserido está incorreto ou não corresponde à cidade especificada."
    },
    "CARRIER_ADDRESS_TOO_LONG": {
      "title": "Endereço muito longo",
      "subtitle": "O endereço inserido é muito longo para gerar a etiqueta de envio."
    },
    "CARRIER_PHONE_REQUIRED": {
      "title": "Número de telefone necessário",
      "subtitle": "Não é possível gerar a etiqueta de envio porque o número de telefone está faltando."
    },
    "CARRIER_PHONE_TOO_LONG": {
      "title": "Número de telefone muito longo",
      "subtitle": "O número de telefone inserido é muito longo para gerar a etiqueta de envio."
    },
    "CARRIER_PHONE_TOO_SHORT": {
      "title": "Número de telefone muito curto",
      "subtitle": "O número de telefone inserido está incorreto; é muito curto para gerar a etiqueta de envio."
    },
    "CARRIER_ADDRESS_TOO_SHORT": {
      "title": "Endereço muito curto",
      "subtitle": "O endereço inserido é muito curto para gerar a etiqueta de envio."
    },
    "carrierMessage": "O transportador indica a seguinte mensagem:"
  }
}
