<template>
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="btn-icon"
  >
    <g clip-path="url(#clip0_606_32857)">
      <path
        d="M5.83333 2.35303H3.16667C2.79848 2.35303 2.5 2.6515 2.5 3.01969V5.68636C2.5 6.05455 2.79848 6.35303 3.16667 6.35303H5.83333C6.20152 6.35303 6.5 6.05455 6.5 5.68636V3.01969C6.5 2.6515 6.20152 2.35303 5.83333 2.35303Z"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.8333 10.353H11.1667C10.7985 10.353 10.5 10.6515 10.5 11.0197V13.6864C10.5 14.0546 10.7985 14.353 11.1667 14.353H13.8333C14.2015 14.353 14.5 14.0546 14.5 13.6864V11.0197C14.5 10.6515 14.2015 10.353 13.8333 10.353Z"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.1665 2.35303L9.1665 4.35303M14.4998 7.68636V5.68636C14.4998 5.33274 14.3594 4.9936 14.1093 4.74355C13.8593 4.4935 13.5201 4.35303 13.1665 4.35303H9.1665L11.1665 6.35303L14.4998 7.68636Z"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.83333 14.3529L7.83333 12.3529M2.5 9.01953V11.0195C2.5 11.3732 2.64048 11.7123 2.89052 11.9623C3.14057 12.2124 3.47971 12.3529 3.83333 12.3529H7.83333L5.83333 10.3529L2.5 9.01953Z"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_606_32857">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0.5 0.353027)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "Return",
};
</script>

<style lang="scss" scoped></style>
