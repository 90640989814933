<template>
  <div
    class="d-flex bg-neutral80 w-100 justify-center pa-7 rounded-t-xl"
    style="position: sticky; bottom: 0; z-index: 999"
  >
    <v-btn
      type="button"
      class="btn btn-tertiary-white ma-0 pa-0 pr-10"
      variant="plain"
      :ripple="false"
      @click="cancel"
    >
      {{ $t(`product.cancel`) }}
    </v-btn>
    <v-btn
      type="button"
      class="btn btn-secondary btn-large"
      @click="submit"
      :loading="buttonLoading"
    >
      {{ $t(`product.return.return`) }} {{ returnProducts.length }}
      {{ itemLength }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "ReturnButtons",
  props: {
    cancel: {
      type: Function,
      required: true,
    },
    submit: {
      type: Function,
      required: true,
    },
    buttonLoading: {
      type: Boolean,
      default: false,
    },
    returnProducts: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    itemLength() {
      if (this.returnProducts.length === 1) {
        return this.$t(`product.return.item`);
      } else {
        return this.$t(`product.return.items`);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
