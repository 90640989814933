{
  "chat": {
    "title": "Talk to Us",
    "email": "E-mail address",
    "email_placeholder": "e.g. example{'@'}gmail.com",
    "tracking": "Tracking number",
    "tracking_placeholder": "e.g. #01921883712873",
    "subject": "Select the reason for your inquiry",
    "placeholder": "Type your message",
    "inform": "Hi! Thank you for contacting us through this chat. We cannot address your request immediately through this channel, but we will get back to you via email as soon as possible. Thanks!",
    "send": "Send",
    "options": {
      "select": "Select an option",
      "op1": "Make an inquiry about the shipment",
      "op2": "Report an incident",
      "op3": "Return Policy Inquiry",
      "op4": "Refund Process Inquiry",
      "op5": "Request order cancellation",
      "op6": "Modify my order"
    }
  },
  "chAndRe": {
    "tellUs": "Tell us why do you want to return the order",
    "writeHere": "Write here",
    "fulfillmentDays": "The return time for this order (DAYS_TO days) is over.",
    "expired": "The return period has expired",
    "change": "Change",
    "errorEmail": "Please enter your email address",
    "invalidEmail": "Invalid email",
    "unit": "Qty.",
    "to_tracking": "Back to tracking"
  },
  "app": {
    "power": "Powered by"
  },
  "add": {
    "submit": "Submit",
    "id_card": "Add your id card",
    "success": "Success!",
    "message": "Your order is ready to process.",
    "invalidDNI": "Invalid ID card",
    "required": "Required field",
    "info": "The identification number or ID is usually printed on the document itself and consists of eight digits followed by a letter that acts as a control digit.",
    "country": {
      "label": "Shipping Country",
      "placeholder": "Select"
    },
    "zipCode": {
      "label": "Zip Code",
      "placeholder": "Enter the zip code"
    },
    "city": {
      "label": "City",
      "placeholder": "Enter the city"
    },
    "address": {
      "label": "Address",
      "placeholder": "Enter the address"
    },
    "address2": {
      "label": "Address extra information",
      "placeholder": "Enter the address"
    },
    "name": {
      "label": "Name",
      "placeholder": "Enter the name"
    },
    "phone": {
      "label": "Phone",
      "placeholder": "Enter the phone number"
    },
    "searchCountry": "Search country"
  },
  "dialog": {
    "title": "Please enter your email address so that we can process your request"
  },
  "info": {
    "form": {
      "tracking": "Order number or tracking",
      "tracking_ej": "e.g. #02692F0405",
      "required_tracking": "Order number is required.",
      "postal": "Postal Code",
      "postal_ej": "e.g. 08006",
      "required_postal": "Postal code is required.",
      "search": "Search",
      "tooltip_order": "Find your tracking number in the order confirmation email you received. You can also find it on the delivery note inside the package.",
      "tooltip_code": "It is a numerical code that determines a location. You can easily find it by calling the post office or doing an internet search.",
      "error": "Order not found"
    },
    "title": {
      "need": "Find your order",
      "tracking": "Tracking number",
      "your_order": "Your order",
      "your_products": "Your products"
    }
  },
  "options": {
    "tracking": "Tracking: ",
    "order_number": "Order number",
    "order_name": "Order name",
    "id": "Order ID",
    "return": "Would you like to make a return?",
    "change": "Would you like to make an exchange?",
    "my_return": "View my return",
    "my_change": "View my exchange",
    "return_progress": "You have a return in progress",
    "change_progress": "You have an exchange in progress",
    "return_or_change": "Would you like to make an exchange or return?",
    "make_return": "Make a return",
    "make_change": "Make an exchange",
    "courier": {
      "name": "Your order is processed by",
      "contact": "trusted carrier service",
      "details": "Courier details"
    },
    "noData": "No options available"
  },
  "steps": {
    "return": {
      "step1": {
        "step": "Step 1:",
        "desc": "Select the product you want to return"
      },
      "step2": {
        "step": "Step 2:",
        "desc": "Confirm the return",
        "desc2": "Confirm the return or select more products"
      },
      "step3": {
        "desc": "We have sent the return request. You will receive a response by email shortly."
      }
    },
    "change": {
      "step1": {
        "step": "Step 1:",
        "desc": "Select the product you want to exchange"
      },
      "step2": {
        "step": "Step 2:",
        "desc": "Confirm the exchange",
        "desc2": "Confirm the exchange or select more products"
      },
      "step3": {
        "desc": "We have sent the exchange request. You will receive a response by email shortly."
      }
    }
  },
  "instructions": {
    "title": "Instructions for Return",
    "title_steps": "How to return a product?",
    "subtitle_return": "Return",
    "subtitle_change": "Exchange",
    "item_return": "Your items to return",
    "item_change": "Your items to exchange",
    "prepare": "Prepare",
    "send": "Send back the order",
    "refund": "Refund",
    "information": {
      "desc_url_1": "To process the return, follow the instructions provided on the label. Please note that if you are returning from a non-European Union country, it is essential to print the invoice and include it in the package.",
      "desc_url_2": "To return an item, simply affix this label to the package, and we will schedule its pickup.",
      "desc_url_3": "To complete the return, click on the 'More Information' button and follow the instructions.",
      "desc_cex": "To finalize the return, drop off the package at a post office and mention that it is a 'Logística Inversa de Correos express' (Correos express Return Logistics) shipment so they can generate a return label. Ensure that the information on the label matches your order reference '{ref}' exactly, including numbers and letters."
    },
    "qr": {
      "step1": "Download and print 2 copies of the QR code. Place one copy outside the package and include the other inside. This way, we can identify your return and proceed with the refund.",
      "important": "IMPORTANT: The QR is not a shipping label; it is an internal identification code for our warehouse to identify the product. You must also attach the shipping label provided by the transport company of your choice to your package.",
      "step2": "Take the package to the transport company that is most convenient for you and send it to the address indicated in the email.",
      "step3": "Receive your refund once we have received the return package."
    },
    "defaultInstructions": {
      "step1": "Pack the items for return carefully.",
      "step2": "Follow the provided instructions to proceed with the return.",
      "step3": "Receive your refund once we have received the return package."
    },
    "return": {
      "title": "You can now send your return",
      "desc": "To proceed with the return, please follow the instructions we have sent to your email address.",
      "print_label": "Print the return label",
      "more_info": "More information",
      "qr_return": "Download QR code",
      "identifier": "Return reference:"
    },
    "received": {
      "title": "We have received your return",
      "desc": "Your return has been received successfully. For more information, please contact us."
    }
  },
  "product": {
    "return": {
      "title": "Do you want to return this item?",
      "label": "Why do you want to return it?",
      "qty": "How many do you want to return?",
      "reason": "Write here the reason for the return",
      "required": "Please write a reason for the return.",
      "date": "Return available until",
      "return": "Return",
      "item": "item",
      "items": "items",
      "uploadImageDamage": "Attach the image of the damaged item",
      "uploadImageWrong": "Attach the image of the wrong item",
      "uploadImageDamagePlus": "Attach the images of the damaged items",
      "uploadImageWrongPlus": "Attach the images of the wrong items",
      "maxUpload": "You can only attach a maximum of 1 image per product",
      "addImage": "Add",
      "notReturnable": "This product is not eligible for returns or exchanges",
      "allProductsNoReturnable": "Returns or exchanges cannot be made for any of the products"
    },
    "change": {
      "title": "Do you want to exchange this item?",
      "label": "Why do you want to exchange it?",
      "product": "Exchange options",
      "reason": "Write here the reason for the exchange",
      "required": "Please write a reason for the exchange.",
      "date": "Change available until",
      "noStock": "No stock available"
    },
    "confirm": "Confirm",
    "cancel": "Cancel",
    "options": {
      "select": "Select an option",
      "DAMAGED": "Arrived damaged",
      "TOO_BIG": "Too big",
      "TOO_SMALL": "Too small",
      "NOT_EXPECTED": "Not what I expected",
      "NOT_ORDERED": "Not what I ordered",
      "OTHER": "Other",
      "eg": "e.g. it doesn't meet my expectations"
    }
  },
  "tracking": {
    "need": "Add information",
    "resume": {
      "order": {
        "title": "Shipping Address"
      }
    },
    "messages": {
      "processing": "Status: processing",
      "shipped": "Status: shipped",
      "out": "Status: in delivery",
      "ful": "Status: delivered",
      "refused": "Status: refused",
      "info": "To fulfill this order, we need additional information.",
      "wrongAddress": "Oops, it looks like there is an error in some fields.",
      "verifyField": "Please verify that all the information is correct."
    },
    "progress": {
      "processing": "We are processing your order as quickly as possible",
      "shipped": "Your order has already left our warehouse",
      "delivery": "Almost there, your order is out for delivery",
      "delivered": "We have delivered your order",
      "refused": "We attempted to deliver your package, but it was refused. Please contact us for more information."
    }
  },
  "footer": {
    "contact": "Contact",
    "help": "Help",
    "policy": "Privacy Policy"
  },
  "checkout": {
    "checkout": "Checkout",
    "paymentDetails": "Payment Details",
    "paymentDetailsText": "You're just one step away from completing your purchase! Please review the details of your order and proceed with the secure payment.",
    "customerName": "First Name",
    "customerSurnames": "Last Name",
    "customerAdress": "Home Address",
    "zipCode": "Zip Code",
    "city": "City",
    "orderHeader": "Your Order",
    "orderText": "The new products added to your cart will be shipped together with your previous order. That is, you will receive everything in one package.",
    "quantity": "unit",
    "exclusivePrice": "Exclusive Price:",
    "totalPrice": "Subtotal",
    "shippment": "Shipping included in the order",
    "thanks": "Thank you very much!",
    "successText": "You have taken advantage of the exclusive discounts for you. Next, we will proceed to prepare your new order. Remember that you will receive everything on the initially expected date. Enjoy your new discounted products!",
    "payNow": "Pay now",
    "paymentSuccess": "Your payment has been completed",
    "paymentProcessing": "Your payment is being processed",
    "paymentLabel": "Your payment information is not stored on our servers. It is only used to securely process your payment."
  },
  "upselling": {
    "discount": {
      "text": "SHOP WITH DISCOUNTS AND RECEIVE IT IN A SINGLE ORDER!"
    },
    "days": [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
    "months": [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    "finished": {
      "condolences": "Sorry, this offer has already ended.",
      "condolencesBody1": "We're sorry you couldn't take advantage of this offer in time. Don't worry! At",
      "condolencesBody2": "we are always working to bring you new and exciting promotions.",
      "condolencesBody3": "In the meantime, we invite you to explore our other products and recent discoveries that we're sure you'll love.",
      "titleTracking": "Check the status of your order",
      "trackingButton": "Track it here"
    },
    "recommendedProducts": {
      "title": "Recommended for you",
      "subtitleUpselling": "We believe these products match your purchases. Add to cart now and receive it in the same order.",
      "subtitleUpsellingEnd": "We think you might be interested in these products. Don't miss the chance to discover these hidden gems that might become your new favorites.",
      "goToShop": "Go to the shop",
      "addToCart": "Add to cart",
      "modifyCart": "Modify the cart"
    },
    "upsellingProducts": {
      "title": ["Shop with", "discount", "and receive in the same order"],
      "orderNumber": "Order Number",
      "timeText": "COUNTDOWN OFFERS!",
      "continue": "Continue reading to see the status of your order"
    },
    "paySummary": {
      "title1": "Your cart (",
      "title2": "new items )",
      "deleteCart": "Remove from cart",
      "addMoreProducts": "Add more products",
      "endPurchase": "Complete purchase",
      "price": "Price:"
    }
  },
  "iframe": {
    "addBanner": "Add a banner (optional)"
  },
  "exceptionsCarrier": {
    "CARRIER_WRONG_POSTAL_CODE": {
      "title": "Invalid postal code",
      "subtitle": "The entered postal code is incorrect or does not match the specified city."
    },
    "CARRIER_ADDRESS_TOO_LONG": {
      "title": "Address too long",
      "subtitle": "The entered address is too long to generate the shipping label."
    },
    "CARRIER_PHONE_REQUIRED": {
      "title": "Phone number required",
      "subtitle": "The shipping label cannot be generated because phone number is missing."
    },
    "CARRIER_PHONE_TOO_LONG": {
      "title": "Phone number too long",
      "subtitle": "The entered phone number is too long to generate the shipping label."
    },
    "CARRIER_PHONE_TOO_SHORT": {
      "title": "Phone number too short",
      "subtitle": "The entered phone number is incorrect; it is too short to generate the shipping label."
    },
    "CARRIER_ADDRESS_TOO_SHORT": {
      "title": "Address too short",
      "subtitle": "The entered address is too short to generate the shipping label."
    },
    "carrierMessage": "The carrier indicates the following message:"
  }
}
