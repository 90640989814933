import AddInfo from "@/views/order/AddInfo.vue";

import path from "@/router/const/path";
import name from "@/router/const/name";

export default [
  {
    path: path.DATA,
    name: name.DATA,
    component: AddInfo,
  },
  {
    path: path.SHOP_DATA,
    name: name.SHOP_DATA,
    component: AddInfo,
  },
];
