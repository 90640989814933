<template>
  <v-fade-transition>
    <CheckoutWrapper
      v-if="!loading"
      :company="company"
      :selectedProducts="selectedProducts"
      :customTextsObject="customTextsObject"
      :order="order"
      :payment="paymentSuccess"
    >
      <div v-if="!paymentSuccess">
        <PaymentDetails :order="order" />
        <v-divider
          color="neutral80"
          class="border-opacity-80 my-16"
        ></v-divider>
        <div>
          <div
            class="d-flex justify-center align-center"
            style="height: 100px"
            v-if="paymentElementLoading"
          >
            <v-progress-circular
              indeterminate
              color="neutral80"
              :size="100"
              :width="5"
            ></v-progress-circular>
          </div>
          <div id="payment-element"></div>
          <p class="text-neutral40 footnote pt-4">
            {{ $t(`checkout.paymentLabel`) }}
          </p>
        </div>
        <v-divider color="neutral80" class="border-opacity-80 my-8"></v-divider>

        <div class="d-flex justify-end">
          <v-btn
            :disabled="disabledPay"
            type="button"
            class="btn btn-primary mb-10"
            width="290px"
            @click="confirmPayment"
            :loading="loadingBtn"
          >
            {{ $t(`checkout.payNow`) }}
          </v-btn>
        </div>
      </div>
      <div v-else class="pb-6">
        <h3 class="pb-4">{{ $t(`checkout.thanks`) }}</h3>
        <!-- <h6 class="pb-2">
          {{
            paymentSuccess == 1
              ? $t(`checkout.paymentSuccess`)
              : $t(`checkout.paymentProcessing`)
          }}
        </h6> -->
        <span class="subtitle-3">
          {{ $t(`checkout.successText`) }}
        </span>
      </div>

      <CustomAlert v-if="errorMessage" error :text="errorMessage">
        {{ errorMessage }}
      </CustomAlert>
    </CheckoutWrapper>
  </v-fade-transition>
</template>

<script>
import CheckoutWrapper from "@/components/containers/CheckoutWrapper.vue";
import PaymentDetails from "@/components/blocks/PaymentDetails.vue";
import { loadStripe } from "@stripe/stripe-js";
import {
  createPaymentIntent,
  addSelectedProducts,
} from "@/services/checkout.services";
import CustomAlert from "@/components/blocks/CustomAlert.vue";

export default {
  name: "Checkout",
  components: {
    CheckoutWrapper,
    PaymentDetails,
    CustomAlert,
  },
  props: {
    customTextsObject: {
      type: Object,
      default: () => {},
    },
    setLoading: {
      type: Function,
    },
    company: {
      type: Object,
      default: () => undefined,
    },
  },
  data() {
    return {
      loading: true,
      loadingBtn: false,
      disabledPay: true,
      order: {},
      error: null,
      selectedProducts: [],
      stripe: null,
      elements: null,
      paymentElement: null,
      errorMessage: null,
      paymentSuccess: null,
      paymentElementLoading: true,
    };
  },
  async mounted() {
    this.loading = true;
    this.setLoading(true);
    // Public test key to create stripe elements
    this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_API_KEY);
    await this.checkStatus();

    this.order = JSON.parse(localStorage.getItem("upsellingOrder"));
    this.selectedProducts = JSON.parse(
      localStorage.getItem("selectedProducts")
    );
    this.setLoading(false);
    this.loading = false;
  },
  methods: {
    async confirmPayment() {
      this.errorMessage = null;
      this.setLoading(true);
      this.loading = true;
      this.loadingBtn = true;
      const elements = this.elements;
      const { paymentIntent, error } = await this.stripe.confirmPayment({
        elements,
        redirect: "if_required",
        confirmParams: {
          return_url: window.location.href,
          payment_method_data: {
            billing_details: {
              address: {
                country: this.order.shipping_address_country_code,
              },
            },
          },
        },
      });
      if (error) {
        if (error.type === "card_error" || error.type === "validation_error") {
          this.errorMessage = error.message;
        } else {
          this.errorMessage = "An unexpected error occurred.";
        }
      } else {
        try {

          const { error } = await addSelectedProducts(
            this.$route.params.publicKey,
            this.order.id,
            this.selectedProducts,
            paymentIntent.id
          );
          if (error) {
            this.errorMessage = "An unexpected error occurred.";
          } else {
            this.paymentSuccess = true;
          }
        } catch (e) {
          this.errorMessage = "An unexpected error occurred.";
        }
      }
      this.loadingBtn = false;
      this.setLoading(false);
      this.loading = false;
    },
    async initializeStripe() {
      this.paymentElementLoading = true;
      const res = await createPaymentIntent(
        this.order.id,
        this.$route.params.publicKey,
        this.selectedProducts,
        this.order.currency
      );

      const clientSecret = res.clientSecret;

      this.elements = this.stripe.elements({
        clientSecret: clientSecret,
      });

      this.paymentElement = this.elements.create("payment", {
        defaultValues: {
          billingDetails: {
            address: {
              country: this.order.shipping_address_country_code,
            },
          },
        },
        fields: {
          billingDetails: {
            address: {
              country: "never",
            },
          },
        },
        terms: {
          card: "never",
          paypal: "never",
        },
      });

      this.paymentElement.on("change", (event) => {
        if (event.complete) {
          // All fields in the Element are filled out correctly
          this.disabledPay = false;
        } else {
          this.disabledPay = true;
        }
        if (event.error) {
          this.disabledPay = true;
        }
      });
      this.paymentElement.mount("#payment-element");
      this.paymentElementLoading = false;
    },
    async checkStatus() {
      const clientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
      );

      if (!clientSecret) {
        return;
      }

      const { paymentIntent } = await this.stripe.retrievePaymentIntent(
        clientSecret
      );

      switch (paymentIntent.status) {
        case "succeeded":
          this.paymentSuccess = 1;
          break;
        case "processing":
          this.paymentSuccess = 2;
          break;
        case "requires_payment_method":
          this.errorMessage =
            "Your payment was not successful, please try again.";
          break;
        default:
          this.errorMessage = "Something went wrong.";
          break;
      }
    },
  },
  watch: {
    async loading(newValue) {
      if (!newValue && !this.paymentSuccess) {
        this.$nextTick(() => {
          this.initializeStripe();
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
