import { $fetchJson, SEND_CLIENT_EMAIL } from "@/services/_index.service";

export const sendClientEmail = async ({ companyId, id, subject, message }) => {
  return $fetchJson({
    method: "POST",
    body: {
      company_id: companyId,
      id: id,
      subject: subject,
      message: message,
    },
    url: SEND_CLIENT_EMAIL,
  });
};
