<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="btn-icon"
  >
    <g clip-path="url(#clip0_606_27491)">
      <path
        d="M9.33333 14.8929L2 7.55957"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2 10.2262V7.55957H4.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.3335 10.2262H14.0002V7.55957"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.6665 2.89307L13.9998 10.2264"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_606_27491">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.893066)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "Change",
};
</script>

<style lang="scss" scoped></style>
