// Styles
import "vuetify/styles";
// import "@mdi/font/css/materialdesignicons.css";

// Vuetify
import { createVuetify } from "vuetify";
import * as components from "vuetify/lib/components";
import * as directives from "vuetify/lib/directives";

const customTheme = {
  dark: false,
  colors: {
    primary: "#FFCA43",
    secondary: "#C5BBE3",
    // NEUTRALS
    neutral00: "#fff",
    neutral05: "#f2f2f2",
    neutral10: "#E6E6E6",
    neutral20: "#CDCDCD",
    neutral40: "#9A9A9A",
    neutral60: "#686868",
    neutral80: "#282828",
    // FEEDBACK
    success: "#459A33",
    successMedium: "#8DC881",
    successLight: "#E3F2E0",

    warning: "#C9A81C",
    warningMedium: "#E2CA64",
    warningLight: "#F8F3D8",

    info: "#1A73E8",
    infoMedium: "#ACE0F6",
    infoLight: "#EEF9FD",

    error: "#C22020",
    errorMedium: "#E29583",
    errorLight: "#F7E6E1",

    middle: "#FEA217",
    middleMedium: "#FFC266",
    middleLight: "#FFE5BD",

    borderColor: "#282828",
  },
};

export default createVuetify({
  components,
  directives,
  theme: {
    options: {
      customProperties: true,
      variations: false,
    },
    isDark: false,
    themes: {
      light: customTheme,
    },
  },
});
