<template>
  <v-card flat class="rounded-lg bg-neutral80" width="auto">
    <slot name="badge"></slot>
    <v-row no-gutters align="center">
      <v-col class="ma-0 pa-0" cols="auto">
        <v-avatar
          :size="mobile ? 160 : 110"
          rounded="0"
          class="elevation-soft rounded-lg"
          color="neutral00"
        >
          <v-img :src="logoImage" cover />
        </v-avatar>
      </v-col>
      <v-col class="ml-2 d-flex flex-column">
        <v-row no-gutters>
          <v-col cols="12" md="6">
            <p class="body-1 font-weight-medium text-no-wrap">
              {{ item.title }}
            </p>
            <p class="caption-1 pt-0">
              {{ item.variant_title }}
            </p>
            <p v-if="item.discount_price" class="caption-1 pt-10 pt-lg-10">
              -{{percentage}}%
            </p>
          </v-col>
          <v-col>
            <p
              v-if="item.discount_price"
              :class="mobile ? '' : 'text-end'"
              style="font-size: 13px"
            >
              <del class="text-neutral00">{{ item.price }}</del>
            </p>
            <p
              v-else
              :class="mobile ? 'pt-15' : 'text-end'"
              style="font-size: 19px"
            >
              <span class="text-neutral00">{{ item.price }}</span>
            </p>
            <p :class="mobile ? '' : 'text-end'" style="font-size: 19px">
              <span class="text-neutral00 bg-error">
                {{ item.discount_price }}
              </span>
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-divider color="neutral00" class="border-opacity-100 my-8"></v-divider>
  </v-card>
</template>

<script>
import { useDisplay } from "vuetify";

export default {
  name: "CheckoutProductDetails",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { mobile } = useDisplay();
    return { mobile };
  },
  computed: {
    logoImage() {
      return this.item.img;
    },
    title() {
      return this.item.title;
    },
    quantity() {
      return this.item.quantity || "-";
    },
    percentage() {
      return parseInt(
        (1 -
          +this.item.discount_price.split(" ")[0] /
            +this.item.price.split(" ")[0]) *
          100
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
