<template>
  <v-alert
    icon="mdi-alert-circle-outline"
    type="error"
    variant="tonal"
    :text="text"
    :class="
      error
        ? 'elevation-soft rounded-lg mb-5 pa-3 mt-n5'
        : 'hidden mb-5 pa-3 mt-n5'
    "
  >
  </v-alert>
</template>

<script>
export default {
  data() {
    return {
      alert: true,
    };
  },
  props: {
    error: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: () => "",
    },
  },
};
</script>

<style lang="scss" scoped>
.hidden {
  visibility: hidden;
}
:deep .v-alert__content {
  padding-top: 3px !important;
}
</style>
