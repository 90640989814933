/* eslint-disable no-unused-vars */
import { createRouter, createWebHistory } from "vue-router";

// 1. entry
import base from "@/router/routes/base.routes";
// 2. extra
import goDesignView from "@/router/routes/goDesignView.routes";
import mockTrackingPage from "@/router/routes/mockTrackingPage.routes";
import PageNotFound from "@/views/helpers/PageNotFound";
// 3. order routes
import tracking from "@/router/routes/order/tracking.routes";
import changes from "@/router/routes/order/changes.routes";
import returns from "@/router/routes/order/returns.routes";
import returnInfo from "@/router/routes/order/returnInfo.routes";
import addIdCard from "@/router/routes/order/addIdCard.routes";
import upselling from "@/router/routes/order/upselling.routes";
import checkout from "@/router/routes/order/checkout.routes";

const routes = [
  ...base,
  ...tracking,
  ...changes,
  ...returns,
  ...returnInfo,
  ...addIdCard,
  ...goDesignView,
  ...mockTrackingPage,
  ...upselling,
  ...checkout,

  { path: "/:catchAll(.*)", name: "Not Found", component: PageNotFound },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
