<template>
  <v-row justify="end">
    <v-col cols="auto">
      <v-sheet
        class="rounded-circle"
        color="info"
        :height="20"
        :width="20"
        style="margin-top: -22px; margin-right: -40px"
      >
        <p class="number">
          <slot></slot>
        </p>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.number {
  font-family: "Raleway";
  line-height: 1;
  text-align: center;
  color: rgb(var(--v-theme-neutral00));
}
</style>
