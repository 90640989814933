<template>
  <v-dialog v-model="show" width="800" persistent scroll-strategy="none">
    <v-card class="rounded-xl pa-3 pa-lg-8">
      <h5 class="my-8 text-center">
        {{ $t(`dialog.title`) }}
      </h5>
      <InputField
        :placeholder="$t(`chat.email_placeholder`)"
        :rules="emailRules"
        :value="customerEmail"
        @input="saveEmail"
      >
        <p>{{ $t(`chat.email`) }}:</p>
      </InputField>
      <div class="d-flex justify-center">
        <v-btn
          id="submit"
          :disabled="isDisabled"
          class="btn btn-primary mb-10"
          type="submit"
          v-on:click="show = false"
          @click="submitSelectedValue"
        >
          {{ $t(`product.confirm`) }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/inputs/InputField.vue";

export default {
  name: "Modal",
  components: {
    InputField,
  },
  data() {
    return {
      show: false,
      customerEmail: null,
      emailRegex:
        /^([a-zA-Z0-9_\\.\\-])+@(([a-zA-Z0-9\\-])+\.)+([a-zA-Z0-9]{2,4})+$/,
      emailRules: [
        (value) => {
          if (value) return true;
          return this.$t(`chAndRe.errorEmail`);
        },
        (value) => {
          if (!this.emailRegex.test(value)) {
            return this.$t(`chAndRe.invalidEmail`);
          } else {
            return true;
          }
        },
      ],
    };
  },
  computed: {
    isDisabled() {
      return !this.emailRules.every(
        (rule) => rule(this.customerEmail) === true
      );
    },
  },
  methods: {
    submitSelectedValue() {
      this.$emit("customerEmail", this.customerEmail);
    },
    saveEmail(value) {
      this.customerEmail = value;
    },
  },
  emits: ["value", "customerEmail"], // Declare the custom event listener
};
</script>

<style lang="scss" scoped>
.v-dialog > .v-overlay__content > .v-card {
  box-shadow: 0px 34px 100px rgba(143, 168, 191, 0.3) !important;
}
@media (min-width: 768px) {
  #submit {
    width: 250px;
  }
}
</style>
