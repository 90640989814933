<template>
  <v-img
    v-bind="$attrs"
    aspect-ratio="1.5"
    :height="height"
    :width="width"
    :max-height="Math.max(14, height)"
    :max-width="Math.max(21, width)"
    :src="require(`@/assets/countryFlags/${fileName}`).default"
    class="image-style"
    cover
  />
</template>

<script>
export default {
  name: "CountryFlag",
  props: {
    countryCode: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      default: "16",
    },
    width: {
      type: String,
      default: "24",
    },
  },
  computed: {
    fileName() {
      if (this.countryCode && this.countryCode.length === 2) {
        return `${this.countryCode.toLowerCase()}.png`;
      } else {
        return "error.png";
      }
    },
  },
};
</script>

<style scoped>
.image-style {
  opacity: 0.8;
  border-radius: 2px;
  border: 1px solid #999;
}
</style>
