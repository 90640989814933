export const PUBLIC_KEY_PARAM = "publicKey";

const PUBLIC_KEY_PATH = `/:${PUBLIC_KEY_PARAM}`
const OPTIONS_PATH = "/options";
const TRACKING_PATH = "/tracking";
const UPSELLING_PATH = "/upselling";
const CHECKOUT_PATH = "/checkout";
const RETURNS_PATH = "/returns";
const CHANGES_PATH = "/changes";
const RETURN_INFO_PATH = "/return-info";
const DATA_PATH = "/addinfo";

export default {
// Amphora route path
  BASE: "",
  OPTIONS: OPTIONS_PATH,
  TRACKING: TRACKING_PATH,
  UPSELLING: UPSELLING_PATH,
  CHECKOUT: CHECKOUT_PATH,
  RETURNS: RETURNS_PATH,
  CHANGES: CHANGES_PATH,
  RETURN_INFO: RETURN_INFO_PATH,
  DATA: DATA_PATH,
  GO_DESIGN_VIEW: "/admin-private/customizations",
  MOCK_TRACKING_PAGE: "/admin-private/tracking",
  // Company and shop route path
  SHOP_BASE: `${PUBLIC_KEY_PATH}`,
  SHOP_OPTIONS: `${PUBLIC_KEY_PATH + OPTIONS_PATH}`,
  SHOP_TRACKING: `${PUBLIC_KEY_PATH + TRACKING_PATH}`,
  SHOP_UPSELLING: `${PUBLIC_KEY_PATH + UPSELLING_PATH}`,
  SHOP_CHECKOUT: `${PUBLIC_KEY_PATH + CHECKOUT_PATH}`,
  SHOP_RETURNS: `${PUBLIC_KEY_PATH + RETURNS_PATH}`,
  SHOP_CHANGES: `${PUBLIC_KEY_PATH + CHANGES_PATH}`,
  SHOP_RETURN_INFO: `${PUBLIC_KEY_PATH + RETURN_INFO_PATH}`,
  SHOP_DATA: `${PUBLIC_KEY_PATH + DATA_PATH}`,
};
