<template>
  <div v-for="(step, index) in stepsInstructions" :key="index" class="pb-2">
    <p class="body-1 text-neutral80 font-weight-medium">
      <span class="mr-2">{{ index + 1 }}.</span>{{ step.title }}
    </p>
    <p
      v-if="step.pickUpReference && pickupReference !== ''"
      class="body-1 text-neutral40"
    >
      {{ step.pickUpReference }}
    </p>
    <p class="body-1 text-neutral40">{{ step.text }}</p>
    <p
      v-if="returnWarehouseAddress !== '' && step.direction"
      class="body-1 text-neutral40"
    >
      {{ step.direction }}:
      <span class="font-weight-bold text-neutral80">
        {{ returnWarehouseAddress }}
      </span>
    </p>
  </div>
</template>
<script>
export default {
  name: 'ReturnInstructions',
  props: {
    returnCustomInstructions: {
      type: Object,
      default: () => {},
    },
    identifierReturn: {
      type: String,
      default: '',
    },
    returnWarehouseAddress: {
      type: String,
      default: '',
    },
    pickupReference: {
      type: String,
      default: '',
    },
  },
  computed: {
    stepsInstructions() {
      const steps = []
      for (const key in this.returnCustomInstructions) {
        if (key.startsWith('step')) {
          const step = { ...this.returnCustomInstructions[key] }
          if (step.text && step.text.includes('{ref}')) {
            step.text = step.text.replace('{ref}', this.identifierReturn)
          }
          if (
            step.pickUpReference &&
            step.pickUpReference.includes('{homePickupRef}')
          ) {
            step.text = step.text.replace(
              '{homePickupRef}',
              this.pickupReference
            )
          }
          steps.push(step)
        }
      }
      return steps
    },
  },
}
</script>
