<template>
  <div class="text-center text-lg-start">
    <p class="subtitle-3 text-uppercase text-neutral60 mb-10">
      <slot name="step"></slot>
    </p>
    <h4 class="mb-10">
      <slot name="content"></slot>
    </h4>
  </div>
</template>

<script>
export default {
  name: "ReturnSteps",
  props: {
    hasReturn: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped></style>
