{
  "chat": {
    "title": "Habla con nosotros",
    "email": "Correo electrónico",
    "email_placeholder": "p. ej: ejemplo{'@'}gmail.com",
    "tracking": "Número de seguimiento",
    "tracking_placeholder": "p. ej: #01921883712873",
    "subject": "Selecciona el motivo de tu consulta",
    "placeholder": "Hacer una consulta",
    "inform": "¡Hola! Gracias por contactarnos a través de este chat. No podemos atender tu solicitud de manera inmediata por esta vía, pero nos pondremos en contacto contigo por correo electrónico lo más rápido posible. ¡Gracias!",
    "send": "Enviar",
    "options": {
      "select": "Selecciona una opción",
      "op1": "Hacer una consulta sobre el envío",
      "op2": "Notificar una incidencia",
      "op3": "Consulta sobre la Política de devoluciones",
      "op4": "Consulta sobre el Proceso de Reembolso",
      "op5": "Solicitar la cancelación de mi pedido",
      "op6": "Modificar mi pedido"
    }
  },
  "chAndRe": {
    "tellUs": "Dinos por qué quieres devolver el pedido",
    "writeHere": "Escribe aquí",
    "fulfillmentDays": "El plazo de devolución (DAYS_TO días) de este pedido ha acabado.",
    "expired": "El período de devolución ha caducado",
    "change": "Cambio",
    "errorEmail": "Por favor, introduce tu email",
    "invalidEmail": "Email incorrecto",
    "unit": "Ctd.",
    "to_tracking": "Volver al seguimiento"
  },
  "app": {
    "power": "Desarrollado por"
  },
  "add": {
    "submit": "Enviar",
    "id_card": "Añade tu documento de identidad",
    "success": "¡Completado!",
    "message": "Tu pedido está listo para ser procesado.",
    "invalidDNI": "Documento de identidad inválido",
    "required": "Campo requerido",
    "info": "El número de documento de identidad o DNI suele estar impreso en el propio documento y consta de ocho cifras seguidas de una letra que actúa como dígito de control.",
    "country": {
      "label": "País de envío",
      "placeholder": "Selecciona"
    },
    "zipCode": {
      "label": "Código postal",
      "placeholder": "Escribe el código postal"
    },
    "city": {
      "label": "Ciudad",
      "placeholder": "Escribe la ciudad"
    },
    "address": {
      "label": "Dirección",
      "placeholder": "Escribe la dirección"
    },
    "address2": {
      "label": "Información extra de la dirección",
      "placeholder": "Escribe la dirección"
    },
    "name": {
      "label": "Nombre",
      "placeholder": "Escribe el nombre"
    },
    "phone": {
      "label": "Teléfono",
      "placeholder": "Escribe el número de teléfono"
    },
    "searchCountry": "Buscar país"
  },
  "dialog": {
    "title": "Por favor, introduce tu email para que podamos procesar tu solicitud"
  },
  "info": {
    "form": {
      "tracking": "Número de pedido o seguimiento",
      "tracking_ej": "p. ej: #02692F0405",
      "required_tracking": "Se requiere número de pedido.",
      "postal": "Código Postal",
      "postal_ej": "p. ej: 08006",
      "required_postal": "Se requiere código postal.",
      "search": "Buscar",
      "tooltip_order": "Encuentra tu número de seguimiento en el correo electrónico de confirmación del pedido que has recibido. También puedes verlo en el albarán de entrega dentro del paquete.",
      "tooltip_code": "Es un código numérico que determina una ubicación. Puedes encontrarlo fácilmente llamando a correos o realizando una búsqueda por internet.",
      "error": "No se ha encontrado el pedido"
    },
    "title": {
      "need": "Encuentra tu pedido",
      "tracking": "Número de seguimiento",
      "your_order": "Tu pedido",
      "your_products": "Tus productos"
    }
  },
  "options": {
    "tracking": "Seguimiento: ",
    "order_number": "Número de pedido",
    "order_name": "Nombre del pedido",
    "id": "ID del pedido",
    "return": "¿Quieres hacer una devolución?",
    "change": "¿Quieres hacer un cambio?",
    "my_return": "Ver mi devolución",
    "my_change": "Ver mi cambio",
    "return_progress": "Tienes una devolución en curso",
    "change_progress": "Tienes un cambio en curso",
    "return_or_change": "¿Quieres hacer un cambio o devolución?",
    "make_return": "Hacer devolución",
    "make_change": "Hacer cambio",
    "courier": {
      "name": "Tu pedido es operado por",
      "contact": "servicio de transportista de confianza",
      "details": "Detalles del transportista"
    },
    "noData": "No hay opciones disponibles"
  },
  "steps": {
    "return": {
      "step1": {
        "step": "Paso 1:",
        "desc": "Selecciona el producto que quieres devolver"
      },
      "step2": {
        "step": "Paso 2:",
        "desc": "Confirma la devolución",
        "desc2": "Confirma la devolución o selecciona más productos"
      },
      "step3": {
        "desc": "Hemos enviado la solicitud de devolución. En breve recibirás una respuesta por correo electrónico"
      }
    },
    "change": {
      "step1": {
        "step": "Paso 1:",
        "desc": "Selecciona el producto que quieres cambiar"
      },
      "step2": {
        "step": "Paso 2:",
        "desc": "Confirma el cambio",
        "desc2": "Confirma el cambio o selecciona más productos"
      },
      "step3": {
        "desc": "Hemos enviado la solicitud de cambio. En breve recibirás una respuesta por correo electrónico"
      }
    }
  },
  "instructions": {
    "title": "Instrucciones para devolución",
    "title_steps": "¿Cómo devolver un producto?",
    "subtitle_return": "Devolución",
    "subtitle_change": "Cambio",
    "item_return": "Tus artículos para devolver",
    "item_change": "Tus artículos para cambiar",
    "prepare": "Prepara",
    "send": "Envía de vuelta el pedido",
    "refund": "Reembolso",
    "information": {
      "desc_url_1": "Para realizar la devolución, sigue las indicaciones que aparecen en la etiqueta. Ten en cuenta que si estás realizando la devolución desde un país que no forma parte de la Unión Europea, es imprescindible que imprimas la factura y la incluyas en el paquete.",
      "desc_url_2": "Para devolver un artículo, solo tienes que pegar esta etiqueta en el paquete y programaremos su recogida.",
      "desc_url_3": "Para completar la devolución haz clic en el botón de 'Más información' y siga las instrucciones.",
      "desc_cex": "Para finalizar la devolución entrega el paquete en una oficina de Correos y menciona que se trata de un envío con 'Logística Inversa de Correos express' para que puedan generar una etiqueta de devolución. Asegúrate de que la información en la etiqueta coincida exactamente con la referencia '{ref}' de tu pedido, incluyendo números y letras."
    },
    "qr": {
      "step1": "Descarga e imprime 2 copias del código QR. Pega una copia fuera del paquete e incluye la otra dentro. De este modo podremos identificar tu devolución y proceder al reembolso.",
      "important": "IMPORTANTE: el QR no es una etiqueta de envío, es un código de identificación interno para que nuestro almacén pueda identificar el producto. Deberás también pegar a tu paquete la etiqueta de envío que te dará la empresa de transportes que elijas.",
      "step2": "Lleva el paquete a la empresa de transporte que te resulte más conveniente y envíalo a la dirección indicada en el email.",
      "step3": "Recibe tu reembolso una vez hayamos recibido el paquete de devolución."
    },
    "defaultInstructions": {
      "step1": "Empaqueta cuidadosamente los artículos para devolver.",
      "step2": "Sigue las instrucciones proporcionadas para proceder con la devolución.",
      "step3": "Recibe tu reembolso una vez hayamos recibido el paquete de devolución."
    },
    "return": {
      "title": "Ya puedes enviar tu devolución",
      "desc": "Para llevar a cabo la devolución, por favor, sigue las instrucciones que hemos enviado a tu dirección de correo electrónico.",
      "print_label": "Imprime la etiqueta de devolución",
      "more_info": "Más información",
      "qr_return": "Descarga el código QR",
      "identifier": "Referencia de devolución:"
    },
    "received": {
      "title": "Hemos recibido tu devolución",
      "desc": "Tu devolución ha sido recibida correctamente, para mas información contacta con nosotros."
    }
  },
  "product": {
    "return": {
      "title": "¿Quieres devolver este artículo?",
      "label": "¿Por qué quieres devolverlo?",
      "qty": "¿Qué cantidad quieres devolver?",
      "reason": "Escribe aquí el motivo de la devolución",
      "required": "Por favor, escribe una razón para la devolución.",
      "date": "Devolución disponible hasta el",
      "return": "Devolver",
      "item": "artículo",
      "items": "artículos",
      "uploadImageDamage": "Adjunta la imagen del artículo dañado",
      "uploadImageWrong": "Adjunta la imagen del artículo erróneo",
      "uploadImageDamagePlus": "Adjunta las imagenes de los artículos dañados",
      "uploadImageWrongPlus": "Adjunta las imagenes de los artículos erróneos",
      "maxUpload": "Solo puedes adjuntar máximo 1 imagen por producto",
      "addImage": "Añadir",
      "notReturnable": "Este producto no admite devoluciones ni cambios",
      "allProductsNoReturnable": "No se puede hacer devoluciones ni cambios de ninguno de los productos"
    },
    "change": {
      "title": "¿Quieres cambiar este artículo?",
      "label": "¿Por qué quieres cambiarlo?",
      "product": "Opciones de cambio",
      "reason": "Escribe aquí el motivo del cambio",
      "required": "Por favor, escribe una razón para el cambio.",
      "date": "Cambio disponible hasta el",
      "noStock": "No hay stock disponible"
    },
    "confirm": "Confirmar",
    "cancel": "Cancelar",
    "options": {
      "select": "Selecciona una opción",
      "DAMAGED": "Ha llegado dañado",
      "TOO_BIG": "Demasiado grande",
      "TOO_SMALL": "Demasiado pequeño",
      "NOT_EXPECTED": "No es lo que esperaba",
      "NOT_ORDERED": "No es lo que pedí",
      "OTHER": "Otro",
      "eg": "p. ej: no cumple mis expectativas"
    }
  },
  "tracking": {
    "need": "Añadir información",
    "resume": {
      "order": {
        "title": "Dirección de envío "
      }
    },
    "messages": {
      "processing": "Estado: en preparación",
      "shipped": "Estado: enviado",
      "out": "Estado: en reparto",
      "ful": "Estado: entregado",
      "refused": "Estado: rechazado",
      "info": "Para enviar este pedido, necesitamos información adicional.",
      "wrongAddress": "Ooops, parece que hay un error en algunos campos.",
      "verifyField": "Por favor, verifica que todos los datos sean correctos."
    },
    "progress": {
      "processing": "Estamos preparando tu pedido lo más rápido posible",
      "shipped": "Tu pedido ya ha salido de nuestro almacén",
      "delivery": "Casi casi, tu pedido está en reparto",
      "delivered": "Ya hemos entregado tu pedido",
      "refused": "Hemos intentado entregar tu paquete pero este ha sido rechazado. Porfavor contacta con nosotros para mas información."
    }
  },
  "footer": {
    "contact": "Contacto",
    "help": "Ayuda",
    "policy": "Política de privacidad"
  },
  "checkout": {
    "checkout": "Checkout",
    "paymentDetails": "Detalles del pago",
    "paymentDetailsText": "¡Estás a solo un paso de completar tu compra! Por favor, revisa los detalles de tu pedido y procede con el pago seguro.",
    "customerName": "Nombre",
    "customerSurnames": "Apellidos",
    "customerAdress": "Dirección del domicilo",
    "zipCode": "Código postal",
    "city": "Ciudad",
    "orderHeader": "Tu pedido",
    "orderText": "Los productos nuevos añadidos a tu carrito se enviarán conjuntamente con tu pedido anterior. Es decir, recibirás todo en un mismo paquete.",
    "quantity": "unidad",
    "exclusivePrice": "Precio exclusivo:",
    "totalPrice": "Subtotal",
    "shippment": "Envío incluido en el pedido",
    "thanks": "¡Muchas gracias!",
    "successText": "Has aprovechado los descuentos exclusivos para ti. A continuación procederemos a preparar tu nuevo pedido. Recuerda que lo recibirás todo en la fecha prevista inicial. ¡Disfruta de tu nuevos productos con descuento!",
    "payNow": "Pagar ahora",
    "paymentSuccess": "Tu pago ha sido completado",
    "paymentProcessing": "Tu pago se encuentra en proceso",
    "paymentLabel": "Tus datos de pago no se almacenan en nuestros servidores. Solo son utilizados para procesar tu pago de forma segura."
  },
  "upselling": {
    "discount": {
      "text": "¡ COMPRA CON DESCUENTOS Y RECÍBELO EN UN SOLO PEDIDO !"
    },
    "days": [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado"
    ],
    "months": [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre"
    ],
    "finished": {
      "condolences": "Lo sentimos, esta oferta ya ha finalizado",
      "condolencesBody1": "Sentimos que no hayas podido aprovechar esta oferta a tiempo. ¡No te preocupes! En",
      "condolencesBody2": "siempre estamos trabajando para traerte nuevas y emocionantes promociones",
      "condolencesBody3": "Mientras tanto, te invitamos a explorar nuestros otros productos y descubrimientos recientes que estamos seguros te encantarán.",
      "titleTracking": "Consulta el estado de tu pedido",
      "trackingButton": "Haz el seguimiento aquí"
    },
    "recommendedProducts": {
      "title": "Recomendados para ti",
      "subtitleUpselling": "Creemos que estos productos encajan con tus compras. Añade al carrito ahora y recíbelo en el mismo pedido.",
      "subtitleUpsellingEnd": "Creemos que estos productos te pueden interesar. No pierdas la oportunidad de descubrir estas joyas ocultas que pueden convertirse en tus nuevos favoritos.",
      "goToShop": "Ir a la tienda",
      "addToCart": "Añadir al carrito",
      "modifyCart": "Modificar el carrito"
    },
    "upsellingProducts": {
      "title": ["Compra con", "descuento", "y recibe en el mismo pedido"],
      "orderNumber": "Número de pedido",
      "timeText": "¡CUENTA ATRÁS OFERTAS!",
      "continue": "Sigue leyendo para ver el estado de tu pedido"
    },
    "paySummary": {
      "title1": "Tu carrito (",
      "title2": "artículos nuevos )",
      "deleteCart": "Eliminar del carrito",
      "addMoreProducts": "Añadir más productos",
      "endPurchase": "Finalizar compra",
      "price": "Precio:"
    }
  },
  "iframe": {
    "addBanner": "Añadir un banner (opcional)"
  },
  "exceptionsCarrier": {
    "CARRIER_WRONG_POSTAL_CODE": {
      "title": "Código postal inválido",
      "subtitle": "El código postal ingresado es incorrecto o no coincide con la ciudad especificada."
    },
    "CARRIER_ADDRESS_TOO_LONG": {
      "title": "Dirección demasiado larga",
      "subtitle": "La dirección ingresada es demasiado larga para generar la etiqueta de envío."
    },
    "CARRIER_PHONE_REQUIRED": {
      "title": "Número de teléfono requerido",
      "subtitle": "No se puede generar la etiqueta de envío porque falta el número de teléfono."
    },
    "CARRIER_PHONE_TOO_LONG": {
      "title": "Número de teléfono demasiado largo",
      "subtitle": "El número de teléfono ingresado es demasiado largo para generar la etiqueta de envío."
    },
    "CARRIER_PHONE_TOO_SHORT": {
      "title": "Número de teléfono demasiado corto",
      "subtitle": "El número de teléfono ingresado es incorrecto; es demasiado corto para generar la etiqueta de envío."
    },
    "CARRIER_ADDRESS_TOO_SHORT": {
      "title": "Dirección demasiado corta",
      "subtitle": "La dirección ingresada es demasiado corta para generar la etiqueta de envío."
    },
    "carrierMessage": "El transportista indica el siguiente mensaje:"
  }
}
