<template>
  <v-card class="top-rounded pa-0" width="100%" color="neutral80">
    <div class="mx-auto" style="max-width: 800px">
      <v-row class="px-10 pt-5" no-gutters>
        <v-col cols="8">
          <span class="subtitle-3 font-weight-black">{{
            $t("checkout.totalPrice")
          }}</span>
          <v-spacer></v-spacer>
          <span>{{ $t("checkout.shippment") }}</span>
        </v-col>
        <v-col class="text-end">
          <span class="subtitle-3 font-weight-black">{{ totalPrice }}</span>
        </v-col>
        <v-divider color="neutral00" class="border-opacity-100 mt-4" />
      </v-row>
      <v-row class="mb-16 pt-2 mb-sm-5 ma-0 justify-center" align="center">
        <v-col align="center" cols="auto" class="pt-0 mb-0">
          <v-btn
            class="btn btn-tertiary text-neutral00"
            @click="deleteSelectedProducts"
          >
            {{ $t("product.cancel") }}
          </v-btn>
        </v-col>
        <v-col align="center" cols="auto" class="pt-0 pa-0">
          <v-btn class="btn btn-secondary" @click="passSelectedProducts">
            {{ $t("upselling.paySummary.endPurchase") }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import { toCheckout } from "@/router/navigate";
import { useDisplay } from "vuetify";

export default {
  name: "UpsellingCart",
  components: {},
  props: {
    selectedProducts: { type: Object, required: true },
    order: { type: Object, required: true },
    showSummary: { type: Boolean, default: false },
  },
  computed: {
    totalPrice() {
      let total = 0;
      let currencyUnit;
      for (let product of Object.values(this.selectedProducts)) {
        total += parseFloat(product.discount_price || product.price);
        if (!currencyUnit) {
          currencyUnit = (product.discount_price || product.price).split(
            " "
          )[1];
        }
      }
      return `${total.toFixed(2)} ${currencyUnit || ""}`;
    },
    show() {
      return this.showSummary;
    },
  },
  setup() {
    const { mobile } = useDisplay();
    return { mobile };
  },
  methods: {
    passSelectedProducts() {
      for (let item of Object.values(this.selectedProducts)) {
        item["quantity"] = 1;
      }
      localStorage.setItem(
        "selectedProducts",
        JSON.stringify(this.selectedProducts)
      );
      toCheckout({
        publicKey: this.$route.params.publicKey,
        tracking: this.order.shipping_number,
        zip_code: this.order.shipping_address_zip,
        order_id: this.order.id,
      });
    },
    deleteSelectedProducts() {
      this.$emit("deleteAll", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.top-rounded {
  border-radius: 40px 40px 0 0;
}
</style>
