<template>
  <v-form v-model="valid" @submit="submitForm" class="d-flex flex-column">
    <TopTitle>{{
      customTextsObject.principal_text
        ? customTextsObject.principal_text[$i18n.locale]
        : $t(`info.title.need`)
    }}</TopTitle>
    <InputField
      :placeholder="$t(`info.form.tracking_ej`)"
      :rules="orderRules"
      :readonly="loading"
      :value="trackingNumber"
      @input="trackingField"
    >
      {{ $t(`info.form.tracking`) }}
      <template v-slot:tooltip>
        <v-tooltip
          :text="$t(`info.form.tooltip_order`)"
          location="end"
          max-width="360"
        >
          <template v-slot:activator="{ props }">
            <v-icon
              class="ml-2 mb-2"
              icon="mdi-24px mdi-help-circle"
              v-bind="props"
            />
          </template>
        </v-tooltip>
      </template>
    </InputField>
    <InputField
      :placeholder="$t(`info.form.postal_ej`)"
      :rules="postalRules"
      :readonly="loading"
      :value="postalCode"
      @input="postalField"
    >
      {{ $t(`info.form.postal`) }}
      <template v-slot:tooltip>
        <v-tooltip
          :text="$t(`info.form.tooltip_code`)"
          location="end"
          max-width="340"
          max-height="200"
        >
          <template v-slot:activator="{ props }">
            <v-icon
              class="ml-2 mb-2"
              icon="mdi-24px mdi-help-circle"
              v-bind="props"
            />
          </template>
        </v-tooltip>
      </template>
    </InputField>
    <CustomAlert :error="showAlert" :text="translatedErrorMessage" />
    <v-btn
      :disabled="!valid"
      :loading="loading"
      type="submit"
      class="d-block btn-primary wide-btn mb-10 align-self-start"
    >
      <p>{{ $t(`info.form.search`) }}</p>
    </v-btn>
  </v-form>
</template>

<script>
import { toTracking } from "@/router/navigate";
import { mapActions } from "vuex";
import TopTitle from "@/components/blocks/TopTitle.vue";
import CustomAlert from "@/components/blocks/CustomAlert.vue";
import InputField from "@/components/inputs/InputField.vue";
import { replaceErrorMessage } from "@/helpers/const.utils";

// placeholder_tracking principal_text secondary_text
export default {
  name: "InfoComponent",
  components: { TopTitle, InputField, CustomAlert },
  props: {
    company: {
      type: Object,
      default: () => undefined,
    },
    customTextsObject: {
      type: Object,
      default: () => {},
    },
    prefixName: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      valid: false,
      trackingNumber: "",
      postalCode: "",
      loading: false,
      showAlert: false,
      error: null,
      orderRules: [
        (value) => {
          if (!value) return this.$t(`info.form.required_tracking`);
          return true;
        },
      ],
      postalRules: [
        (value) => {
          if (!value) return this.$t(`info.form.required_postal`);
          return true;
        },
      ],
    };
  },
  computed: {
    translatedErrorMessage() {
      if (this.error) {
        return replaceErrorMessage(this.error, this.$t);
      }
      return null;
    },
  },
  methods: {
    ...mapActions(["findOrder"]),
    submitForm(event) {
      event.preventDefault();
      if (
        !this.loading &&
        this.trackingNumber !== "" &&
        this.postalCode !== ""
      ) {
        this.loading = true;
        let order_params = {
          trackingNumber: this.trackingNumber.trim(),
          postalCode: this.postalCode.trim(),
          publicKey: this.$route.params.publicKey,
          prefixName: this.prefixName,
        };
        this.findOrder(order_params)
          .then((r) => {
            toTracking({
              publicKey: this.$route.params.publicKey,
              order_id: r.id,
              zip_code: r.shipping_address_zip,
              tracking: r.shipping_number,
            });
          })
          .catch((e) => {
            this.showAlert = true;
            this.error = e;
          })
          .finally(() => {
            this.valid = true;
            this.loading = false;
          });
      }
    },
    trackingField(value) {
      this.trackingNumber = value;
    },

    postalField(value) {
      this.postalCode = value;
    },
  },
};
</script>
<style lang="scss" scoped></style>
