<template>
  <div class="d-flex justify-space-between align-start justify-end pt-5">
    <Lang :customTextsObject="customTextsObject" />
    <div class="d-flex align-center">
      <p class="footer-text pb-1" @click="changeColors">{{ $t(`app.power`) }}</p>
      <AmphoraLogo />
    </div>
  </div>
</template>

<script>
import AmphoraLogo from "@/components/shared/AmphoraLogo.vue";
import Lang from "@/components/shared/Lang.vue";
export default {
  components: {
    AmphoraLogo,
    Lang,
  },
  props: {
    customTextsObject: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-text {
  font-size: 13px;
}

@media screen and (max-width: 767px) {
  .footer-text {
    font-size: 10px;
  }
}
svg {
  padding: 0 0.3em;
  padding-bottom: 3px;
  max-width: 95px;
}
</style>
