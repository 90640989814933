import _router from "@/router";
import name from "@/router/const/name";

export const toBase = ({ publicKey }) => {
  _router.push({
    name: publicKey ? name.SHOP_BASE : name.BASE,
    params: { publicKey: publicKey },
    hash: ``,
  });
};

export const toOptions = ({ publicKey, tracking, order_id, zip_code }) => {
  _router.push({
    name: publicKey ? name.SHOP_OPTIONS : name.OPTIONS,
    params: { publicKey: publicKey },
    hash: `#t=${tracking}&zc=${zip_code}&oi=${order_id}`,
  });
};

export const toTracking = ({ publicKey, tracking, order_id, zip_code }) => {
  _router.push({
    name: publicKey ? name.SHOP_TRACKING : name.TRACKING,
    params: { publicKey: publicKey },
    hash: `#t=${tracking}&zc=${zip_code}&oi=${order_id}`,
  });
};

export const toUpselling = ({ publicKey, tracking, order_id, zip_code }) => {
  _router.push({
    name: publicKey ? name.SHOP_UPSELLING : name.UPSELLING,
    params: { publicKey: publicKey },
    hash: `#t=${tracking}&zc=${zip_code}&oi=${order_id}`,
  });
};

export const toCheckout = ({ publicKey, tracking, order_id, zip_code }) => {
  _router.push({
    name: publicKey ? name.SHOP_CHECKOUT : name.CHECKOUT,
    params: { publicKey: publicKey },
    hash: `#t=${tracking}&zc=${zip_code}&oi=${order_id}`,
  });
};

export const toChanges = ({ publicKey, tracking, zip_code, order_id }) => {
  _router.push({
    name: publicKey ? name.SHOP_CHANGES : name.CHANGES,
    params: { publicKey: publicKey },
    hash: `#t=${tracking}&zc=${zip_code}&oi=${order_id}`,
  });
};

export const toReturns = ({ publicKey, tracking, zip_code, order_id }) => {
  _router.push({
    name: publicKey ? name.SHOP_RETURNS : name.RETURNS,
    params: { publicKey: publicKey },
    hash: `#t=${tracking}&zc=${zip_code}&oi=${order_id}`,
  });
};

export const toReturnsInfo = ({ publicKey, tracking, zip_code, order_id }) => {
  _router.push({
    name: publicKey ? name.SHOP_RETURN_INFO : name.RETURN_INFO,
    params: { publicKey: publicKey },
    hash: `#t=${tracking}&zc=${zip_code}&oi=${order_id}`,
  });
};

export const toAddInfo = ({ publicKey, tracking, order_id, zip_code }) => {
  _router.push({
    name: publicKey ? name.SHOP_DATA : name.DATA,
    params: { publicKey: publicKey },
    hash: `#t=${tracking}&zc=${zip_code}&oi=${order_id}`,
  });
};
