import i18n from "@/i18n"

const PATTERN_EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  // + 34 544 54 54 45 Symbol + country code + 9 digits.
const PATTERN_PHONE = /^(\+\d{1,3}\s*)?((\d[-\s]*){7,15})$/;
const PATTERN_CJK_CHARACTERS =
  /[\u4E00-\u9FFF\u3400-\u4DBF\uF900-\uFAFF\u3040-\u309F\u30A0-\u30FF\uAC00-\uD7AF]/
const PATTERN_ARABIC_CHARACTERS = /[\u0600-\u06FF]/

const ACCEPTED_FILE_TYPES = ["image/png", "image/jpeg", "image/jpg"]


export function requiredRule(str) {
  return !!str || i18n.global.t("validationError.required");
}
export function isValidEmail(email) {
  return PATTERN_EMAIL.test(email)
}
export function emailValidationRule(email) {
  return isValidEmail(email) || i18n.global.t("validationError.email")
}
export function isValidPhone(Phone) {
  return PATTERN_PHONE.test(Phone)
}
export function phoneValidationRule(phone) {
  return isValidPhone(phone) || i18n.global.t("validationError.phone")
}
export function isCJKCharacters(str) {
  return PATTERN_CJK_CHARACTERS.test(str)
}
export function noCJKRule(str) {
  return !isCJKCharacters(str) || i18n.global.t("validationError.cjk")
}
export function isArabicCharacters(str) {
  return PATTERN_ARABIC_CHARACTERS.test(str)
}
export function noArabicRule(str) {
  return !isArabicCharacters(str) || i18n.global.t("validationError.arabic")
}
export function validateImage(file) {
  return ACCEPTED_FILE_TYPES.includes(file.type) && file.size < 5000000
}
export function noCommaRule(str) {
  return !str?.includes(",") || i18n.global.t("validationError.comma")
}
export function noHyphenRule(str) {
  return !str?.includes("-") || i18n.global.t("validationError.hyphen")
}
//  Usage
//  @keydown="(evt) => onKeyDown(evt, numDecimals)"

export function forceNumericInput(event, numDecimals) {
  const { key, target } = event

  const alwaysInvalidKey = ["-", "e", "+"].includes(key)
  const isInvalidDot = numDecimals === 0 && key === "."
  const isInvalidKey = alwaysInvalidKey || isInvalidDot
  const isNumber = key >= "0" && key <= "9"

  const decimalCount = (target.value + key).split(".")[1]?.length || 0

  if (isInvalidKey || (isNumber && decimalCount > numDecimals)) {
    event.preventDefault()
  }
}
