import {
  $fetchJson,
  GET_ORDER_BY_TRACKING,
  ADD_ID_CARD,
  ADD_SHIPPING_INFO,
  GET_INSTRUCTIONS_TRANSLATION,
} from "@/services/_index.service"

export const getOrderByTracking = async ({
  trackingNumber,
  publicKey,
  companyId,
  orderId,
  postalCode,
  products,
  prefixName,
}) => {
  return $fetchJson({
    method: "POST",
    body: {
      shipping_number: trackingNumber,
      shipping_address_zip: postalCode,
      products: products || false,
      public_key: publicKey,
      company_id: companyId,
      order_id: orderId,
      prefix_name: prefixName,
    },
    url: GET_ORDER_BY_TRACKING,
  })
}

export const addIdCard = async ({ companyId, orderId, idCard }) => {
  return $fetchJson({
    method: "POST",
    body: {
      company_id: companyId,
      order_id: orderId,
      customer_id_card: idCard,
    },
    url: ADD_ID_CARD,
  })
}

export const add_shipping_info = async ({
  companyId,
  orderId,
  countryCode,
  zipCode,
  addressCity,
  shippingAddress,
  shippingAddress2,
  shippingAddressName,
  customerPhone,
}) => {
  return $fetchJson({
    method: "POST",
    body: {
      company_id: companyId,
      order_id: orderId,
      shipping_address_country_code: countryCode,
      shipping_address_zip: zipCode,
      shipping_address_city: addressCity,
      shipping_address: shippingAddress,
      ...(shippingAddress2 ? { shipping_address2: shippingAddress2 } : {}),
      shipping_address_name: shippingAddressName,
      customer_phone: customerPhone,
    },
    url: ADD_SHIPPING_INFO,
  })
}

export const getInstructionsTranslation = async (language, currier) => {
  return $fetchJson({
    method: "POST",
    body: {
      language,
      currier
    },
    url: GET_INSTRUCTIONS_TRANSLATION,
  })
}
