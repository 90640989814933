<template>
  <v-card
    flat
    class="overflow-visible mb-10 pa-3 rounded-lg"
    :class="notReturnable ? 'disabled-card' : ''"
    :variant="border ? 'outlined' : 'flat'"
    :color="border ? 'neutral40' : 'neutral00'"
    :disabled="notReturnable && !unicItem"
    >
    <slot name="badge"></slot>
    <div class="d-flex flex-no-wrap justify-space-between align-center">
      <div class="d-flex align-center">
        <v-avatar
          size="80"
          rounded="0"
          class="elevation-soft rounded-lg"
          color="neutral00"
        >
          <v-img :src="logoImage || fallbackImg" @error="onImageError()"  cover />
        </v-avatar>
        <div class="d-flex flex-column">
          <h6 class="footnote px-3 pb-2">{{ title }}</h6>
          <p
            class="text-neutral40 px-3 caption-2"
            v-if="variant != 'Default Title'"
          >
            {{ variant }}
          </p>
          <p v-if="notReturnable" class="px-3 caption-2 text-error">{{ $t("product.return.notReturnable") }}</p>
        </div>
      </div>
      <v-spacer></v-spacer>
      <div>
        <p id="qty" class="text-neutral40 text-no-wrap">
          {{ $t(`chAndRe.unit`) }} {{ quantity }}
        </p>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "ProductDetails",
  props: {
    item: {
      type: Object,
      default: () => undefined,
    },
    unicItem: {
      type: Boolean,
      default: () => false,
    },
    border: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      fallbackImg: require("@/assets/img/photo-off.png")
    };
  },
  computed: {
    logoImage() {
      return this.item ? this.item.product.img : undefined;
    },
    title() {
      return this.item ? this.item.product.title : undefined;
    },
    variant() {
      return this.item ? this.item.product.variant_title : undefined;
    },
    quantity() {
      if (this.item && (this.item.quantity || this.unicItem)) {
        return this.item.quantity;
      } else {
        return "-";
      }
    },
    notReturnable() {
      return this.item ? this.item.product.not_returnable : false;
    }
  },
  methods: {
    onImageError() {
      event.target.src = this.fallbackImg;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  width: 100%;
}

#qty {
  font-size: 12px;
}
@media (min-width: 768px) {
  .v-card {
    width: 60%;
  }
  #qty {
    font-size: inherit;
  }
}
</style>
