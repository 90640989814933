<template>
  <v-fade-transition>
    <MainWrapper
      v-if="!loading"
      :company="company"
      :customTextsObject="customTextsObject"
    >
      <ReturnSteps>
        <template v-slot:step> {{ steps[currentStep].step }} </template>
        <template v-slot:content>
          {{ steps[currentStep].content }}
        </template>
      </ReturnSteps>
      <p
        class="subtitle-3 text-success mb-10 text-center text-lg-start"
        v-if="!returned"
      >
        {{ $t(`product.change.date`) }} {{ returnDate }}
      </p>
      <h5 class="mb-10">{{ $t(`info.title.your_products`) }}</h5>
      <ShippingNumber :order="order" />
      <template v-for="item in order.products" :key="item.id">
        <ProductDialog
          v-if="item.product && item.changes.length > 1"
          :item="item"
          :canChange="(canChange = true)"
          :disabled="returned || item.changes.length === 0"
          :returned="returned"
          :returnProducts="returnProducts"
          :company="company"
          @canceled="cancelProduct"
          @selected="returnedProduct($event)"
          @selectedChange="changeProduct"
        />
      </template>
      <OrderAddress :order="order" />
      <EmailDialog @customerEmail="getEmail($event)" v-model="showDialog" />
      <ChatComponent :company="company" :order="order" />
    </MainWrapper>
  </v-fade-transition>
  <ReturnButtons
    v-if="returnProducts.length > 0"
    :cancel="cancel"
    :submit="submit"
    :buttonLoading="buttonLoading"
    :returnProducts="returnProducts"
  />
</template>

<script>
import { mapActions } from "vuex";
import { toBase } from "@/router/navigate";
import { toReturnsInfo } from "@/router/navigate";
import { getReturnByOrder, createReturn } from "@/services/returns.service.js";

import MainWrapper from "@/components/containers/MainWrapper.vue";
import ChatComponent from "@/components/shared/ChatComponent.vue";
import ProductDialog from "@/components/dialogs/ProductDialog.vue";
import EmailDialog from "@/components/dialogs/EmailDialog.vue";

import OrderAddress from "@/components/blocks/OrderAddress.vue";
import ReturnSteps from "@/components/blocks/ReturnSteps.vue";
import ReturnButtons from "@/components/blocks/ReturnButtons.vue";
import ShippingNumber from "@/components/blocks/ShippingNumber.vue";

export default {
  name: "Changes",
  components: {
    MainWrapper,
    OrderAddress,
    ShippingNumber,
    ReturnSteps,
    ReturnButtons,
    ProductDialog,
    EmailDialog,
    ChatComponent,
  },
  props: {
    customTextsObject: {
      type: Object,
      default: () => {},
    },
    rules: {
      type: Object,
      default: () => {},
    },
    setLoading: {
      type: Function,
    },
    company: {
      type: Object,
      default: () => {},
    },
    item: {
      type: Object,
      default: () => undefined,
    },
    index: {
      type: Number,
      default: () => null,
    },
    selectedMessage: {
      type: String,
      default: () => undefined,
    },
    prefixName: {
      type: String,
      default: () => "",
    },
  },
  data: function () {
    return {
      loading: true,
      buttonLoading: false,
      canChange: true,
      returned: false,
      returnedObject: {},
      order: {},
      changedProducts: [],
      returnProducts: [],
      currentStep: 0,
      showDialog: false,
    };
  },
  created() {
    const parsedParams = {};
    this.setLoading(true);
    this.$route.hash
      .split("&")
      .map((part) => part.replace(/#/, ""))
      .forEach((param) => {
        const parts = [
          param.substr(0, param.indexOf("=")),
          param.substr(param.indexOf("=") + 1),
        ];
        parsedParams[parts[0]] = parts[1];
      });
    this.setOrder(parsedParams);
  },
  computed: {
    returnDate() {
      if (this.order) {
        if (this.order.time_fulfilled) {
          const timeFulfilled = new Date(this.order.time_fulfilled);
          const fulfillmentDays = Number(this.rules.fulfillmentDays);
          const nextDate = new Date(
            timeFulfilled.getTime() + fulfillmentDays * 24 * 60 * 60 * 1000
          );
          return nextDate.toLocaleDateString("es-ES", {
            timeZone: "Europe/Madrid",
          });
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    steps() {
      return [
        {
          step: this.$t(`steps.change.step1.step`),
          content: this.$t(`steps.change.step1.desc`),
        },
        {
          step: this.$t(`steps.change.step2.step`),
          content:
            this.order.products.length > 1
              ? this.$t("steps.change.step2.desc2")
              : this.$t("steps.change.step2.desc"),
        },
        {
          step: "",
          content: this.$t(`steps.change.step3.desc`),
        },
      ];
    },
  },
  methods: {
    ...mapActions(["findOrder"]),
    async setOrder(params) {
      const orderParams = {
        trackingNumber: params.t,
        postalCode: params.zc,
        publicKey: this.$route.params.publicKey,
        orderId: params.oi,
        products: true,
      };
      try {
        const res = await this.findOrder(orderParams);

        this.order = res;
        const { company_id: companyId, id: orderId } = this.order;
        const returns = await getReturnByOrder({
          companyId,
          orderId,
        });
        if (returns.return !== null) {
          this.currentStep = 2;
          this.returned = true;
        }
      } catch (e) {
        if (e.error === 404 || e.error === 403 || e.error === 427) {
          toBase({ publicKey: this.$route.params.publicKey, error: e.message });
        }
      } finally {
        this.loading = false;
        this.setLoading(false);
      }
    },
    cancel() {
      this.currentStep = 0;
      this.returnProducts = [];
      this.changedProducts = [];
    },
    cancelProduct(id) {
      const index = this.returnProducts.findIndex(
        (product) => product.id === id
      );
      if (index !== -1) {
        this.returnProducts.splice(index, 1);
        this.changedProducts.splice(index, 1);
        if (this.returnProducts.length === 0) {
          this.currentStep = 0;
        }
      }
    },
    returnedProduct(product) {
      const duplicate = this.returnProducts.find((item) => {
        return item.id == product.id;
      });

      if (duplicate) {
        duplicate.message = product.message;
        duplicate.quantity = product.quantity;
      } else {
        this.currentStep = 1;
        this.returnProducts.push(product);
      }
    },
    changeProduct(change, product, quantity) {
      const duplicate = this.changedProducts.find((item) => {
        return item.productId == product.id;
      });
      if (duplicate) {
        duplicate.id = change.id;
        duplicate.quantity = quantity;
      } else {
        this.changedProducts.push({
          productId: product.id,
          id: change.id,
          quantity: quantity,
        });
      }
    },
    getEmail(email) {
      this.order.customer_email = email;
      this.showDialog = false;
      this.submit();
    },
    async submit() {
      this.buttonLoading = true;
      this.changedProducts.forEach((product) => {
        delete product.productId;
      });
      if (!this.order.customer_email) {
        this.showDialog = true;
        this.buttonLoading = false;
        return; // exit the function to prevent further execution
      }
      if (!this.showDialog) {
        try {
          await createReturn({
            returnProducts: this.returnProducts,
            changeProducts: this.changedProducts,
            costumerEmail: this.order.customer_email,
            companyId: this.order.company_id,
            orderId: this.order.id,
          });
        } catch (e) {
          console.error(e);
        } finally {
          this.currentStep = 2;
          this.returned = true;
          this.buttonLoading = false;
          this.toReturnInfoRoute();
        }
      }
    },
    toReturnInfoRoute() {
      toReturnsInfo({
        publicKey: this.$route.params.publicKey,
        tracking: this.order.shipping_number,
        zip_code: this.order.shipping_address_zip,
        order_id: this.order.id,
      });
    },
  },
};
</script>
