<template>
  <p class="text-neutral60 font-weight-light mb-10">
    {{ $t("upselling.upsellingProducts.orderNumber") }} {{ order.id }}
  </p>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
